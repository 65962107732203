var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"modal--ident pay-modal",attrs:{"title":"Оплата"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"form-box"},[_c('form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isSending),expression:"isSending"}],staticClass:"form-box__form pay-modal__form",on:{"submit":function($event){$event.preventDefault();}}},[(_vm.form.accountID)?_c('form-box-select',{attrs:{"label":"Договор","list":_vm.accountInfo,"valuePropName":"AccountID","keyPropName":"AccountID"},on:{"input":_vm.setForm},scopedSlots:_vm._u([{key:"selectedOption",fn:function(ref){
var item = ref.item;
return [_vm._v(" (Л/с: "+_vm._s(item.Ident)+") "+_vm._s(item.Address)+" ")]}},{key:"option",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Ident)+" ("+_vm._s(item.AccountType)+") ")]}}],null,false,3245694176),model:{value:(_vm.form.accountID),callback:function ($$v) {_vm.$set(_vm.form, "accountID", $$v)},expression:"form.accountID"}}):_vm._e(),_c('form-box-input',{attrs:{"type":"amount","label":"Сумма оплаты (₽)","readonly":!_vm.allowEditSum,"message":"Неверная сумма"},model:{value:(_vm.form.sum),callback:function ($$v) {_vm.$set(_vm.form, "sum", $$v)},expression:"form.sum"}}),(
          _vm.insuranceSum &&
          (!_vm.form.paymentSystem || _vm.form.paymentSystem == 'Tinkoff')
        )?[_c('form-box-checkbox',{attrs:{"label":("Страхование " + _vm.insuranceSum + " ₽"),"refName":"insuranceControl","inputId":"insurance","classModificators":['pay-modal']},model:{value:(_vm.form.insuranceEnabledByUser),callback:function ($$v) {_vm.$set(_vm.form, "insuranceEnabledByUser", $$v)},expression:"form.insuranceEnabledByUser"}}),_c('div',{staticClass:"pay-modal__insurance"},[_c('a',{staticClass:"pay-modal__insurance-link pay-modal__insurance-link--rules",attrs:{"target":"_blank","href":"https://sm-center.ru/vsk_polis.pdf"}},[_vm._v(" Условия страхования ")]),_c('a',{staticClass:"pay-modal__insurance-link pay-modal__insurance-link--disable",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.disableInsurance()}}},[_vm._v(" Не предлагать страхование ")])])]:_vm._e(),(
          _vm.form.paymentSystem == 'Tinkoff' ||
            (_vm.paymentSystems &&
              _vm.paymentSystems.length === 1 &&
              _vm.paymentSystems[0].name == 'Tinkoff')
        )?_c('form-box-checkbox',{attrs:{"label":"Cохранить карту","refName":"saveCardControl","inputId":"save-card","classModificators":['pay-modal']},model:{value:(_vm.form.allowCardSaving),callback:function ($$v) {_vm.$set(_vm.form, "allowCardSaving", $$v)},expression:"form.allowCardSaving"}}):_vm._e(),(_vm.paymentSystems && _vm.paymentSystems.length > 1)?[_c('form-box-radioset',{staticClass:"pay-modal__payment-system",attrs:{"label":"Платежная система","list":_vm.paymentSystemsRadiosetList,"message":"Выберите платежную систему","classModificators":['pay-modal']},scopedSlots:_vm._u([{key:"label",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"payment-system"},[_c('img',{staticClass:"payment-system__icon",attrs:{"src":_vm.getPaymentSystemImage(item.value)}}),_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,false,2971234992),model:{value:(_vm.form.paymentSystem),callback:function ($$v) {_vm.$set(_vm.form, "paymentSystem", $$v)},expression:"form.paymentSystem"}})]:_vm._e(),_c('div',{staticClass:"pay-modal__totals"},[_c('p',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.commissionIsLoading),expression:"commissionIsLoading"}],staticClass:"pay-modal__total pay-modal__total--sum"},[_vm._v(" Итого: "),_c('strong',[_vm._v(_vm._s(_vm.totalSum)+" ₽")])]),(!_vm.hideComissionInfo)?[(_vm.commission > 0)?_c('p',{staticClass:"pay-modal__total pay-modal__total--commission"},[_vm._v(" *Комиссия: "+_vm._s(_vm.commission)+" ₽ ")]):_c('p',{staticClass:"pay-modal__total pay-modal__total--commission"},[_vm._v(" *Комиссия не взимается ")])]:_vm._e()],2),_c('base-button',{staticClass:"pay-modal__button",attrs:{"disabled":!_vm.form.sum || (_vm.form.sum && +_vm.form.sum <= 0)},on:{"click":_vm.startPayment}},[_vm._v(" Оплатить ")])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }