var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[(_vm.tableData.length)?_c('table',{class:[
      'table',
      ("table--" + _vm.type)
    ]},[(_vm.header)?_c('thead',{staticClass:"table__header"},[_c('tr',[_vm._l((_vm.header),function(th,hkey){return [(th)?_c('th',{key:("table-header-" + hkey),style:(("\n              text-align: " + (th.align ? th.align : 'left') + ";\n              width: " + (th.width ? th.width : 'auto') + ";\n              cursor: " + (th.sorting ? 'pointer' : 'default') + "\n            ")),on:{"click":function($event){(th.sorting) ? _vm.sortingData($event, th.sorting.key) : false}}},[_vm._v(" "+_vm._s(th.title)+" "),(th.sorting)?_c('span',{class:[
                'table__sorting',
                { 'table__sorting--active': (_vm.sorting.find(function (item) { return item.key === th.sorting.key; })['active']) },
                { 'table__sorting--asc': _vm.sorting.find(function (item) { return item.key === th.sorting.key; })['direction'] === 'asc' },
                { 'table__sorting--desk': _vm.sorting.find(function (item) { return item.key === th.sorting.key; })['direction'] === 'desc' }
              ]},[_c('arrow-down-icon',{attrs:{"size":"16"}})],1):_vm._e()]):_vm._e()]})],2)]):_vm._e(),_c('tbody',{staticClass:"table__body"},_vm._l((_vm.tableData),function(tr,i){return _c('tr',{key:("table-tr-" + i)},[_vm._l((tr),function(td,bkey){return [(td)?_c('td',{key:("table-td-" + (td.key || bkey)),style:(("\n              text-align: " + (td.align ? td.align : 'left') + ";\n              color: " + (td.textColor ? td.textColor : 'initial') + "\n            "))},[_vm._v(" "+_vm._s(td.value)+" "),(td.action && td.action.type === 'download')?_c('document-download-icon',_vm._g({staticClass:"table__download",attrs:{"size":"16"}},td.action.events)):_vm._e()],1):_vm._e()]})],2)}),0),_vm._t("footer")],2):_c('alert',{attrs:{"type":"info"}},[_vm._v(" Нет данных ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }