<template>
  <modal 
    title="Оплата" 
    class="modal--ident pay-modal"
    @close="$emit('close')"
  >
    <div class="form-box">
      <form 
        v-loading="isSending"
        class="form-box__form pay-modal__form" 
        @submit.prevent 
      >
        <!-- ЛС -->
        <form-box-select
          v-if="form.accountID"
          v-model="form.accountID"
          label="Договор"
          :list="accountInfo"
          valuePropName="AccountID"
          keyPropName="AccountID"
          @input="setForm"
        >
          <template #selectedOption="{ item }">
            (Л/с: {{ item.Ident }}) {{ item.Address }}
          </template>
          <template #option="{ item }">
            {{ item.Ident }} ({{ item.AccountType }})
          </template>
        </form-box-select>

        <!-- Sum -->
        <form-box-input 
          v-model="form.sum"
          type="amount"
          label="Сумма оплаты (₽)"
          :readonly="!allowEditSum"
          message="Неверная сумма"
        />

        <!-- Страхование -->
        <template
          v-if="
            insuranceSum &&
            (!form.paymentSystem || form.paymentSystem == 'Tinkoff')
          "
        >
          <form-box-checkbox
            v-model="form.insuranceEnabledByUser"
            :label="`Страхование ${insuranceSum} ₽`"
            refName="insuranceControl"
            inputId="insurance"
            :classModificators="['pay-modal']"
          />

          <div class="pay-modal__insurance">
            <a
              class="pay-modal__insurance-link pay-modal__insurance-link--rules"
              target="_blank"
              href="https://sm-center.ru/vsk_polis.pdf"
            >
              Условия страхования
            </a>
            <a
              class="pay-modal__insurance-link pay-modal__insurance-link--disable"
              @click.prevent="disableInsurance()"
              href="javascript:;"
            >
              Не предлагать страхование
            </a>
          </div>
        </template>

        <!-- Save card  -->
        <form-box-checkbox
          v-if="
            form.paymentSystem == 'Tinkoff' ||
              (paymentSystems &&
                paymentSystems.length === 1 &&
                paymentSystems[0].name == 'Tinkoff')
          "
          v-model="form.allowCardSaving"
          label="Cохранить карту"
          refName="saveCardControl"
          inputId="save-card"
          :classModificators="['pay-modal']"
        />

        <!-- Payment system -->
        <template v-if="paymentSystems && paymentSystems.length > 1">
          <form-box-radioset
            v-model="form.paymentSystem"
            label="Платежная система"
            :list="paymentSystemsRadiosetList"
            message="Выберите платежную систему"
            class="pay-modal__payment-system"
            :classModificators="['pay-modal']"
          >
            <template #label="{ item }">
              <div class="payment-system">
                <img
                  class="payment-system__icon"
                  :src="getPaymentSystemImage(item.value)"
                />
                {{ item.title }}
              </div>
            </template>
          </form-box-radioset>
        </template>

        <!-- Totals -->
        <div class="pay-modal__totals">
          <p 
            v-loading="commissionIsLoading"
            class="pay-modal__total pay-modal__total--sum" 
          >
            Итого:
            <strong>{{ totalSum }} ₽</strong>
          </p>
          <template v-if="!hideComissionInfo">
            <p 
              v-if="commission > 0"
              class="pay-modal__total pay-modal__total--commission">
              *Комиссия: {{ commission }} ₽
            </p>
            <p 
              v-else 
              class="pay-modal__total pay-modal__total--commission"
            >
              *Комиссия не взимается
            </p>
          </template>
        </div>

        <base-button
          :disabled="!form.sum || (form.sum && +form.sum <= 0)"
          class="pay-modal__button"
          @click="startPayment"
        >
          Оплатить
        </base-button>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import modal from "@/components/ui/Modal";
import * as commonApi from "@/api/common";
import FormBoxInput from '@/components/form/FormBoxInput';
import FormBoxSelect from '@/components/form/FormBoxSelect';
import FormBoxCheckbox from '@/components/form/FormBoxCheckbox';
import FormBoxRadioset from '@/components/form/FormBoxRadioset';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: "payModal",

  components: { 
    modal,
    FormBoxInput,
    FormBoxSelect,
    FormBoxCheckbox,
    FormBoxRadioset,
    BaseButton
  },

  props: {
    id: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      form: {
        accountID: null,
        sum: 0,
        allowCardSaving: false,
        paymentSystem: null,
        insuranceEnabledByUser: false,
      },

      insuranceSum: 0,
      totalSum: 0,
      commission: 0,
      hideComissionInfo: true,
      commissionIsLoading: false,
      //accountInfoIDs: [],
      accountInfo: [],
      paymentSystems: [],
      isSending: false,
      isPaymentSystemSelectionMode: false,
      allowEditSum: true,
      sumControlIsFocus: false
    };
  },

  computed: {
    ...mapGetters({
      getAllInfo: 'account/getAllInfo'
    }),

    isGranel() {
      // TODO Временно убираем у Гранеля Сбербанк на фронте
      // Бэк должен сделать на своей стороне
      return window.appSettings.apiUrl.split("/").includes("komfortnew");
    },

    /**
     * uniqueAccounts - Уникальные лицевые счета. Удалены дубликаты.
     * @returns {array} Список счетов.
     */
    uniqueAccounts() {
      return this.accountInfo.filter((x, i, self) => {
        return self.findIndex((t) => t.Ident == x.Ident) === i
      });
    },

    paymentSystemsRadiosetList() {
      return this.paymentSystems.map((item) => {
        return {
          title: item.displayName,
          value: item.name
        }
      });
    },
  },

  mounted() {
    this.form.accountID = this.id;
    this.getAccountInfo();
    this.setPaymentSystems();
  },

  methods: {
    ...mapActions({
      getSumWithCommission: 'account/getSumWithCommission',
      disableAccountInsurance: 'account/disableAccountInsurance',
      getPaymentSystems: 'account/getPaymentSystems',
      getPayLink: 'account/getPayLink',
    }),

    getAccountInfo() {
      this.accountInfo = this.getAllInfo();
      this.setForm();
    },

    disableInsurance() {
      // console.log('--- disableInsurance ---')
      this.isSending = true;

      this.disableAccountInsurance(this.id)
        .then((result) => {
          this.isSending = false;
          if (result.error) {
            this.$notify.error({
              position: "top-left",
              title: "Ошибка",
              message: result.error,
            });
          } else {
            this.getAccountInfo();
          }
        })
        .catch(() => (this.isSending = false));
    },

    setPaymentSystems() {
      this.isSending = true;

      this.getPaymentSystems()
        .then((result) => {
          this.isSending = false;
          this.paymentSystems = result;
          if (this.paymentSystems && this.paymentSystems.length > 1) {
            this.form.paymentSystem = this.paymentSystems[0].name;
          } else {
            this.form.paymentSystem = "Tinkoff";
          }
        })
        .catch(() => (this.isSending = false));
    },

    setForm() {
      const accountInfo = this.accountInfo.find((item) => item.AccountID === +this.form.accountID); 

      if (accountInfo) {
        this.insuranceSum = accountInfo.InsuranceSum;

        this.form.sum = accountInfo.Sum;
        this.form.paymentSystem = this.form ? this.form.paymentSystem : null;
        this.form.allowCardSaving = false;
        this.form.insuranceEnabledByUser = accountInfo.InsuranceEnabledByUser
      } else {
        this.allowEditSum = false;

        this.form.sum = this.accountInfo.reduce((accumulator, current) => {
          return parseFloat((accumulator + current.Sum).toFixed(2));
        }, 0);
        this.form.accountID = null;
        this.form.paymentSystem = this.form ? this.form.paymentSystem : null;
        this.form.allowCardSaving = false;
      }
    },

    startPayment() {
      if (parseInt(this.form.sum) > 0) {
        this.setPayLink();
      }
    },

    getPaymentSystemImage(name) {
      return commonApi.getPaymentSystemImageDownloadLink(name);
    },

    setPayLink() {
      this.isSending = true;
      this.getPayLink(this.form)
        .then((result) => {
          this.isSending = false;
          this.isPaymentSystemSelectionMode = false;
          if (result.payLink && result.payLink.length > 0) {
            location.href = result.payLink;
          }
        })
        .catch(() => {
          this.isSending = false;
          this.isPaymentSystemSelectionMode = false;
        });
    },

    getCommission() {
      if (!this.form.sum) {
        this.commission = 0;
        this.totalSum = 0;
        return;
      }

      this.commissionIsLoading = true;

      this.getSumWithCommission({
          sum: parseFloat(this.form.sum) + this.insuranceSum,
          accountID: this.form.accountID,
        })
        .then((result) => {
          this.commission = result.Commission;
          this.totalSum = result.TotalSum;
          this.hideComissionInfo = result.HideComissionInfo;
          this.commissionIsLoading = false;
        })
        .catch(() => (this.commissionIsLoading = false));
    },
  },
  watch: {
    "form.sum"() {
      this.getCommission();
    },
    "form.insuranceEnabledByUser"() {
      this.getCommission();
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/form'

.pay-modal
  .payment-system
    display: flex
    align-items: center

.pay-modal__form
  margin-bottom: 0

.pay-modal__insurance
  display: flex
  justify-content: space-between
  margin-bottom: 12px

.pay-modal__insurance-link
  +TextButtonMini()

.pay-modal__totals
  margin-bottom: 24px

.pay-modal__total
  +TextMain()

  &--commission
    +TextMini()

  &:not(:last-child)
    margin-bottom: 2px

  strong
    +TextHeader(2)
    display: inline-block
    margin-left: 8px

.pay-modal__button
  margin-bottom: 0
</style>
