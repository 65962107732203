<template>
  <div class="chat">
    <!-- Chat container -->
    <div 
      ref="chatContainer"
      class="chat__content"
    >
      <perfect-scrollbar
        ref="dialogListScroll"
        class="chat__wrapper"
      >
        <template
          v-for="(messagesArray, date) in messages"
        >
          <div
            v-loading="isLoadingMessages"
            :key="`list-date-${date}`"
          >
            <div
              v-for="(message, i) in messagesArray"
              :key="i"
              :class="[
                'chat__message',
                { 'chat__message--user': message.IsSelf }
              ]"
            >
              <div class="message">
                <div class="message__header">
                  <p class="message__name">
                    {{ message.IsSelf ? 'Вы' : 'Оператор' }}
                  </p>
                  <span class="message__date">{{ convertDate(date, message.Added) }}</span>
                </div>

                <template v-if="message.FileID > 0">
                  <a 
                    class="message__text--file"
                    @click="getFile(message.FileID)"
                  >
                    <download-icon class="message__download-icon" />
                    {{ message.Text }}
                  </a>
                </template>
                <template v-else>
                  <p class="message__text">{{ message.Text }}</p>
                </template>
              </div>
            </div>
          </div>
        </template>
      </perfect-scrollbar>
    </div>

    <!-- Message form -->
    <form 
      ref="modalMessageForm"
      class="chat__form"
      v-on:submit.prevent 
    >
      <!-- Attach -->
      <paper-clip-icon 
        class="chat__attach-icon" 
        @click="$refs.fileControl.click()"
      />
      <input
        ref="fileControl"
        type="file"
        @change="addFile"
        name="file"
        style="display: none"
      />

      <!-- Message textarea -->
      <input
        type="text"
        placeholder="Сообщение"
        :disabled="isSendingMessage"
        class="chat__input"
        v-model="form.message"
        @keyup.enter="addMessage"
      />

      <!-- Send button -->
      <button
        type="submit" 
        title="Отправить"
        class="chat__send-button" 
        v-loading="isSendingMessage"
        @click="addMessage"
      >
        <paper-airplane-icon class="chat__send-icon icon"/>
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PaperClipIcon, PaperAirplaneIcon, DownloadIcon } from "@vue-hero-icons/outline";

export default {
  name: 'supportChat',

  components: { 
    PaperClipIcon,
    PaperAirplaneIcon,
    DownloadIcon
  },

  props: {
    phone: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      dialogListScroll: null,
      isLoadingMessages: false,
      isSendingMessage: false,
      refreshTimer: null,
      form: {
        phone: this.phone,
        message: null,
        file: null,
      },
    }
  },

  created() {
    this.getMessages();

    this.resizeThrottlerFunctionList.push(
      this.setChatHeight
    );
  },

  mounted() {
    this.scrollToBottom();

    this.timer = setInterval(() => {
      this.update();
    }, 5000);
  },

  updated() {
    this.setChatHeight();
    this.scrollToBottom();
  },

  destroyed() {
    clearInterval(this.timer);
  },

  computed: {
    ...mapGetters({
      getSortedMessages: 'common/getSortedMessages'
    }),

    messages() {
      return this.getSortedMessages;
    },
  },

  methods: {
    ...mapActions({
      getMessagesAction: 'common/getMessages',
      addMessageAction: 'common/addMessage',
      addFileAction: 'common/addFile',
      getFileAction: 'common/getFile',
      updateMessagesAction: 'common/updateMessages'
    }),

    getMessages() {
      this.isLoadingMessages = true;
      this.getMessagesAction({
          phone: this.form.phone,
          deviceID: localStorage.getItem("deviceID"),
          info: navigator.userAgent,
        })
        .then(() => (this.isLoadingMessages = false))
        .catch(() => {
          this.isLoadingMessages = false;
          this.$notify.error("Ошибка загрузки сообщений");
        });
    },

    addMessage() {
      if (this.form.message && this.form.message.length > 0) {
        this.isSendingMessage = true;
        this.addMessageAction({
            text: this.form.message,
            phone: this.form.phone,
            deviceID: localStorage.getItem("deviceID"),
          })
          .then(() => {
            this.form.message = "";
            this.isSendingMessage = false;
            this.getMessages();
          })
          .catch(() => {
            this.isSendingMessage = false;
            this.$notify.error("Ошибка отправки файла");
          });
      }
    },

    addFile(event) {
      let fd = new FormData();
      fd.append("file", event.target.files[0]);
      fd.append("deviceID", localStorage.getItem("deviceID"));
      this.addFileAction(fd)
        .then(() => {
          this.getMessages();
        })
        .catch(() => {
          this.$notify.error("Ошибка загрузки файла");
        });
    },

    getFile(id) {
      this.getFileAction({
        id,
        deviceID: localStorage.getItem("deviceID"),
      });
    },

    update() {
      this.updateMessagesAction({
          deviceID: localStorage.getItem("deviceID"),
          info: navigator.userAgent,
        })
        .catch(() => {
          this.$notify.error("Ошибка загрузки сообщений");
        });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.dialogListScroll) {
          const $dialog = this.$refs.dialogListScroll;

          $dialog.$el.scrollTop = $dialog.$el.scrollHeight;
        }
      });
    },

    convertDate(date, time) {
      const timeArray = time.split(':');

      return `${date} ${timeArray[0]}:${timeArray[1]}`;
    },

    setChatHeight() {
      this.$nextTick(() => {
        if (this.$refs.dialogListScroll) {
          const $modal = this.$parent.$refs.modal;
          const $modalHeader = this.$parent.$refs.modalHeader;
          const $modalMessageForm = this.$refs.modalMessageForm;
          const $chat = this.$refs.dialogListScroll;
          const height = $modal.clientHeight - ($modalHeader.clientHeight + $modalMessageForm.clientHeight);

          $chat.$el.style = `max-height: ${height}px`;
        }
      });
    }
  },
}
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.chat
  display: flex
  height: 100%
  flex:
    direction: column
    basis: 100%

  .message
    +Size(90%, auto)
    max-width: 400px
    background-color: $cElmLight
    border-radius: 16px 16px 16px 4px
    padding: 12px

    @media screen and (min-width: 992px)
      padding: 16px

  .message__header
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 0 10px

    @media screen and (min-width: 992px)
      margin-bottom: 14px

  .message__name
    +TextSubMain()
    margin: 0

  .message__date
    +TextMini()
    flex-shrink: 0

  .message__text
    +TextMain()
    margin-bottom: 0

    &--file
      +TextMain($cAccentBlue)
      display: inline-flex
      align-items: center

      &:hover
        text-decoration: underline
        cursor: pointer
    
  .message__download-icon
    margin-right: 8px

.chat__content
  flex:
    shrink: 1
    basis: 100%

.chat__wrapper
  padding: 12px 16px

  @media screen and (min-width: 992px)
    padding: 12px 24px

.chat__message
  display: flex
  margin: 0 0 16px

  @media screen and (min-width: 992px)
    margin-bottom: 24px

  &--user
    justify-content: flex-end

    .message
      border-radius: 16px 16px 4px 16px
      background-color: $cBgSuccess

.chat__form
  flex-shrink: 0
  display: flex
  justify-content: space-between
  align-items: center
  background: $cBgSec
  border-top: 1px solid $cBorder
  padding: 16px

.chat__attach-icon
  +Size(24px)
  +Transition((color))
  flex-shrink: 0
  color: $cTextDisabled
  cursor: pointer
  margin-right: 12px

  &:hover, &:focus
    color: $cTextMain

  @media screen and (min-width: 992px)
    margin-right: 17px

.chat__input
  +Size(100%, $sHug)
  flex-shrink: 1
  background-color: $cBgMain
  border: 1px solid $cBorder
  border-radius: 16px
  padding: 18px 16px

.chat__send-button
  +ButtonIcon()
  flex-shrink: 0
  margin-left: 12px

  @media screen and (min-width: 992px)
    margin-left: 17px

.chat__send-icon
  transform: rotate(45deg)
</style>