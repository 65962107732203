<template>
  <article>
    <table 
      v-if="tableData.length"
      :class="[
        'table',
        `table--${type}`
      ]"
    >
      <thead 
        v-if="header"
        class="table__header"
      >
        <tr>
          <template v-for="(th, hkey) in header">
            <th
              v-if="th"
              :key="`table-header-${hkey}`"
              :style="`
                text-align: ${th.align ? th.align : 'left'};
                width: ${th.width ? th.width : 'auto'};
                cursor: ${th.sorting ? 'pointer' : 'default'}
              `"
              @click="(th.sorting) ? sortingData($event, th.sorting.key) : false"
            >
              {{ th.title }}
              <span 
                v-if="th.sorting"
                :class="[
                  'table__sorting',
                  { 'table__sorting--active': (sorting.find(item => item.key === th.sorting.key)['active']) },
                  { 'table__sorting--asc': sorting.find(item => item.key === th.sorting.key)['direction'] === 'asc' },
                  { 'table__sorting--desk': sorting.find(item => item.key === th.sorting.key)['direction'] === 'desc' }
                ]"
              >
                <arrow-down-icon size="16" />
              </span>
            </th>
          </template>
        </tr>
      </thead>

      <tbody class="table__body">
        <tr
          v-for="(tr, i) in tableData"
          :key="`table-tr-${i}`"
        >
          <template v-for="(td, bkey) in tr">
            <td
              v-if="td"
              :key="`table-td-${td.key || bkey}`"
              :style="`
                text-align: ${td.align ? td.align : 'left'};
                color: ${td.textColor ? td.textColor : 'initial'}
              `"
            >
              {{ td.value }}
              <document-download-icon 
                v-if="td.action && td.action.type === 'download'"
                size="16" 
                class="table__download"
                v-on="td.action.events"
              />
            </td>
          </template>
        </tr>
      </tbody>

      <slot name="footer" />
    </table>

    <alert 
      v-else
      type="info"
    >
      Нет данных
    </alert>
  </article>
</template>

<script>
import { ArrowDownIcon, DocumentDownloadIcon } from "@vue-hero-icons/outline";
import Alert from '@/components/ui/Alert';

export default {
  name: 'SmTable',

  components: {
    ArrowDownIcon,
    DocumentDownloadIcon,
    Alert
  },

  props: {
    header: {
      type: [ Object, Array ],
      required: false,
      default() {
        return {}
      }
    },
    body: {
      type: [ Object, Array ],
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'blue'
    }
  },

  data() {
    return {
      sorting: []
    }
  },

  computed: {
    tableData() {
      return [...this.body]
    },
  },

  created() {
    this.initSorting();
  },

  methods: {
    initSorting() {
      // console.log('--- initSorting method ---')

      const sortingColumn = this.header.filter(item => item && item.sorting);

      if (sortingColumn) {
        this.sorting = sortingColumn.map(({ sorting }, i) => {
          return {
            key: sorting.key,
            direction: (sorting.direction) ? sorting.direction : 
              (i === 0) ? 'asc' : 'desc',
            active: (i === 0)
          };
        })
      }
    },

    sortingData(_, key) {
      if (typeof key !== 'undefined') {
        // console.log('--- thClickHandler methid ---')

        // Меняем иконки
        this.sorting.map((item) => {
          if (item.key === key) {
            if (item.active) {
              item.direction = (item.direction === 'asc') ? 'desc' : 'asc';
            } else {
              item.active = true;
              item.direction = 'asc';
            }
          } else {
            item.active = false;
            item.direction = 'desc';
          }
        });

        // Сортируем массив
        const direction = this.sorting.find(item => item.key === key)['direction'];

        this.tableData.sort((a, b) => {
          const rowA = a.find(item => item.key === key);
          const rowB = b.find(item => item.key === key);
          let sortingValueA;
          let sortingValueB;

          if (typeof rowA.sortableValue !== 'undefined') {
            sortingValueA = rowA.sortableValue;
          } else {
            sortingValueA = rowA.value;
          }
          if (typeof rowB.sortableValue !== 'undefined') {
            sortingValueB = rowB.sortableValue;
          } else {
            sortingValueB = rowB.value;
          }

          if (direction === 'asc') {
            if (sortingValueA > sortingValueB) {
              return 1;
            }
            if (sortingValueA < sortingValueB) {
              return -1;
            }
          }

          if (direction === 'desc') {
            if (sortingValueA > sortingValueB) {
              return -1;
            }
            if (sortingValueA < sortingValueB) {
              return 1;
            }
          }
          
          return 0;
        })
      }
    }
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.table
  width: 100%
  border-collapse: collapse
  
  th, td
    box-sizing: border-box

  &--blue
    .table__header
      th
        color: $cTextMain
        background-color: $cElmLight

  &--white
    .table__header
      th
        color: $cTextSec
        background-color: $cBgSec

.table__header
  th
    // +TextMini($cTextMain)
    +TextMini()
    vertical-align: middle
    // background-color: $cElmLight
    border-bottom: 1px solid $cBorder
    padding: 6px

    &:not(:last-child)
      border-right: 1px solid $cBorder

    @media screen and (min-width: 768px)
      padding: 6px 16px

.table__body
  td
    +TextMini($cTextMain)

    border-bottom: 1px solid $cBorder
    padding: 6px

    &:not(:last-child)
      border-right: 1px solid $cBorder

  &--empty
    td
      +TextMain($cError)
      text-align: center

  @media screen and (min-width: 768px)
    td
      padding: 6px 24px

  tr:last-child td
    border-bottom: 0

.table__sorting
  +Size(100%, 10px)
  display: inline-block
  color: $cIconNeutral
  vertical-align: middle
  transition: transform .15s
  margin-top: 4px

  &--active
    color: $cAccentBlue

  &--asc
    transform: rotate(0deg)

  &--desk
    transform: rotate(180deg)

  svg
    +Size(100%)

  @media screen and (min-width: 768px)
    +Size(16px)
    margin-top: 0
    margin-left: 8px

.table__download
  +Transition((color))
  display: inline-flex
  color: $cAccentBlue
  cursor: pointer

  &:hover
    color: $cAccent
</style>