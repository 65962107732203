var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modal",class:[
    'modal',
    { 'modal--base': _vm.setBaseStyle },
    { 'modal--chat': _vm.chatMode }
  ]},[_c('div',{ref:"modalHeader",class:[
      'modal__header',
      { 'modal__header--left': !_vm.title || (_vm.title && _vm.closeButton) }
    ]},[(_vm.title)?_c('h4',{staticClass:"modal__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.closeButton && !_vm.dialog)?_c('x-icon',{staticClass:"modal__close-icon",attrs:{"role":"button"},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e()],1),_c('div',{staticClass:"modal__body"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }