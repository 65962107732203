<template>
  <div class="layout--page" ref="layout">
    <header-block ref="header" />
    <main 
      ref="main"
      class="page" 
    >
      <div class="page__container">
        <slot />
      </div>
    </main>
    <footer-block ref="footer" />
  </div>
</template>

<script>
import HeaderBlock from "@/layouts/HeaderBlock";
import FooterBlock from "@/layouts/FooterBlock";

export default {
  name: "Page",

  components: { 
    HeaderBlock, 
    FooterBlock, 
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$root.$emit("page-loading", newValue);
      }
    },
  },

  created() {
    this.resizeThrottlerFunctionList.push(
      this.setLayoutMainOffset
    );
  },

  mounted() {
    this.setLayoutMainOffset();
  },

  methods: {
    setLayoutMainOffset() {
      setTimeout(() => {
        const $main = this.$refs.main;
        const $header = this.$refs.header;

        if ($header) {
          const height = $header.$el.offsetHeight;

          $main.style.marginTop = `${height}px`;
        }
      }, 600);
    }
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"

.page
  +Size(100%)
  max-width: $sMWContainer
  flex-grow: 1

  &--requests
    .page__container
      padding-top: 0
      padding-bottom: 0

.page__container
  +PageLayoutContainer(false)
  padding: 
    top: 0
    bottom: 0
</style>
