<template>
  <div class="polls-wrapper__polls-item polls-item">
    <div class="polls-item__row">
      <h4 class="polls-item__name">{{ poll.Name }}</h4>
      <div class="polls-item__indicator">
        <span v-if="!poll.IsReaded" class="polls-item__dot"></span>
      </div>
    </div>
    <div class="polls-item__row">
      <div class="polls-item__cell">
        Количество вопросов:
        <span class="polls-item__count">{{ poll.Questions.length }}</span>
      </div>
      <div class="polls-item__cell">
        Количество отвеченных:
        <span class="polls-item__count">{{ countPassedQuestions }}</span>
      </div>
    </div>
    <span class="main-btn polls-item__button" @click="openPoll">
      <span class="glipf-polls"></span
      ><span
        v-html="
          countPassedQuestions < poll.Questions.length
            ? 'Пройти опрос'
            : 'Показать ответы'
        "
      ></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "PollItem",
  props: {
    poll: {
      type: Object,
      require: true,
    },
  },
  computed: {
    countPassedQuestions() {
      return this.poll.Questions.filter((item) => {
        return item.IsCompleteByUser;
      }).length;
    },
  },
  methods: {
    openPoll() {
      this.$emit("startpoll", this.poll);
    },
  },
};
</script>

<style>
.polls-item {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flex !important;
  display: -o-flex !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  background-color: white;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  padding: 1.3em;
  font-weight: 300;
}
.theme-light .polls-item::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin-top: 0px;
  border-radius: 50px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: -2px;
  margin-left: -2px;
  background: -webkit-linear-gradient(
    bottom,
    rgba(var(--base-color-rgb), 0.5) 0%,
    rgba(var(--base-color-rgb), 0.1) 25%,
    rgba(var(--base-color-rgb), 0.1) 75%,
    rgba(var(--base-color-rgb), 0.5) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(var(--base-color-rgb), 0.5) 0%,
    rgba(var(--base-color-rgb), 0.1) 25%,
    rgba(var(--base-color-rgb), 0.1) 75%,
    rgba(var(--base-color-rgb), 0.5) 100%
  );
}
.theme-light .polls-item {
  border: 1px solid transparent;
  margin-top: 1px;
  height: calc(100% - 2px);
  position: relative;
}

.polls-item__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}
.polls-item__name {
  flex: 0 0 90%;
}
.polls-item__indicator {
  flex: 0 0 10%;
}
.polls-item__dot {
  height: 10px;
  width: 10px;
  background-color: #f00;
  border-radius: 50%;
  display: block;
  margin-left: auto;
}
.polls-item__cell {
  flex: 0 0 50%;
  font-size: 0.74rem;
}
.polls-item__count {
  color: var(--base-color);
}
.polls-item__button {
  color: #fff !important;
}
.glipf-polls {
  padding-right: 5px;
}
</style>
