<template>
  <div class="home-page">
    <div v-loading="!accounts">
      <template v-if="accounts">
        <template v-if="accounts.length">
          <div v-loading="isLoading">
            <!-- Уведомления -->
            <notifications-section 
              v-if="announcements && !authUser.accessOSS"
              :items="announcements"
              class="home-page__section"
            />

            <!-- TODO: Собрания -->

            <!-- Новости -->
            <news-section
              v-if="news && !authUser.accessOSS"
              :items="news"
              class="home-page__section"
            />

            <!-- Опросы -->
            <!-- <polls-section
              v-if="getMenuVisibilitySettings.Polls"
              :items="polls"
              class="home-page__section"
            /> -->

            <!-- Специальные предложения -->
            <!-- <special-offers-section
              :items="additionalServices"
              class="home-page__section"
            /> -->
          </div>
        </template>

        <section v-else>
          <add-account-ident text="быть в курсе" />
        </section>
      </template>
    </div>

    <!-- TODO: Old code. Удалить -->
    <!-- <div class="container">
      <div class="row">
        <div class="main_single-column">

          // ! Уведомления
          <template v-if="!authUser.accessOSS">
            <div :class="['section-title', 'slider-title', 'white-title']">
              <span class="glipf-events-2"></span>Уведомления
            </div>
            <vue-slick-carousel
              :arrows="true"
              :slidesToShow="slidesToShow"
              class="news-slider notice-slider"
            >
              <div class="news-item" v-if="!(announcements.length > 0)">
                <div class="news-item_title none">Уведомлений нет</div>
              </div>

              <div
                class="news-item"
                v-for="announce in announcements"
                :key="`announce-${announce.ID}`"
              >
                <div class="news-item_title">
                  <router-link
                    :to="{
                      name: 'Announcement',
                      params: { id: announce.ID },
                    }"
                    >{{ announce.Header }}</router-link
                  >
                </div>
                <span class="news-item_date">{{ announce.Created }}</span>
                <p></p>
                <div class="text-right">
                  <router-link
                    :to="{
                      name: 'Announcement',
                      params: { id: announce.ID },
                    }"
                    class="news-item_more-details"
                  >
                    <span class="text">Подробнее</span>
                  </router-link>
                </div>
              </div>
            </vue-slick-carousel>
          </template>

          <template v-if="!hasAccounts && !authUser.accessOSS">
            <div class="events-noaccounts">
              <div class="adress-box">
                <div class="common-warning-text">
                  Лицевые счета не подключены
                </div>
                <a
                  @click="openAddAccountIdentModal"
                  style
                  class="common-warning-link"
                  >+ Подключить лицевой счёт</a
                >
              </div>
            </div>
          </template>

          // ! TODO: Список собраний собственников
          <template v-if="authUser.accessOSS">
            <template v-if="oss.length > 0">
              <div :class="['section-title', 'slider-title']">
                <span class="glipf-events-oss"></span>Собрания собственников
              </div>
              <vue-slick-carousel
                :arrows="true"
                :slidesToShow="slidesToShow"
                class="news-slider notice-slider"
              >
                <div class="news-item" v-if="!(oss.length > 0)">
                  <div class="news-item_title none">Собраний нет</div>
                </div>

                <div
                  class="news-item"
                  v-for="item in oss"
                  :key="`oss-${item.ID}`"
                >
                  <div class="news-item_title">
                    <router-link
                      :to="{ name: 'OssInfo', params: { id: item.ID } }"
                      >{{
                        item.MeetingTitle || "Наименование не указано"
                      }}</router-link
                    >
                  </div>
                  <span class="news-item_date"
                    >{{ dateNoTime(item.DateStart) }} -
                    {{ dateNoTime(item.DateEnd) }}</span
                  >
                  <br />
                  <span>{{ item.Status }}</span>
                  <p></p>
                  <div class="text-right">
                    <router-link
                      :to="{ name: 'OssInfo', params: { id: item.ID } }"
                      class="news-item_more-details"
                    >
                      <span class="text">Подробнее</span>
                    </router-link>
                  </div>
                </div>
              </vue-slick-carousel>
            </template>
            <template v-else>
              <div :class="['section-title', 'slider-title']">
                <span class="glipf-events-oss"></span>Нет активных собраний
              </div>
            </template>
          </template>

          // ! Специальные предложения
          <template v-if="additionalServices.length > 0">
            <div :class="['section-title', 'slider-title']">
              <span class="glipf-discount"></span>Специальные предложения
            </div>
            <vue-slick-carousel
              :arrows="true"
              :slidesToShow="slidesToShow"
              class="special-offers_slider"
            >
              <div v-if="!(additionalServices.length > 0)"></div>
              <ServiceItem
                v-for="shop in additionalServices"
                :key="`special-offer_item_${shop.ID}`"
                :shop="shop"
              >
                <router-link
                  :to="{
                    name: 'Shop',
                    params: {
                      id: shop.ShopID,
                      AdditionalServiceId: shop.ID,
                      type: shop.ShopType,
                      id_RequestType: shop.id_RequestType,
                      breadcrumbs: [
                        {
                          text: `События`,
                          route: {
                            name: 'Home',
                          },
                        },
                        {
                          text: `${shop.Name}`,
                          route: '',
                        },
                      ],
                    },
                    props: {},
                  }"
                >
                  <img
                    v-if="shop.HasLogo"
                    :src="shop.LogoLink"
                    :alt="shop.Name"
                    class="shop__img"
                  />
                  <div class="shop__desc">{{ shop.Name }}</div>
                </router-link>
              </ServiceItem>
            </vue-slick-carousel>
          </template>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
//vuex
import { mapGetters, mapActions } from "vuex";

import NotificationsSection from '@/components/pages/home/NotificationsSection';
import NewsSection from '@/components/pages/home/NewsSection';
// import PollsSection from '@/components/pages/home/PollsSection';
// import SpecialOffersSection from '@/components/pages/home/SpecialOffersSection';
import AddAccountIdent from '@/components/pages/common/AddAccountIdent';
// import ServiceItem from "@/components/ui/ServiceItem";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "Home",

  components: { 
    // ServiceItem 
    NotificationsSection,
    NewsSection,
    // PollsSection,
    // SpecialOffersSection,
    AddAccountIdent
  },

  data() {
    return {
      newsIDs: [],
      ossIDs: [],
      announcementIDs: [],
      additionalServiceIDs: [],
      isLoading: true,
      isLoadingAccounts: true,
      isLoadingOss: false,
    };
  },

  computed: {
    ...mapGetters({
      getMenuVisibilitySettings: "config/getMenuVisibilitySettings",
      getNonPassedPolls: 'polls/getNonPassedPolls',
      getNews: 'news/getNews',
      getOss: 'oss/getOss',
      getAnnouncements: 'announcement/getAnnouncements',
      getServices: 'additionalService/getServices',
      accounts: 'account/getAccounts'
    }),

    news() {
      return this.getNews(this.newsIDs);
    },
    
    oss() {
      return this.getOss(this.ossIDs);
    },

    announcements() {
      return this.getAnnouncements(this.announcementIDs);
    },

    additionalServices() {
      return this.getServices(this.additionalServiceIDs);
    },

    polls() {
      return this.getNonPassedPolls;
    }
  },

  // TODO: Зачем это?
  // watch: {
  //   announcementIDs(newValue) {
  //     if (newValue.length > 0) {
  //       localStorage.setItem("announcementIDs", JSON.stringify(newValue));
  //     }
  //   },
  //   newsIDs(newValue) {
  //     if (newValue.length > 0) {
  //       localStorage.setItem("newsIDs", JSON.stringify(newValue));
  //     }
  //   },
  // },

  created() {
    // console.log('--- Home component page ---')
    // console.log('--- created hook ---')
    this.getAccountInfo();
    this.setEventBlockData();
    this.getOSS();
    
    this.$root.$on("updated-account-info", this.onAccountInfoChanged);
    this.$root.$on("deleted-account-info", this.onAccountInfoChanged);
  },

  destroyed() {
    this.$root.$off("updated-account-info", this.onAccountInfoChanged);
    this.$root.$off("deleted-account-info", this.onAccountInfoChanged);
  },

  methods: {
    ...mapActions({
      getUserInfo: 'account/getUserInfo',
      getEventBlockData: 'news/getEventBlockData',
    }),

    onAccountInfoChanged() {
      this.getAccountInfo();
      this.getOSS();
    },

    getAccountInfo() {
      this.isLoadingAccounts = true;

      this.getUserInfo()
        .finally(() => {
          this.isLoadingAccounts = false;
        });
    },

    // TODO: Have to remove
    // getCacheData() {
    //   let announcementIDs = localStorage.getItem("announcementIDs");

    //   this.announcementIDs =
    //     announcementIDs
    //       ? JSON.parse(announcementIDs)
    //       : this.announcementIDs;

    //   let newsIDs = localStorage.getItem("newsIDs");
    //   this.newsIDs = newsIDs !== null ? JSON.parse(newsIDs) : this.newsIDs;
    // },

    setEventBlockData() {
      this.isLoading = true;

      this.getEventBlockData()
        .then((result) => {
          if (result) {
            this.newsIDs = result.news.keys;
            this.announcementIDs = result.announcements.keys;
            this.additionalServiceIDs = result.services.keys;

            this.isLoading = false;
          }
        })
        .catch((error) => {
          throw error;
        })
    },

    getOSS() {
      this.isLoadingOss = true;
      this.$store
        .dispatch("oss/getOSS")
        .then((result) => {
          this.ossIDs = result.keys;
          this.isLoadingOss = false;
        })
        .catch(() => {
          this.$nextTick(() => {
            this.isLoadingOss = false;
          });
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.home-page
  padding: 24px 0 0

  @media screen and (min-width: 768px)
    padding: calc(40px - 12px) 0 0

.home-page__section
  margin-bottom: 20px
</style>
