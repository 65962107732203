<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    aria-labelledby="History"
    role="presentation"
  >
    <path
      d="M11 5.5V12.5"
      stroke="#002855"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 15.5L11 12.5"
      stroke="#002855"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 8.96448H0V3.96448"
      stroke="#002855"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.5 18.5C5 20.5 7.39801 21.8931 9.43718 22.2982C11.4764 22.7034 13.59 22.4955 15.5109 21.7007C17.4317 20.906 19.0735 19.5602 20.2286 17.8335C21.3837 16.1068 22.0002 14.0767 22.0002 12C22.0002 9.9233 21.3837 7.89323 20.2286 6.16651C19.0735 4.4398 17.4317 3.09399 15.5109 2.29927C13.59 1.50455 11.4764 1.29661 9.43718 1.70176C7.39801 2.1069 5.52491 3.10693 4.05475 4.57538L0.000244141 8.62517"
      stroke="#002855"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'HistoryIcon',
};
</script>
