var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-cart-page"},[_c('page-header',{attrs:{"back":{
    title: 'Назад',
    to: _vm.previousRouteParams
  }}}),_c('section',{staticClass:"user-cart-page__header"},[_c('div',{staticClass:"user-cart-page__header-wrap"},[_c('breadcrumbs',{staticClass:"user-cart-page__breadcrumbs",attrs:{"items":_vm.currentBreadcrumbs}}),_c('h1',{staticClass:"user-cart-page__title"},[_vm._v(" Оформление заказа ")])],1)]),_c('div',{staticClass:"user-cart-page__container"},[_c('section',{staticClass:"user-cart-page__column user-cart-page__column--left"},[_c('div',{staticClass:"user-cart-page__goods-list"},_vm._l((_vm.goods),function(good){return _c('cart-item',{key:("good-" + (good.ID)),attrs:{"good":good}})}),1)]),_c('section',{staticClass:"user-cart-page__column user-cart-page__column--right",attrs:{"id":"cart"}},[_c('div',{staticClass:"amount-card"},[_c('ul',{staticClass:"prices"},[(_vm.isGoodsType)?_c('li',{staticClass:"prices__field"},[_c('label',{staticClass:"prices__title"},[_vm._v("Вес:")]),_c('span',{staticClass:"prices__value"},[_vm._v(_vm._s(_vm.cartWeight)+" кг")])]):_vm._e(),_c('li',{staticClass:"prices__field"},[_c('label',{staticClass:"prices__title"},[_vm._v("Стоимость "+_vm._s(_vm.typeShopText)+":")]),_c('span',{staticClass:"prices__value"},[_vm._v(_vm._s(_vm.cartCost)+" ₽")])]),_c('li',{staticClass:"prices__field"},[_c('label',{staticClass:"prices__title"},[_vm._v("Общая стоимость:")]),_c('span',{staticClass:"prices__value"},[_vm._v(_vm._s(_vm.cartCost)+" ₽")])])]),_c('form-box-select',{staticClass:"amount-card__account-selector",attrs:{"label":"Лицевой счет","list":_vm.uniqueAccounts,"valuePropName":"Ident","keyPropName":"AccountID"},scopedSlots:_vm._u([{key:"selectedOption",fn:function(ref){
  var item = ref.item;
return [_vm._v(" (Л/с: "+_vm._s(item.Ident)+") "+_vm._s(item.Address)+" ")]}},{key:"option",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.Ident)+" ("+_vm._s(item.AccountType)+") ")]}}]),model:{value:(_vm.selectedIdent),callback:function ($$v) {_vm.selectedIdent=$$v},expression:"selectedIdent"}}),_c('div',{staticClass:"amount-card__buttons"},[_c('base-button',{staticClass:"amount-card__button",attrs:{"disabled":_vm.cartIsEmpty},on:{"click":_vm.onPay}},[_vm._v(" Подтвердить заказ ")]),_c('base-button',{staticClass:"amount-card__continue",attrs:{"style-class":"secondary-text"},on:{"click":function($event){return _vm.$router.push(_vm.previousRouteParams)}}},[_vm._v(" Продолжить покупки ")])],1)],1)])]),_c('modal',{attrs:{"name":"on-pay","addaptive":true,"height":"auto","scrollable":true}},[_c('modal-wrapper',{attrs:{"title":"Выберите лицевой счет"},on:{"close":function($event){return _vm.$modal.hide('on-pay')}}},[_c('div',{staticClass:"user-cart-page__account-selector"},[_c('form-box-select',{attrs:{"label":"Лицевой счет","list":_vm.uniqueAccounts,"valuePropName":"Ident","keyPropName":"AccountID"},scopedSlots:_vm._u([{key:"selectedOption",fn:function(ref){
  var item = ref.item;
return [_vm._v(" (Л/с: "+_vm._s(item.Ident)+") "+_vm._s(item.Address)+" ")]}},{key:"option",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.Ident)+" ("+_vm._s(item.AccountType)+") ")]}}]),model:{value:(_vm.selectedIdent),callback:function ($$v) {_vm.selectedIdent=$$v},expression:"selectedIdent"}})],1),_c('div',{staticClass:"user-cart-page__modal-buttons"},[_c('base-button',{staticClass:"user-cart-page__modal-button",attrs:{"style-class":"additional"},on:{"click":function($event){return _vm.$modal.hide('on-pay')}}},[_vm._v(" Отмена ")]),_c('base-button',{staticClass:"user-cart-page__modal-button",attrs:{"disabled":!_vm.selectedIdent},on:{"click":_vm.onPay}},[_vm._v(" Продолжить ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }