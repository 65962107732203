<template>
  <div class="layout--request-frame" ref="layout">
    <main 
      ref="main"
      class="page" 
    >
      <div class="page__container">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Page",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$root.$emit("page-loading", newValue);
      }
    },
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"

.page
  +Size(100%)
  // max-width: $sMWContainer
  flex-grow: 1

.page__container
  // +PageLayoutContainer(false)
  +Size(100%)
  // max-height: 100vh
  padding: 
    top: 0
    bottom: 0
    left: 0
    right: 0

  @media screen and (min-width: 1200px)
    padding: 
      top: 0
      bottom: 0
      left: 0
      right: 0
</style>
