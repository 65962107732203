<template>
  <div class="poll">
    <button
      @click="$emit('close')"
      class="close popup-form_close"
      type="button"
    >
      <span class="glipf-remove"></span>
    </button>
    <h2 class="main-inner_title poll__name">{{ poll.Name }}</h2>
    <div class="poll__question">
      <p class="poll__question-text" v-html="currentQuestion.Text"></p>
      <div
        v-if="
          currentQuestion.AnswerType === 'single' ||
            currentQuestion.AnswerType === null
        "
        class="poll__answers"
      >
        <label
          v-for="answer in currentQuestion.Answers"
          :key="`answer-${answer.ID}`"
        >
          <input
            type="radio"
            name="pollSingleAnswer"
            :checked="
              isChecked({
                QuestionId: currentQuestion.ID,
                AnswerId: answer.ID,
              })
            "
            :disabled="currentQuestion.IsCompleteByUser"
            @change="
              setSingleAnswer($event, {
                QuestionId: currentQuestion.ID,
                AnswerId: answer.ID,
                CustomAnswer: null,
              })
            "
          />
          {{ answer.Text }}
        </label>
      </div>
      <div
        v-if="currentQuestion.AnswerType === 'multiple'"
        class="poll__answers"
      >
        <label
          v-for="answer in currentQuestion.Answers"
          :key="`answer-${answer.ID}`"
        >
          <input
            type="checkbox"
            name="pollMultipleAnswer"
            :checked="
              isChecked({
                QuestionId: currentQuestion.ID,
                AnswerId: answer.ID,
              })
            "
            :disabled="currentQuestion.IsCompleteByUser"
            @change="
              setMultipleAnswer($event, {
                QuestionId: currentQuestion.ID,
                AnswerId: answer.ID,
                CustomAnswer: null,
              })
            "
          />
          {{ answer.Text }}
        </label>
      </div>
      <div v-if="currentQuestion.AnswerType === 'open'" class="poll__answers">
        <textarea
          class="poll__open-answer"
          :value="getOpenAnswer(currentQuestion.ID)"
          :readonly="currentQuestion.IsCompleteByUser"
          @input="
            setOpenAnswer({
              QuestionId: currentQuestion.ID,
              AnswerId: null,
              CustomAnswer: $event.target.value,
            })
          "
        />
      </div>
    </div>

    <div class="poll__buttons">
      <div class="poll_button-wrapper">
        <span
          v-if="selectedQuestionIndex > 0"
          class="poll__button main-btn"
          @click="selectedQuestionIndex -= 1"
        >
          <span class="poll__back-ico"></span>
          <span>Назад</span>
        </span>
      </div>

      <div class="poll_button-wrapper">
        <span
          v-if="
            selectedQuestionIndex == poll.Questions.length - 1 &&
              hasAnswer &&
              !currentQuestion.IsCompleteByUser
          "
          class="poll__button main-btn"
          @click="savePoll"
        >
          <span>Завершить</span>
        </span>
        <span
          v-if="
            selectedQuestionIndex == poll.Questions.length - 1 &&
              currentQuestion.IsCompleteByUser
          "
          class="poll__button main-btn"
          @click="$emit('close')"
        >
          <span>Закрыть</span>
        </span>
        <span
          v-else-if="
            currentQuestion.IsCompleteByUser ||
              (selectedQuestionIndex != poll.Questions.length - 1 && hasAnswer)
          "
          class="poll__button main-btn"
          @click="selectedQuestionIndex += 1"
        >
          <span>Далее</span>
          <span class="poll__next-ico"></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
//vuex
import { mapActions } from "vuex";

export default {
  name: "PollForm",
  props: {
    poll: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      selectedQuestionIndex: 0,
      initialPollingResult: {
        PollId: null,
        ExtraInfo: "",
        Answers: [],
      },
      PollingResult: {
        PollId: null,
        ExtraInfo: "",
        Answers: [],
      },
    };
  },
  computed: {
    currentQuestion() {
      return this.poll.Questions[this.selectedQuestionIndex];
    },
    hasAnswer() {
      if (
        this.currentQuestion.AnswerType === "open" &&
        !!this.getOpenAnswer(this.currentQuestion.ID)
      ) {
        return true;
      }
      if (
        ["single", "multiple", null].includes(this.currentQuestion.AnswerType)
      ) {
        const questionIndex =
          this.PollingResult.Answers.findIndex(
            (item) => item.QuestionId == this.currentQuestion.ID
          ) + 1;
        return !!questionIndex;
      }
      return false;
    },
  },
  created() {
    this.initPollingResult();
    this.setReadedFlag(this.PollingResult.PollId);
  },
  watch: {
    poll() {
      this.initPollingResult();
      this.selectedQuestionIndex = 0;
    },
  },
  methods: {
    ...mapActions({
      saveResult: "polls/saveResult",
      setReadedFlag: "polls/setReadedFlag",
    }),
    initPollingResult() {
      this.PollingResult = { ...this.initialPollingResult };
      this.PollingResult.PollId = this.poll.ID;
      this.poll.Questions.forEach((item) => {
        if (["single", "multiple", null].includes(item.AnswerType)) {
          let answer = item.Answers.filter((answer) => {
            return answer.IsUserAnswer;
          }).map((answer) => {
            return {
              AnswerId: answer.ID,
              QuestionId: item.ID,
              CustomAnswer: "",
            };
          });
          this.PollingResult.Answers = [
            ...this.PollingResult.Answers,
            ...answer,
          ];
        } else if (item.AnswerType === "open" && item.Answers.length) {
          let answer = [
            {
              AnswerId: null,
              QuestionId: item.ID,
              CustomAnswer: item.Answers[0].Text,
            },
          ];
          this.PollingResult.Answers = [
            ...this.PollingResult.Answers,
            ...answer,
          ];
        }
      });
    },
    savePoll() {
      this.saveResult(this.PollingResult)
        .then(() => {
          this.$emit("pass");
        })
        .catch(() => {
          this.$notify.error({
            title: "Ошибка",
            message: "Не удалось завершить опрос. Попробуйте позже",
            duration: 6000,
            offset: 100,
          });
        });
    },
    setSingleAnswer($event, answer) {
      let questionIndex = this.PollingResult.Answers.findIndex(
        (item) => item.QuestionId == answer.QuestionId
      );
      if (questionIndex > -1) {
        this.PollingResult.Answers.splice(questionIndex, 1);
      }
      if ($event.target.checked) {
        this.PollingResult.Answers.push(answer);
      }
      console.log(this.PollingResult);
    },
    setMultipleAnswer($event, answer) {
      let questionIndex = this.PollingResult.Answers.findIndex(
        (item) =>
          item.QuestionId == answer.QuestionId &&
          item.AnswerId == answer.AnswerId
      );

      if (questionIndex > -1) {
        this.PollingResult.Answers.splice(questionIndex, 1);
      }
      if ($event.target.checked) {
        this.PollingResult.Answers.push(answer);
      }
      console.log(this.PollingResult);
    },
    setOpenAnswer(answer) {
      let questionIndex = this.PollingResult.Answers.findIndex(
        (item) => item.QuestionId == answer.QuestionId
      );
      if (questionIndex > -1) {
        this.PollingResult.Answers.splice(questionIndex, 1);
      }

      this.PollingResult.Answers.push(answer);
    },
    getOpenAnswer(questionId) {
      let questionIndex = this.PollingResult.Answers.findIndex(
        (item) => item.QuestionId == questionId
      );
      if (questionIndex > -1) {
        return this.PollingResult.Answers[questionIndex].CustomAnswer;
      }
      return "";
    },
    isChecked(answer) {
      let questionIndex =
        this.PollingResult.Answers.findIndex(
          (item) =>
            item.QuestionId == answer.QuestionId &&
            item.AnswerId == answer.AnswerId
        ) + 1;
      return !!questionIndex;
    },
  },
};
</script>

<style>
.poll {
  position: relative;
  padding: 20px;
  background-color: white;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0 8px 16.7px 1.3px rgba(0, 0, 0, 0.13);
  box-shadow: 0 8px 16.7px 1.3px rgba(0, 0, 0, 0.13);
}
.poll__name {
  padding-right: 30px;
  font-size: 1.3em;
}
.poll__question {
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flex !important;
  display: -o-flex !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  background-color: white;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  padding: 1.3em;
  font-weight: 300;
}
.theme-light .poll__question::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin-top: 0px;
  border-radius: 50px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: -2px;
  margin-left: -2px;
  background: -webkit-linear-gradient(
    bottom,
    rgba(var(--base-color-rgb), 0.5) 0%,
    rgba(var(--base-color-rgb), 0.1) 25%,
    rgba(var(--base-color-rgb), 0.1) 75%,
    rgba(var(--base-color-rgb), 0.5) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(var(--base-color-rgb), 0.5) 0%,
    rgba(var(--base-color-rgb), 0.1) 25%,
    rgba(var(--base-color-rgb), 0.1) 75%,
    rgba(var(--base-color-rgb), 0.5) 100%
  );
}
.theme-light .poll__question {
  border: 1px solid transparent;
  margin-top: 1px;
  height: calc(100% - 2px);
  position: relative;
}
.poll__question-text {
  font-size: 1.3rem;
}
.poll__answers {
  display: flex;
  flex-direction: column;
}
.poll__anchor {
  text-decoration: underline;
}
.poll__open-answer {
  height: 100px;
  padding: 0.7em;
  border: 1px solid var(--base-color);
  -webkit-border-radius: 15px;
  border-radius: 15px;
  outline: none;
  resize: none;
  width: 100%;
}
.poll__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
}
.poll__button {
  display: flex !important;
  min-width: auto !important;
  align-items: center;
  justify-content: center;
}
.poll__back-ico {
  position: relative;
  transform: rotate(225deg);
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.poll__next-ico {
  position: relative;
  transform: rotate(45deg);
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
</style>
