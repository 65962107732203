<template>
  <div class="log-register">
    <div class="log-register__header">
      <h1 class="log-register__title">{{ content.title }}</h1>
      <div class="log-register__steps">
        <div 
          v-for="s in [1,2,3]"
          :key="s"
          :class="[
            'step',
            { 'step--prev': s < step },
            { 'step--current': s === step },
            { 'step--next': s === step + 1 }
          ]"
        >
          <!-- Есть недостатки в такой навигации -->
          <!-- @click="(step > s) ? step = s : false" -->
          <span class="step__number">{{ s }}</span>
          Шаг {{ s }}
        </div>
      </div>
    </div>

    <div class="log-register__form">
      <div class="form-box">

        <!--Шаг 1-->
        <form 
          v-if="step === 1"
          @submit.prevent 
          class="form-box__form" 
          :key="`step1-${step}`"
        >
          <form-box-input 
            v-mask="`+7 (###) ### ## ##`"
            v-model="form.phone"
            refName="phoneControl"
            label="Телефон"
            type="tel"
            placeholder="+7 (999) 999 99 99"
            :message="errors.phone"
            :focused="phoneIsFocus"
            @input="delete errors.phone"
          />

          <form-box-input 
            refName="fioControl"
            label="ФИО"
            type="text"
            :message="errors.fio"
            :focused="fioIsFocus"
            v-model="form.fio"
            @input="delete errors.fio"
          />

          <form-box-input 
            v-if="config.requireBirthDate"
            refName="birthdayControl"
            label="Дата рождения"
            type="text"
            placeholder="дд.мм.гггг"
            :message="errors.birthday"
            v-mask="`##.##.####`"
            v-model="form.birthday"
            @input="delete errors.birthday"
          />

          <form-box-checkbox
            v-model="consent"
            inputId="consent"
            refName="consentControl"
            :hasError="(errors.consent)"
            :message="(errors.consent) ? errors.consent : ''"
          >
            Согласие на 
            <a
              tabindex="0"
              class="form-box__policy-privacy"
              @click.prevent="openPersonalDataPolitics"
            >
              обработку персональных данных
            </a>
          </form-box-checkbox>

          <base-button 
            tabindex="0"
            class="form-box__button" 
            :disabled="disabledNextStep"
            @click="nextSmsCode"
          >
            Далее
          </base-button>
        </form>

        <!--Шаг 2-->
        <form 
          v-if="step === 2"
          @submit.prevent  
          class="form-box__form"
          :key="`step2-${step}`"
        >
          <!-- Код доступа - form.code -->
          <form-box-input 
            v-mask="`####`"
            refName="codeControl"
            label="Код доступа"
            type="text"
            placeholder="0000"
            :message="errors.code"
            :showPopover="showCodePopover"
            :hasError="errors.code"
            v-model="form.code"
            @input="delete errors.code"
          >
            <template #popover>
              <popover
                :title="content.codePopover.title"
                :bodyText="content.codePopover.text"
                @close="showCodePopover = false"
              >
                <template #footer>
                  <base-button 
                    style-class="secondary"
                    class="form-box__support-button"
                    @click="[openSupportModalChatWithousAuth(), showCodePopover = false]"
                  >
                    {{ content.codePopover.button }}
                  </base-button>
                </template>
              </popover>
            </template>
          </form-box-input>

          <p 
            v-show="!(!isDisabledSendButton || (isDisabledSendButton && !disabledNextStep))"
            class="form-box__note"
          >
            Повторить запрос кода через:
            <span>{{ codeResendTimer }}</span>
            <br>
          </p>
          <p 
            v-if="showFlashCodeForm"
            class="form-box__note"
          >
            Введите последние 4 цифры номера, 
            с которого поступил звонок на номер 
            <strong>{{ form.phone }}</strong>.
          </p>
          <p 
            v-else
            v-show="disabledNextStep"
            class="form-box__note"
          >
            Чтобы получить код доступа нажмите «Звонок с кодом». 
            Вам позвонит робот на номер {{ form.phone }} и сообщит код, 
            либо нажмите «Код в SMS» для получения кода сообщением по SMS.
          </p>
          
          <p class="form-box__note">
            Неверный номер телефона? 
            Вернитесь на начальный экран заполнения.
          </p>
          <a 
            class="form-box__link"
            @click.stop="step = 1"
          >
            Вернуться в начало
          </a>

          <template v-if="!showFlashCodeForm && form.code.length < 4">
            <form-box-radioset
              v-model="confirmType"
              :list="[
                { title: 'Звонок с кодом', value: 'call' },
                { title: 'Код в SMS', value: 'sms' },
              ]"
              :classModificators="['register']"
            />

            <base-button 
              tabindex="0"
              class="form-box__button" 
              :disabled="isDisabledSendButton"
              @click="requestConfirmCode"
            >
              Запросить код
            </base-button>
          </template>

          <base-button 
            v-show="!disabledNextStep || (disabledNextStep && showFlashCodeForm)"
            tabindex="0"
            class="form-box__button" 
            :disabled="disabledNextStep"
            @click="checkSmsCode"
          >
            Далее
          </base-button>
        </form>

        <!--Шаг 3-->
        <form 
          v-if="step === 3"
          @submit.prevent 
          class="form-box__form" 
          :key="`step3-${step}`"
        >
          <form-box-input 
            refName="passwordControl"
            label="Пароль"
            type="password"
            :message="errors.password"
            v-model="form.password"
            @input="delete errors.password"
          />

          <form-box-input 
            refName="repeatPasswordControl"
            label="Подтвердите пароль"
            type="password"
            :message="errors.repeatPassword"
            v-model="form.repeatPassword"
            @input="delete errors.repeatPassword"
          />
          
          <base-button 
            :disabled="!isValidateStep3"
            class="form-box__button" 
            @click="register"
          >
            Зарегистрироваться
          </base-button>
        </form>

        <base-button
          tabindex="0"
          style-class="text"
          class="form-box__login-link" 
          @click="$router.push({ name: 'Login' })"
        >
          Войти
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  yearFromDate,
  monthFromDate,
  dayFromDate,
  daysInMonth,
} from "@/services/common";
import FormBoxInput from '@/components/form/FormBoxInput';
import FormBoxCheckbox from '@/components/form/FormBoxCheckbox';
import FormBoxRadioset from '@/components/form/FormBoxRadioset';
import Popover from "@/components/ui/Popover";
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: "Register",

  components: { 
    FormBoxInput,
    FormBoxCheckbox,
    FormBoxRadioset,
    Popover,
    BaseButton,
  },

  data() {
    return {
      form: {
        phone: "",
        fio: "",
        password: "",
        repeatPassword: "",
        code: "",
        birthday: "",
      },
      phoneIsFocus: false,
      fioIsFocus: false,
      consentIsFocus: false,
      confirmTypeCallIsFocus: false,
      confirmTypeSmsIsFocus: false,
      errors: {},
      showCodePopover: false,
      codeResendTimer: 60,
      isDisabledSendButton: false,
      consent: false,
      step: 1,
      error: "",
      confirmType: 'call',
      showFlashCodeForm: true,
    };
  },

  computed: {
    content() {
      return {
        title: 'Регистрация',
        codePopover: {
          title: 'Не получили код?',
          text: 'Напишите в тех.поодержку, поможем зарегистрироваться.',
          button: 'Написать в тех.поддержку'
        },
      }
    },

    step1ValidationError() {
      if (
        !this.form.fio ||
        !this.form.phone ||
        (this.config.requireBirthDate && !this.form.birthday)
      ) {
        return "Для продолжения необходимо заполнить все поля";
      }

      if (this.config.requireBirthDate) {
        if (this.form.birthday.length !== 10) {
          return "Указана неверная дата рождения";
        }

        // yearFromDate(this.form.birthday) > 1900 && yearFromDate(this.form.birthday) < new Date().getFullYear()
        var year = yearFromDate(this.form.birthday);
        if (year < 1900) {
          return "Год рождения не может быть меньше 1900";
        }
        if (year > new Date().getFullYear()) {
          return "Год рождения не может быть больше текущего";
        }

        var month = monthFromDate(this.form.birthday);
        if (month < 1) {
          return "Месяц рождения не может быть меньше 1";
        }
        if (month > 12) {
          return "Месяц рождения не может быть больше 12";
        }

        var day = dayFromDate(this.form.birthday);
        if (day < 1) {
          return "День даты рождения не может быть меньше 1";
        }
        var monthDays = daysInMonth(month, year);
        if (day > monthDays) {
          return (
            "День даты рождения в указанном месяце не может быть больше " +
            monthDays
          );
        }
      }

      if (!this.consent) {
        return "Для продолжения необходимо дать согласие на обработку персональных данных";
      }
      
      return "";
    },

    isValidateStep3() {
      return (
        (this.form.password.length > 0 && this.form.repeatPassword.length > 0) &&
        (this.form.password === this.form.repeatPassword)
      );
    },

    disabledNextStep() {
      if (this.step === 1 && (
          !this.form.fio ||
          this.form.phone.length <= 17 ||
          (this.config.requireBirthDate && this.form.birthday.length <= 10) ||
          !this.consent ||
          this.errors.fio || 
          this.errors.phone
        )) {
        return true
      } 
      
      if (this.step === 2 && 
          this.form.code.length < 4 ||
          this.errors.code
        ) {
        return true
      }

      if (this.step === 3 && (
          !this.form.password || 
          !this.form.repeatPassword
      )) {
        return true
      }

      return false
    },
  },

  mounted() {
    if (this.$route.query.phone && this.$route.query.phone.length === 10) {
      this.form.phone = this.$route.query.phone;
      this.fioIsFocus = true;
    } else {
      this.phoneIsFocus = true;
    }

    this.$nextTick(() => {
      window.addEventListener('keyup', (e) => {
        if (e.code === 'Space') {
          if (this.confirmTypeCallIsFocus) {
            this.confirmType = 'call';
          }

          if (this.confirmTypeSmsIsFocus) {
            this.confirmType = 'sms';
          }
        }
      })
    })
  },

  watch: {
  //   isDisabledSendButton(val) {
  //     if (!val) {
  //       setTimeout(() => {
  //         this.showCodePopover = true;
  //       }, 1000);
  //     }
  //   }
    // step(val) {
    //   if (val === 2) {
    //     this.showFlashCodeForm = true;
    //   }
    // }
  },

  methods: {
    ...mapActions({
      registerByPhone: 'account/registerByPhone',
      loginAction: 'account/login',
      getRequestAccessCodeAction: 'account/getRequestAccessCode',
      sendCheckCodeSMS: 'account/sendCheckCodeSMS',
      checkAccessCode: 'account/checkAccessCode',
      sendCheckCodeFlashCall: 'account/sendCheckCodeFlashCall',
    }),

    nextSmsCode() {
      var error = this.step1ValidationError;
      if (!error) {
        ++this.step;
        this.getFlashCallCode();
      } else {
        this.$notify.error({
          position: "top-left",
          title: "Ошибка",
          message: error,
        });
      }
    },

    setCodeResendTimer() {
      this.isDisabledSendButton = true;
      let timeout = 60000;
      let interval = setInterval(() => {
        this.codeResendTimer -= 1;
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
        this.isDisabledSendButton = false;
        this.codeResendTimer = 60;
        this.showFlashCodeForm = false;
      }, timeout);
    },

    getRequestAccessCode() {
      this.getRequestAccessCodeAction({ phone: this.form.phone })
        .then(() => {
          this.setCodeResendTimer();
          this.$notify({
            position: "top-left",
            title: "Код регистрации",
            message: "Ожидайте звонка, робот сообщит Вам код регистрации",
          });
        });
    },

    getSMSCode() {
      this.sendCheckCodeSMS({ phone: this.form.phone })
        .then(() => {
          this.setCodeResendTimer();
          this.$notify({
            position: "top-left",
            title: "Код регистрации",
            message: "Проверочный код отправлен сообщением в SMS",
          });
        });
    },

    getFlashCallCode() {
      this.sendCheckCodeFlashCall({ 
          phone: this.form.phone,
          dontCheck: true,
        })
        .then(() => {
          this.setCodeResendTimer();
          this.showFlashCodeForm = true;
        });
    },

    checkSmsCode() {
      // console.log('--- checkSmsCode method ---');
      this.checkAccessCode({
          phone: this.form.phone.match(/(\d+)/g).join(""),
          code: this.form.code,
        })
        .then((result) => {
          if (result.IsCorrect) {
            ++this.step;
          } else {
            this.errors['code'] = 'Код введён не корректно';
            this.showCodePopover = true;
          }
        });
    },

    register() {
      // console.log('--- register method ---')
      if (this.isValidateStep3) {
        this.registerByPhone({
            ...this.form,
            phone: this.form.phone.match(/(\d+)/g).join(""),
          })
          .then((result) => {
            if (result.isSucceed) {
              this.login();
            }
          })
          .catch((error) => {
            this.$notify.error(error);
          });
      }
    },

    login() {
      // console.log('--- login method ---')
      this.loginAction({
          phone: this.form.phone.match(/(\d+)/g).join(""),
          password: this.form.password,
        })
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch(() => {
          this.$router.push({ name: "Login" });
        });
    },

    requestConfirmCode() {
      if (this.confirmType === 'call') {
        this.getRequestAccessCode()
      }

      if (this.confirmType === 'sms') {
        this.getSMSCode()
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"
@import "../sass/pages/login-register"
@import "../sass/components/form/form"

.form-box__button
  &:not(:last-child)
    margin-bottom: 24px

.form-box__login-link
  width: 100%

.form-box__link
  cursor: pointer

.form-box__support-button
  width: 100%
  margin-top: 24px

.form-box__policy-privacy
  +TextMain()
  +Transition((color))

  font-weight: 600

  &:hover, &:focus
    color: $cAccent !important
    text-decoration: none
</style>
