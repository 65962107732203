<template>
  <section 
    v-loading="!noLogin"
    class="auth-page" 
  >
    <span class="auth-page__code">401</span>

    <div class="auth-page__content">
      <h1 class="auth-page__title">
        Токен либо не найден, либо его срок действия истек.
      </h1>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
// import * as authService from '@/services/auth.service.js';

export default {
  name: 'Auth',

  data() {
    return {
      noLogin: false
    }
  },

  created() {
    console.log('--- Auth page ---');
  },

  mounted() {
    this.noLogin = false;
    
    this.auth({ token: this.tokenFromURL })
      .then((result) => {
        if (result) {
          this.$router.push({ name: 'Requests' });
        }

        this.noLogin = true;
      });
  },

  methods: {
    ...mapActions({
      auth: 'account/authByToken'
    }),
  }
}
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.auth-page
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  height: 100%

.auth-page__code
  +Typography(120px, 700, 200px, $cError)

  @media screen and (max-width: 767px)
    font-size: 72px
    line-hegiht: 120px

.auth-page__title
  +TextHeader(0)
  text-align: center
  margin-bottom: 24px

  @media screen and (min-width: 768px)
    margin-bottom: 72px

.auth-page__link
  +Button('text')
</style>