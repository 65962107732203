<template>
  <modal
    title="Добавить лицевой счёт"
    :setBaseStyle="true"
    class="modal--ident"
    @close="$emit('close')"
  >
    <div class="form-box">
      <form
        v-loading="isSending"
        class="form-box__form"
        @submit.prevent
      >
        <template v-if="stage === 1">
          <form-box-select
            v-if="isRostovZKH"
            v-model="form.clientBaseID"
            label="Город"
            :list="clientBases"
            valuePropName="ID"
            keyPropName="Name"
            :maxShowOptions="6"
          />

          <form-box-input 
            v-model="form.ident"
            refName="identControl"
            label="Номер лицевого счёта"
            placeholder="1234567890"
            :focused="(!isRostovZKH)"
          />

          <form-box-select
            v-if="selectPrefix"
            v-model="selectedPrefix"
            label="Управляющая организация"
            :list="prefixes"
            valuePropName="Prefix"
            keyPropName="Name"
          />
        </template>
        <template v-else>
          <form-box-input 
            v-model="form.pinCode"
            refName="pinControl"
            label="Пин-код с последней квитанции"
            placeholder="0000"
            message="Введите пин-код"
            :minlength="1"
            :showPopover="showPinPopover"
          />
        </template>
        <base-button
          type="submit"
          :disabled="isDisabled"
          @click="send"
        >
          Подключить
        </base-button>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from "@/components/ui/Modal";
import BaseButton from "@/components/ui/BaseButton";
import FormBoxInput from '@/components/form/FormBoxInput'
import FormBoxSelect from '@/components/form/FormBoxSelect'

export default {
  name: "addAccountIdentModal",

  components: { 
    Modal,
    BaseButton,
    FormBoxInput,
    FormBoxSelect,
  },

  data() {
    return {
      form: {
        ident: "",
        pinCode: "",
        clientBaseID: null
      },
      stage: 1,
      isSending: false,
      selectedPrefix: "",
      showPinPopover: true,
    };
  },

  computed: {
    ...mapGetters({
      prefixes: 'account/accountPrefixes',
      clientBases: 'account/getClientBases',
    }),

    selectPrefix() {
      return this.prefixes.length;
    },

    isDisabled() {
      return (this.stage === 1 && this.form.ident.length === 0) ||
              (this.stage === 1 && this.selectPrefix &&
                (!this.selectedPrefix || !this.selectedPrefix.length)) ||
              (this.stage === 2 && !this.form.pinCode.length)
    },

    isRostovZKH() {
      return window.appSettings.apiUrl.split("/").includes("rostov-zkh");
    }
  },

  created() {
    this.getPrefixes();
  },

  mounted() {
    this.getClientBases();
  },

  methods: {
    ...mapActions({
      getPrefixes: 'account/getAccountPrefixes',
      addAccountByIdent: 'account/addAccountByIdent',
      getClientBases: 'account/getClientBases'
    }),

    send() {
      if (this.stage === 1) {
        if (this.config.useAccountPinCode) {
          this.stage = 2;
          return;
        }
      }

      if (
        this.form.ident.length > 0 &&
        (!this.selectPrefix ||
          (this.selectedPrefix && this.selectedPrefix.length))
      ) {
        this.isSending = true;
        var data = this.form;
        if (this.selectPrefix) {
          data = {
            ident: this.selectedPrefix + data.ident,
            pinCode: data.pinCode,
          };
        }
        
        this.addAccount(data);
      }
    },

    addAccount(data) {
      this.addAccountByIdent(data)
        .then((result) => {
          if (result.accounts && result.accounts.length) {
            this.$emit("close");
            this.$root.$emit("updated-account-info");
            this.$notify.success(`Лицевой счет №${this.form.ident} подключен!`);

            this.form.ident = "";
          }
        })
        .then(() => {
          this.stage = 1;
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  },
};
</script>

<style lang="sass">
@import "../../sass/variables"
@import "../../sass/mixins"
@import "../../sass/components/form/form"
</style>
