import * as additionalServiceApi from "@/api/additionalService";

const state = {
  services: {},
  servicesByGroups: null,
  goods: [],
};

const actions = {
  async getLogo(context, id) {
    let response = await additionalServiceApi.getLogo(id);

    if (response.status === 200) {
      let blob = new Blob([response.data], { type: "image/png" });
      return window.URL.createObjectURL(blob);
    }
  },

  async getGoods({ commit }, id) {
    return additionalServiceApi
      .getGoods(id)
      .then((response) => {
        commit("setGoods", response.data.Data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  async getLocalStorageCart({ commit }) {
    return new Promise(
      (resolve)=>{
        let savedCart = JSON.parse(localStorage.getItem("userCart"));
        commit("setGoods", savedCart);
        resolve();
      }
    );
  },
  toggleService({ commit }, index) {
    commit('toggleService', index);
  },

  getDescriptionImage(_, id) {
    return additionalServiceApi
      .getDescriptionImage(id)
      .then((response) => {
        const URLApi = window.URL || window.webkitURL;
        const source = URLApi.createObjectURL(response.data);

        return source;
      })
      .catch((error) => {
        throw error;
      });
  }
};

const getters = {
  getServiceById: (state) => (id) => state.services[id] || {},
  getServices: (state) => (list) => list.map((id) => state.services[id] || {}),
  getServicesByGroups: (state) => state.servicesByGroups,
  getGoodsList: (state) => (category) => {
    return state.goods.filter((item) => {
      if (category) {
        return item.Categories.includes(category);
      }
      return true;
    });
  },

  getCategories: (state) => {
    let categories = [];
    if (state.goods.length > 0) {
      categories = [].concat
        .apply(
          [],
          state.goods.map((good) => {
            return good.Categories;
          })
        )
        .filter((value, index, self) => self.indexOf(value) === index);
    }
    return categories;
  },
  
  getServicesList: (state) => state.services || {},
};

const mutations = {
  setServices(state, data) {
    // console.log('--- setServices mutation ---')
    state.services = {
      ...state.services,
      ...data,
    };
  },
  setServicesByGroups(state, data) {
    state.servicesByGroups = Object.entries(data).map((service, index) => {
      return {
        name: service[0],
        services: service[1],
        isShow: index == 0,
      };
    });
    /*state.servicesByGroups = {
      ...state.servicesByGroups,
      ...data,
    };*/
  },
  setGoods(state, data) {
    let savedCart = JSON.parse(localStorage.getItem("userCart"));
    state.goods = [
      ...data.map((item) => {
        if (savedCart != null) {
          let savedItem = savedCart.find((element) => {
            return element.ID == item.ID;
          });
          if (typeof savedItem !== "undefined") {
            return { ...savedItem };
          }
        }
        return { ...item, Quantity: 0 };
      }),
    ];
  },
  changeGoodCount(state, { ID, count }) {
    let good = state.goods.find((item) => {
      return item.ID == ID;
    });
    if (typeof good !== "undefined") {
      good.Quantity = count;
    }
    localStorage.setItem(
      "userCart",
      JSON.stringify(state.goods.filter((item) => item.Quantity > 0))
    );
  },
  toggleService(state, index) {
    state.servicesByGroups[index].isShow = !state.servicesByGroups[index]
      .isShow;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
