import Vue from "vue";
import VueRouter from "vue-router";
import {
  // redirectToHomeMiddleware,
  checkAccessMiddleware,
  setPageTitleMiddleware,
  checkMobileSettingsMiddleware,
  preloaderStart,
  preloaderStop,
} from "./middlewares";

//pages
import LoginPage from "../pages/Login";
import RegisterPage from "../pages/Register";
import AuthPage from "../pages/Auth";
import HomePage from "../pages/Home";
import NewsPage from "../pages/News";
import PollsPage from "../pages/Polls";
import ServicesPage from "../pages/Services";
import AdditionalServicePage from "../pages/AdditionalServicePage";
import ShopPage from "../pages/Shop";
// import ShopMobilePage from "../pages/ShopMobile";
import UserCartPage from "../pages/UserCart";
// import UserCartMobilePage from "../pages/UserCartMobile";
import AnnouncementPage from "../pages/Announcement";
import ProfilePage from "../pages/Profile";
import PaymentPage from "../pages/Payment";
import RequestsPage from "../pages/Requests";
import NewRequestPage from "../pages/NewRequest";
import RequestConfirmationPage from "../pages/RequestConfirmation";
import MetersPage from "../pages/Meters";
import OssInfoPage from "../pages/OssInfo";
import OssPage from "../pages/Oss";
import OssVotePage from "../pages/OssVote";
import OssResultPage from "../pages/OssResult";
//import MeetingBegan from '../pages/MeetingBegan'
import NotFound from '../pages/NotFound';
// import additionalService from "../store/modules/additionalService"; // ?

// import detectScreenMixin from "@/mixins/detectScreen";

Vue.use(VueRouter);

// let isMobile = detectScreenMixin.methods.isMobile();

const routes = [
  {
    path: "/",
    name: "Home",
    // name: "IndexRouterPage",
    // redirect: 'home',
    // meta: { accessToHeadless: true }
    component: HomePage,
    meta: { title: "Личный кабинет", isAuth: true },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterPage,
    meta: { title: "Регистрация", layout: "auth-layout" },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { title: "Авторизация", layout: "auth-layout" },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage,
    meta: { title: 'Авторизация', layout: 'empty-layout', accessToHeadless: true }
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   component: HomePage,
  //   meta: { title: "Личный кабинет", isAuth: true },
  // },
  {
    path: "/services",
    name: "Services",
    component: ServicesPage,
    props: true,
    meta: { title: "Специальные предложения", isAuth: true },
  },
  {
    path: '/additional_service/:id',
    name: 'AdditionalService',
    component: AdditionalServicePage,
    props: true,
    meta: { title: 'Дополнительная услуга', isAuth: true }
  },
  {
    path: "/services/:id/:type/:id_RequestType?/:AdditionalServiceId",
    name: "Shop",
    // component: isMobile ? ShopMobilePage : ShopPage,
    component: ShopPage,
    props: true,
    meta: { title: "Магазин", isAuth: true, layout: 'services-layout' },
  },
  {
    path: "/usercart/:id/:type/:id_RequestType?/:AdditionalServiceId",
    name: "UserCart",
    // component: isMobile ? UserCartMobilePage : UserCartPage,
    component: UserCartPage,
    props: true,
    meta: { title: "Корзина", isAuth: true, layout: 'services-layout' },
  },
  {
    path: "/requestconfirmation/",
    name: "RequestConfirmation",
    component: RequestConfirmationPage,
    meta: { title: "Подтверждение заказа", isAuth: true },
  },
  {
    path: "/news/:id",
    name: "News",
    component: NewsPage,
    meta: { title: "Новость", isAuth: true },
  },
  {
    path: "/polls/:id?",
    name: "Polls",
    component: PollsPage,
    meta: { title: "Опросы", isAuth: true },
  },
  {
    path: "/announcement/:id",
    name: "Announcement",
    component: AnnouncementPage,
    meta: { title: "Уведомление", isAuth: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfilePage,
    meta: { title: "Профиль", isAuth: true },
  },
  {
    path: "/payment",
    name: "Payment",
    component: PaymentPage,
    meta: { title: "Оплата", isAuth: true },
  },
  {
    path: "/requests/new",
    name: 'NewRequest',
    component: NewRequestPage,
    meta: { title: 'Новая заявка', isAuth: true },
  },
  {
    path: "/requests/:requestID?",
    name: "Requests",
    component: RequestsPage,
    props: true,
    meta: { title: "Заявки", isAuth: true, layout: 'requests-layout', accessToHeadless: true },
  },
  {
    path: "/meters",
    name: "Meters",
    component: MetersPage,
    meta: { title: "Показания", isAuth: true },
  },
  {
    path: "/oss",
    name: "Oss",
    component: OssPage,
    meta: { title: "Собрания собственников", isAuth: true },
  },
  {
    path: "/oss_info/:id",
    name: "OssInfo",
    component: OssInfoPage,
    meta: { title: "Собрание собственников", isAuth: true },
  },
  {
    path: "/oss_vote/:id",
    name: "OssVote",
    component: OssVotePage,
    meta: { title: "Собрание собственников. Голосование", isAuth: true },
  },
  {
    path: "/oss_result/:id",
    name: "OssResult",
    component: OssResultPage,
    meta: { title: "Собрание собственников. Результат", isAuth: true },
  },

  /*{
    path: '/oss',
    name: 'MeetingBegan',
    component: MeetingBegan,
    meta: { title: 'Собрание собственников' }
  },*/
  
  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: { title: 'Ошибка', layout: 'empty-layout', accessToHeadless: true }
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(checkAccessMiddleware);
// router.beforeEach(redirectToHomeMiddleware);
router.beforeEach(checkMobileSettingsMiddleware);
router.beforeEach(setPageTitleMiddleware);
router.beforeEach(preloaderStart);
router.afterEach(preloaderStop);

export default router;
