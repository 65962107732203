<template>
  <modal
    :title="`Переоткрытие заявки №${requestNumber}`" 
    class="modal--request reopen-modal"
    :closeButton="false"
  >
    <form-box-textarea
      v-model="form.message"
      label="Причина переоткрытия"
      message="Введите причину"
      :hasError="hasError"
      height="102px"
      @input="hasError = false"
    />
    <div class="modal__buttons">
      <base-button
        style-class="additional"
        class="modal__button"
        @click="$emit('close')"
      >
        Отмена
      </base-button>
      <base-button
        class="modal__button"
        @click="reopenRequest"
      >
        Подтвердить
      </base-button>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '@/components/ui/Modal';
import FormBoxTextarea from '@/components/form/FormBoxTextarea';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'ReopenRequestModal',

  components: {
    Modal,
    FormBoxTextarea,
    BaseButton
  },

  props: {
    requestId: {
      type: Number,
      required: true,
    },
    requestNumber: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      form: {
        message: ''
      },
      hasError: false
    }
  },

  methods: {
    ...mapActions({
      sendMessage: "request/addMessage",
    }),

    reopenRequest() {
      if (this.form.message.length) {
        this.sendMessage({
          RequestId: this.requestId,
          Text: this.form.message,
        })
        .then(() => {
          this.$emit('close');
          this.$root.$emit('requests:sent-message');
        })
        .catch((error) => {
          console.error(error);
        });
      } else {
        this.hasError = true;
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'  
@import '../../sass/mixins'

.modal__buttons
  display: flex

.modal__button
  width: 100%
  margin: 0 calc(16px / 2)

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0
</style>