<template>
  <article
    v-if="data" 
    class="service-card"
  >
    <router-link 
      :to="(data.CanBeOrdered) ? {
        name: 'Shop',
        params: {
          id: data.ShopID,
          AdditionalServiceId: data.ID,
          type: data.ShopType,
          id_RequestType: data.id_RequestType,
        },
      } : {
        name: 'AdditionalService',
        params: {
          id: data.ID
        }
      }"
      class="service-card__detail-link"
    >
      <h3 class="service-card__title">
        {{ data.Name }}
      </h3>

      <figure 
        v-if="data.HasLogo"
        class="service-card__image"
      >
        <img 
          :src="data.LogoLink"
          :alt="data.Name"
        />
      </figure>
    </router-link>
  </article>
</template>

<script>
export default {
  name: 'ServiceCard',

  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables'
@import '../../../sass/mixins'

.service-card
  +Size(100%, auto)
  display: flex
  flex-direction: column
  justify-content: space-between
  background: $cBgMain
  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
  border-radius: 16px
  padding: 16px

  @media screen and (min-width: 768px)
    padding: 24px

.service-card__detail-link
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between

.service-card__title
  +TextMain($cTextMain)

.service-card__image
  +ResponsiveImg(100%, 154px)

  overflow: hidden
  margin-top: 16px
  border:
    width: 1px
    style: solid
    color: $cBorder
    radius: 16px

  @media screen and (min-width: 768px)
    margin-top: 24px

  img
    object-fit: cover
</style>