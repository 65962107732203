<template>
  <section class="news">
    <section-header
      title="Новости"
    >
      <template v-slot:icon>
        <information-circle-icon class="icon" />
      </template>
    </section-header>

    <div
      v-if="items.length" 
      class="news__list"
    >
      <div
        v-for="(item, i) in items" 
        :key="`news-${i}`"
        class="news__item"
      >
        <card
          :title="item.Header"
          :subtitle="item.Created"
          :text="item.ShortContent"
          :detail-link="{ name: 'News', params: { id: item.ID } }"
          class="news__card"
        />
      </div>
    </div>

    <alert 
      v-else
      type="warning"
    >
      Новостей нет
    </alert>
  </section>
</template>

<script>
import { InformationCircleIcon } from '@vue-hero-icons/outline';
import SectionHeader from '@/components/pages/common/SectionHeader';
import Card from '@/components/ui/Card';
import Alert from '@/components/ui/Alert';

export default {
  name: 'NewsSection',

  components: {
    SectionHeader,
    InformationCircleIcon,
    Card,
    Alert,
  },

  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
  }
}
</script>

<style lang="sass" scoped>
.news__list
  display: flex
  flex-wrap: wrap

.news__item
  flex-grow: 1
  flex-shrink: 0
  flex-basis: 100%
  max-width: 100%
  padding-left: calc(16px / 2)
  padding-right: calc(16px / 2)
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    flex-basis: calc(100% / 2)
    max-width: calc(100% / 2)
    margin-bottom: 20px

    &:nth-child(1), &:nth-child(2n) + .news__item
      padding-left: 0

    &:nth-child(2n)
      padding-right: 0

  @media screen and (min-width: 992px)
    padding-left: calc(20px / 2)
    padding-right: calc(20px / 2)

  @media screen and (min-width: 1200px)
    flex-basis: calc(100% / 4)
    max-width: calc(100% / 4)
    padding-left: calc(16px / 2)
    padding-right: calc(16px / 2)

    &:nth-child(2n) + .news__item
      padding-left: calc(16px / 2)

    &:nth-child(2n)
      padding-right: calc(16px / 2)

    &:nth-child(1), &:nth-child(4n) + .news__item
      padding-left: 0

    &:nth-child(4n)
      padding-right: 0

  @media screen and (min-width: 1400px)
    padding-left: calc(20px / 2)
    padding-right: calc(20px / 2)

    &:nth-child(2n) + .news__item
      padding-left: calc(20px / 2)

    &:nth-child(2n)
      padding-right: calc(20px / 2)

.news__card
  height: 100%
</style>