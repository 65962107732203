<template>
  <div 
    :class="[
      'alert',
      `alert--${type}`
    ]"
  >
    <component 
      :is="icon" 
      class="alert__icon"
    />

    <p class="alert__caption">
      <slot>
        {{ caption }}
      </slot>
    </p>

    <x-icon
      v-if="closeButton"
      class="alert__close"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import { InformationCircleIcon, XIcon } from "@vue-hero-icons/outline";

export default {
  name: 'alert',

  components: {
    InformationCircleIcon,
    XIcon
  },

  props: {
    caption: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'neutral' // info, success, danger, warning, neutral
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    icon() {
      // TODO: Добавить условия для выбора иконок в соответсвии с типом
      return 'information-circle-icon'
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.alert
  display: flex
  align-items: center
  width: 100%
  padding: 12px 16px
  border-radius: 16px

  &--neutral
    background-color: $cElmLight

    .alert__icon
      color: $cLabel

    .alert__caption
      color: $cLabel

  &--info
    background-color: $cElmNote

    .alert__icon
      color: $cIconNeutral

    .alert__caption
      color: $cLabel

  &--warning
    background-color: $cBgWarning

    .alert__icon
      color: $cWarning

    .alert__caption
      color: $cWarning

  &--success
    background-color: $cBgSuccess

    .alert__icon
      color: $cSuccess

    .alert__caption
      color: $cSuccess

  &--danger
    background-color: $cBgError

    .alert__icon
      color: $cError

    .alert__caption
      color: $cError

.alert__icon
  margin-right: 12px
  flex-shrink: 0

.alert__caption
  +TextMini()
  width: 100%
  flex-shrink: 1
  margin-bottom: 0
  
.alert__close
  +ButtonIcon()
  +Size(16px)
  flex-shrink: 0
  margin-left: 12px
</style>