var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-box__field"},[_c('div',{class:[
      'field',
      'field--radio',
      { 'field--error': _vm.hasError },
      { 'field--selected': _vm.value === _vm.radioValue },
      { 'field--active': _vm.isActiveState },
      { 'field--focus': _vm.fieldIsFocus },
      { 'field--disabled': _vm.disabled } ].concat( _vm.modificators
    ),attrs:{"tabindex":"0"},on:{"focus":function($event){_vm.fieldIsFocus = true},"blur":function($event){_vm.fieldIsFocus = false}}},[_c('div',{staticClass:"field__group",on:{"mousedown":function($event){_vm.isActiveState = (!_vm.disabled) ? true : false},"mouseup":function($event){_vm.isActiveState = false}}},[_c('span',{staticClass:"field__radio",class:{'field__radio--selected': _vm.value === _vm.radioValue},on:{"click":function($event){[
          _vm.$emit('input', _vm.radioValue),
          _vm.fieldIsFocus = true,
          _vm.fieldIsFocus = false
        ]}}}),_c('label',{staticClass:"field__label",on:{"click":function($event){[
          _vm.$emit('input', _vm.radioValue),
          _vm.fieldIsFocus = true,
          _vm.fieldIsFocus = false
        ]}}},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.radioLable)+" ")]})],2)])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"field__message field__message--error"},[_vm._v(" "+_vm._s(_vm.message)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }