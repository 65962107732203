<template>
  <div 
    v-if="content.data"
    class="invoices"
  >

    <!-- Accounts Selector -->
    <form-box-select
      v-if="content.data.accounts"
      v-model="selectedIdent"
      label="Лицевой счёт"
      :list="content.data.accounts"
      valuePropName="Ident"
      keyPropName="Ident"
      class="ident-selector"
    >
      <template #selectedOption="{ item }">
        (Л/с: {{ item.Ident }}) {{ item.Address }}
      </template>
      <template #option="{ item }">
        {{ item.Ident }} ({{ item.AccountType }})
      </template>
      <template 
        v-if="content.data.accounts.length > 1"
        #customOption
      >
        <li
          class="ident-selector__select-all"
          @click="selectedIdent = null"
        >
          Выбрать все ({{ content.data.accounts.length }})
        </li>
      </template>
    </form-box-select>

    <!-- Table -->
    <div class="invoices__container">
      <p class="invoices__title">Квитанции</p>
      <base-table
        v-if="billsTable"
        :header="billsTable.header"
        :body="billsTable.body"
      />
    </div>
  </div>
</template>

<script>
import * as commonApi from "@/api/common";
import download from "downloadjs";
import BaseTable from '@/components/ui/BaseTable';
import FormBoxSelect from '@/components/form/FormBoxSelect';

export default {
  name: 'invoices',

  components: {
    BaseTable,
    FormBoxSelect,
  },

  props: {
    content: {
      type: [ Array, Object ],
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      selectedIdent: null,
      loadingBills: [],
    }
  },

  computed: {
    billsTable() {
      console.log('--- billsTable computed data ---')

      if (this.content.data.bills) {
        const bills = this.content.data.bills;

        return {
          header: [
            {
              title: 'Лицевой счет',
              align: 'center',
              width: `${100 / 3}%`,
              sorting: {
                key: 'account'
              }
            },
            {
              title: 'Дата',
              align: 'center',
              width: `${100 / 3}%`,
              sorting: {
                key: 'date'
              }
            },
            {
              title: 'К оплате',
              align: 'center',
              width: `${100 / 3}%`,
            },
            {
              title: 'Начислено',
              align: 'center',
              width: `${100 / 3}%`,
            },
            {
              title: 'Квитанция',
              align: 'center',
              width: `${100 / 3}%`,
            },
          ],
          body: (() => {
            let bills_ = [];

            if (this.selectedIdent) {
              bills_ = bills.filter((row) => row.Ident === this.selectedIdent);
            } else {
              bills_ = [...bills];
            }

            return bills_.map((row) => {
              return [
                {
                  value: row.Ident,
                  align: 'center',
                  key: 'account'
                },
                {
                  value: row.Period,
                  align: 'center',
                  key: 'date',
                  sortableValue: row.SortableDate
                },
                {
                  value: `${row.Total} ₽`,
                  align: 'center'
                },
                {
                  value: `${row.Accounting} ₽`,
                  align: 'center'
                },
                (row.HasFile ? 
                  {
                    value: '',
                    align: 'center',
                    action: {
                      type: 'download',
                      events: { click: () => this.getBillFile(row.ID) }
                    }
                  } : 
                  { value: '' }
                )
              ];
            });
          })()
        }
      }

      return null;
    }
  },

  methods: {
    /**
     * getBillFile - Получение файла квитанции.
     * @param {number} billID ID квитанции.
     */
    getBillFile(billID) {
      // console.log('--- getBillFile method ---')
      this.loadingBills.push(billID);

      commonApi
        .getBillsDownload(billID)
        .then((response) => {
          var index = this.loadingBills.indexOf(billID);
          if (index > -1) {
            this.loadingBills.splice(index, 1);
          }
          var fileName = `Квитанция-${billID}.pdf`;
          if (response.headers && response.headers["content-disposition"]) {
            var text = response.headers["content-disposition"];
            fileName = text.substring(
              text.indexOf('filename="') + 10,
              text.lastIndexOf('"')
            );
          }
          download(response.data, fileName, response.headers["content-type"]);
        })
        .catch((error) => {
          var index = this.loadingBills.indexOf(billID);
          if (index > -1) {
            this.loadingBills.splice(index, 1);
          }
          this.$notify.error({
            title: "Скачивание квитанции",
            message: "Не удалось скачать квитанцию. Повторите попытку позже.",
            duration: 6000,
            offset: 100,
          });
          if (!error) {
            return;
          }
        });
    },
  }
}
</script>

<style lang="sass" scoped>
@import "../../../sass/variables"
@import "../../../sass/mixins"

.invoices
  .ident-selector__select-all
    +TextMini($cAccentBlue)
    +Transition((color))
    text-align: center
    border-top: 1px solid $cBorder
    padding: 12px 16px

    &:hover
      color: $cAccent
      cursor: pointer

.invoices__container
  background-color: $cBgMain
  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
  border-radius: 16px
  padding: 16px

  @media screen and (min-width: 768px)
    padding: 16px 24px

.invoices__title
  +TextMain($cTextMain)
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    margin-bottom: 24px
</style>