<template>
  <modal 
    ref="chatDialog"
    title="Чат поддержки"
    :chatMode="true"
    class="modal--support-chat" 
    @close="$emit('close')"
    :key="'supportModalChat'"
  >
    <support-chat />
  </modal>
</template>

<script>
import Modal from "@/components/ui/Modal";
import supportChat from '@/components/SupportChat'

export default {
  name: "supportModalChat",

  components: {
    Modal,
    supportChat
  },
};
</script>