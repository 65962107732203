import { mapMutations, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      services: 'additionalService/getServicesList',
      goods: 'additionalService/getGoodsList',
    }),

    isGorserv() {
      return (
        this.eventBlockIsLoaded &&
        this.services[this.$route.params.AdditionalServiceId].ExternalSystem === "gorserv"
      );
    },

    cartCost() {
      const cost = this.goods
        .map((item) => {
          return item.Price * item.Quantity;
        })
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0);
      
      if (this.isGorserv) {
        return (cost < 1000 && cost != 0 ? 1000 : cost).toFixed(2);
      }

      return cost.toFixed(2);
    },

    cartWeight() {
      return this.additionalService.goods
        .map((item) => {
          return item.Weight * item.Quantity;
        })
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0)
        .toFixed(2);
    },

    cartIsEmpty() {
      return this.cartCost == 0;
    },
    isEqualZero() {
      return this.good.Quantity > 0;
    },
    isLessRest() {
      return this.good.Quantity < this.good.Rest;
    },
    isGoodsType() {
      return this.$route.params.type === "goods";
    },
    typeShopText() {
      return this.isGoodsType ? "товара" : "услуг";
    },
  },

  methods: {
    ...mapMutations({
      changeGoodCount: "additionalService/changeGoodCount"
    }),

    addToCart(val) {
      let currentCount = this.good.Quantity;
      if (currentCount + val >= 0 && currentCount + val <= this.good.Rest) {
        this.changeGoodCount({ ID: this.good.ID, count: val + currentCount });
      }
    },

    changeCart(event) {
      let count = parseInt(event.target.value);
      count = count ^ ((this.good.Rest ^ count) & -(this.good.Rest < count));
      if (!/^([1-9]\d*|0)$/.test(count)) {
        count = 0;
        event.target.value = "";
      } else {
        event.target.value = count;
      }
      if (this.good.Rest == count) {
        event.target.value = this.good.Rest;
      }
      this.changeGoodCount({ ID: this.good.ID, count });
    },

    detectCartLine() {
      let cartIndex =
        window.innerHeight +
        window.pageYOffset +
        document.querySelector("footer").clientHeight;
      this.cartIsBottom = cartIndex >= document.body.offsetHeight;
    },
  },
};
