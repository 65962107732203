//import { post } from "jquery";
import { mapGetters } from 'vuex';

export default {
	methods: {
		dateNoTime(dateString) {
			if(!dateString){
                return dateString;
            }
            return dateString.split(' ')[0];
        },		
        dateOnlyTime(dateString) {
			if(!dateString){
                return dateString;
            }
            var parts = dateString.split(' ');
            if(parts.length > 1){
                return parts[1];
            }
            return "00:00";
        },
        truncateText(text, length){
            if(text.length > length){
                var position = text.substring(0, length)
                    .lastIndexOf(' ');
                if(position > 0){
                    return text.substring(0, position) + '...';
                }
                return text.substring(0, length) + '...';
            }
            return text;
        },    
	},
    computed: {
        ...mapGetters({
            authToken: 'account/getAuthToken',
        }),
        copyrightYear: function(){
            return new Date().getFullYear();
        },
        tokenFromURL() {
            const url = new URL(window.location);
            return url.searchParams.get('token');
        }
    }
};
