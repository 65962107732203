<template>
  <div class="form-box__field">
    <div 
      :class="[
        'field', 
        { 'field--error': hasError || error },
        { 'field--active': (value && value.toString().length > 0) || fieldIsFocus },
        { 'field--popover': showPopover },
        { 'field--horizontal': horizontal },
        { 'field--has-button': editButton || $slots.button },
        { 'field--no-label' : !label }
      ]"
    >
      <div class="field__group">
        <div class="field__control">
          <label
            v-if="label" 
            class="field__label"
          >
            {{ label }}
          </label>
          <input
            :value="value"
            :ref="ref"
            :name="name"
            :type="inputType"
            :placeholder="placeholder"
            autocomplete="off"
            :step="(inputType === 'number') ? step : false"
            :readonly="readonly || (editButton && !editable)"
            class="field__input"
            v-on="toggleInputFocus()"
            @input="inputHandler"
          />
        </div>
        <template v-if="type === 'password'">
          <component 
            :is="eyePassword"
            :class="[
              'field__icon',
              { 'field__icon--hide': showPassword }
            ]"
            @click="showPassword = !showPassword"
          />
        </template>
        <slot name="icon"></slot>
        <slot name="button">
          <base-button
            v-if="editButton"
            style-class="secondary-text-mini"
            class="field__button"
            @click="editButtonHandler"
          >
            {{ editable ? 'Сохранить' : 'Изменить' }}
          </base-button>
        </slot>
      </div>
      <p
        v-show="((hasError || error) && message) || message" 
        class="field__message"
      >
        {{ message }}
      </p>
      <div 
        v-show="showPopover"
        class="field__popover"
      >
        <slot name="popover"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from '@vue-hero-icons/outline';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'FormBoxInput',

  components: {
    EyeIcon,
    EyeOffIcon,
    BaseButton
  },

  props: {
    // Подпись поля. Тег <label>
    label: {
      type: String,
      required: false,
      default: ''
    },
    // Атрибут name в <input>
    nameAttr: {
      type: String,
      required: false,
      default: ''
    },
    // Тип поля: text, password, amount, number... 
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    // Заглушка
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    // Значение модели
    value: {
      type: [ String, Number ],
    },
    // Текст сообщения об ошибке
    message: {
      type: String,
      required: false,
      default: ''
    },
    // Имя ссылки. Атрибут [ref]
    refName: {
      type: String,
      required: false,
      default: ''
    },
    // Состояние "В фокусе"
    focused: {
      type: Boolean,
      required: false,
      default: false
    },
    // Показывать всплывающее сообщение рядом
    showPopover: {
      type: Boolean,
      required: false,
      default: false
    },
    // Состояние "С ошибкой"
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    // Минимально допустимое значение (для поля с типом number или amount)
    min: {
      type: Number,
      required: false,
      default: 0
    },
    // Шаг инкремента или декремента (для поля с типом number или amount)
    step: {
      type: String,
      required: false,
      default: '0.01'
    },
    // Горизонтальный вид. Подпись, поле и кнопка редактирование отображаются в
    // одну строку
    horizontal: {
      type: Boolean,
      required: false,
      default: false
    },
    // Показывать кнопку редактирования
    editButton: {
      type: Boolean,
      required: false,
      default: false
    },
    // Состояние "Только чтение"
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      fieldIsFocus: false,
      showPassword: false,
      error: false,
      editable: false
    }
  },

  computed: {
    inputType() {
      if (this.type === 'password') {
        return this.showPassword ? 'text' : 'password';
      }

      if (this.type === 'amount') {
        return 'number';
      }

      return 'text';
    },

    eyePassword() {
      return `eye${this.showPassword ? "-off" : ''}-icon`;
    },

    ref() {
      // console.log('--- components -> ui -> form -> input -> ref computed ---');
      if (!this.refName) {
        return `${this.name}-control`;
      }

      return this.refName;
    },

    name() {
      if (!this.nameAttr) {
        return `form-box-input-${this._uid}`;
      }

      return this.nameAttr;
    }
  },

  methods: {
    inputHandler(e) {
      this.error = false;

      if (this.type === 'amount') {
        if (e.target.value <= this.min) {
          this.error = true;
        }

        if (e.target.value.indexOf(".") != '-1') {
          e.target.value = e.target.value.substring(0, e.target.value.indexOf(".") + 3);
        }
      }

      this.$emit('input', e.target.value);
    },

    toggleFieldFocus() {
      const setParams = (value) => {
        
        if (this.fieldIsFocus !== value) {
          this.fieldIsFocus = value;

          // Установка/снятие фокуса на поле ввода
          this.$nextTick(() => {
            if (value) {
              this.$refs[this.refName].focus();
            } else {
              this.$refs[this.refName].blur();
            }
          });
        }
      };

      return {
        click: () => setParams(true),
        clickout: () => setParams(false),
      }
    },

    toggleInputFocus() {
      const setParams = (value) => {
        if (this.fieldIsFocus !== value) {
          this.fieldIsFocus = value;
        }
      };

      return {
        focus: () => setParams(true),
        blur: () => setParams(false),
      }
    },

    editButtonHandler() {
      this.editable = !this.editable;
      
      if (!this.editable) {
        this.$emit('form-box:save');
      }
    }
  },

  watch: {
    focused(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.$nextTick(() => this.$refs[this.ref].focus());
      }
    },
    editable(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.$nextTick(() => {
          this.$refs[this.ref].focus();
          this.$refs[this.ref].select();
        });
      }
    }
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/input'
</style>