<template>
  <div class="profile-page">
    <page-header title="Профиль" />

    <section class="form-box">
      <form 
        class="form-box__form"
        @submit.prevent="save"
      >
        <div class="form-box__card">
          <fieldset class="form-box__fieldset">
            <legend class="form-box__fieldset-title">Информация</legend>

            <form-box-input 
              v-model="form.fio"
              label="ФИО"
              :horizontal="true"
              :edit-button="true"
              :message="errors.fio"
              @form-box:save="validation"
            />

            <form-box-input 
              v-model="form.email"
              label="E-mail"
              :horizontal="true"
              :edit-button="true"
              :message="errors.email"
              @form-box:save="validation"
            />
          </fieldset>

          <fieldset class="form-box__fieldset">
            <legend class="form-box__fieldset-title">Лицевые счета</legend>

            <template v-if="accounts.length">
              <div 
                v-for="(account, i) in accounts"
                :key="`account-${i}`"
                class="form-box__field"
              >
                <div class="field field--horizontal">
                  <div class="field__group">
                    <div class="field__control">
                      <label class="field__label field__label--main">
                        <span>Л/сч:</span> {{ account.Ident }}
                        <br>
                        {{ account.AccountType }}
                      </label>
                      <p class="field__input">
                        {{ account.Address }}
                      </p>
                    </div>
                    <base-button
                      style-class="text-mini"
                      modificator="error"
                      class="field__button"
                      @click="removeAccount(account.Id)"
                    >
                      Удалить
                    </base-button>
                  </div>
                </div>
              </div>
            </template>

            <div class="form-box__field">
              <div class="field field--horizontal">
                <base-button 
                  style-class="text-mini"
                  class="field__button field__button--add-account"
                  @click="openAddAccountIdentModal"
                >
                  <plus-icon />
                  Подключить лицевой счет
                </base-button>
              </div>
            </div>
          </fieldset>

          <!-- ! Таких данных нет -->
          <!-- <fieldset class="form-box__fieldset">
            <legend class="form-box__fieldset-title">Приборы учета</legend>
            <div class="form-box__field">
              <div class="field field--horizontal">
                <div class="field__group">
                  <div class="field__control">
                    <label class="field__label field__label--full-width">
                      Показывать выбывшие приборы
                    </label>
                  </div>
                  <toggler/>
                </div>
              </div>
            </div>
          </fieldset> -->

          <fieldset class="form-box__fieldset">
            <!-- <legend class="form-box__fieldset-title">Приборы учета</legend> -->
            <div class="form-box__field">
              <div class="field field--horizontal">
                <div class="field__group">
                  <div class="field__control">
                    <label class="field__label field__label--full-width">
                      Только электронные квитанции
                    </label>
                  </div>
                  <toggler 
                    v-model="form.onlyElectronicBills" 
                    @input="validation"
                  />
                </div>
                <p
                  v-show="errors.onlyElectronicBills" 
                  class="field__message"
                >
                  {{ errors.onlyElectronicBills }}
                </p>
              </div>
            </div>
          </fieldset>

          <fieldset class="form-box__fieldset form-box__fieldset--buttons">
            <base-button 
              style-class="text-mini"
              modificator="error"
              class="form-box__button"
              @click="removeUser"
            >
              <trash-icon class="form-box__button-icon" />
              Удалить аккаунт
            </base-button>
            <base-button 
              style-class="secondary-text-mini"
              class="form-box__button"
              @click="logout"
            >
              <logout-icon class="form-box__button-icon" />
              Выйти из аккаунта
            </base-button>
          </fieldset>
        </div> 
        <!-- /.form-box__card -->

        <base-button
          type="submit"
          width="200px"
          :disabled="!isValidate"
          class="form-box__save"
        >
          Сохранить
        </base-button>
      </form>
    </section>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/pages/common/PageHeader';
import FormBoxInput from '@/components/form/FormBoxInput';
import BaseButton from '@/components/ui/BaseButton';
import Toggler from '@/components/ui/Toggler';
import { TrashIcon, LogoutIcon, PlusIcon } from '@vue-hero-icons/outline';

export default {
  name: "Profile",

  components: {
    PageHeader,
    FormBoxInput,
    BaseButton,
    Toggler,
    TrashIcon,
    LogoutIcon,
    PlusIcon
  },

  data() {
    return {
      form: {
        email: "",
        fio: "",
        onlyElectronicBills: false
      },
      errors: {
        email: '',
        fio: '',
        onlyElectronicBills: ''
      },
      saveBankCard: false,
      dontSavePassword: true,
      showSuccessAlert: false,
      showDangerAlert: false,
    };
  },

  computed: {
    ...mapGetters({
      getAllInfo: 'account/getAllInfo'
    }),

    accounts() {
      return this.getAllInfo();
    },

    isValidate() {
      return (!this.errors.fio && 
        !this.errors.email &&
        !this.errors.onlyElectronicBills);
    }
  },

  created() {
    this.getInfo();
  },

  mounted() {
    this.$root.$on('updated-account-info', this.getAllInfo);

    this.cloneData();
    this.validation();
  },

  destroyed() {
    this.$root.$off('updated-account-info');
  },

  methods: {
    ...mapActions({
      updateProfile: 'account/updateProfile',
      deleteAccountByIdent: 'account/deleteAccountByIdent',
      getInfo: 'account/getInfo',
      deleteMobileUser: 'account/deleteMobileUser',
      logoutAction: 'account/logout'
    }),

    cloneData() {
      this.form = {
        email: this.authUser.email,
        fio: this.authUser.fio,
        onlyElectronicBills: !!this.authUser.onlyElectronicBills,
      };
    },

    save() {
      if (this.isValidate) {
        this.updateProfile(this.form)
          .then((result) => {
            if (result.isSucceed) {
              this.$notify.success('Данные успешно обновлены!');
            }
          })
          .catch((error) => {
            this.$notify.error(error);
          });
      } else {
        this.$notify.error('Ошибка обновления данных профиля! Проверьте правильность заполнения полей.');
      }
    },

    logout() {
      this.logoutAction()
        .then(() => {
          this.$router.push({ name: 'Login' });
        })
        .catch((error) => {
          this.$notify.error(error);
        });
    },

    removeAccount(id) {
      const account = this.accounts.find(account => account.Id === id);

      this.openDialog({
        title: 'Удаление лицевого счета',
        text: `Вы уверены, что хотите удалить лицевой счет №${account.Ident} (${account.Address})?`,
        buttons: [
          {
            type: 'additional',
            title: 'Отмена',
            handler: () => {
              this.cancelDialog();
            }
          },
          {
            type: 'base',
            title: 'Подтвердить',
            handler: () => {
              this.deleteAccountByIdent(account.Ident)
                .then((result) => {
                  if (result && result.error) {
                    this.$notify.error(result.error);
                    return;
                  }

                  this.$notify.success(`Лицевой счет №${account.Ident} удален.`);
                })
                .catch((error) => {
                  this.$notify.error(error)
                });

              this.cancelDialog();
            }
          }
        ]
      })
    },

    removeUser() {
      this.openDialog({
        title: 'Удаление аккаунта',
        text: 'Вы уверены, что хотите удалить аккаунт?',
        buttons: [
          {
            type: 'additional',
            title: 'Отмена',
            handler: () => {
              this.cancelDialog();
            }
          },
          {
            type: 'base',
            title: 'Подтвердить',
            handler: () => {
              this.deleteMobileUser()
                .then((result) => {
                  if (result.isSucceed) {
                    this.$notify.success('Ваш аккаунт удален');
                    this.logout();
                  }
                })
                .catch((error) => {
                  console.error(error)
                  this.$notify.error(error)
                });

              this.cancelDialog();
            }
          }
        ]
      })
    },

    testEmail() {
      /* eslint-disable */
      const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      /* eslint-enable */
      return regexp.test(this.form.email);
    },

    validation() {
      // console.log('--- validation method ---');

      this.errors.fio = '';
      this.errors.email = '';
      this.errors.onlyElectronicBills = '';

      if (!this.form.fio) {
        this.errors.fio = "Поле обязательно к заполнению";
      }

      if (this.form.email && !this.testEmail()) {
        this.errors.email = "Неверный формат";
      }

      if (this.form.onlyElectronicBills && !this.form.email) {
        this.errors.email = "Поле обязательно к заполнению";
        this.errors.onlyElectronicBills = "Заполните поле E-mail";
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'
@import '../sass/components/form/form'
@import '../sass/components/form/input'

.profile-page
  .form-box
    +ContentContainer()

  .form-box__card
    margin-bottom: 24px

  .form-box__fieldset--buttons
    flex-direction: column
    justify-content: flex-end
    padding: 16px

    @media screen and (min-width: 768px)
      flex-direction: row
      padding: 16px 24px

  .form-box__field:last-of-type
      margin-bottom: 0

  .form-box__button
    margin-right: 0
    margin-bottom: 16px

    &:last-child
      margin-right: 0
      margin-bottom: 0

    @media screen and (min-width: 768px)
      margin-right: 24px
      margin-bottom: 0 !important

  .form-box__button-icon
    +Size(16px)
    flex-shrink: 0
    margin-right: 4px

  .form-box__alert
    margin-bottom: 24px

  .form-box__save
    margin-left: auto

  .field__label
    &--main
      color: $cTextMain

      span
        color: $cLabel

    &--full-width
      max-width: 100%

  .field__button
    flex-shrink: 0

    &--add-account
      width: 100%
      margin-left: 0

      @media screen and (min-width: 768px)
        width: auto

  ::v-deep .field__message
    padding: 0
    color: $cError
</style>
