<template>
  <Modal @close="$emit('close')" class="modal-content_testimony">
    <div class="testimony-item_header">
      Политика оператора в отношении обработки персональных данных
    </div>
    <div style="max-width: 600px; max-height: 400px; overflow-y: scroll;text-align:justify;padding:5px;" v-html="text">
    </div>
  </Modal>
</template>

<script>
  import Modal from "@/components/ui/Modal";
  import * as commonApi from "@/api/common";

  export default {
    name: "personalDataPoliticsModal",
    components: {Modal},
    data() {
      return {
        text: '',
      }
    },
    mounted() {
      this.loadPolicy()
    },
    methods: {
      loadPolicy(){
        var host = window.location.protocol + "//" + window.location.hostname;
        // getPersonalDataPolicy
        commonApi.getPersonalDataPolicy(host)
          .then(response => {
            this.text = response.data
              .split('\n').map(x=>'<p>' + 
                 x.replace(/&/g, "&amp;")
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;")
                  .replace(/"/g, "&quot;")
                  .replace(/'/g, "&#039;") + '</p>').join('')
          });
      },
    },
  }
</script>
