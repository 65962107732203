<template>
  <div class="layout--empty" ref="layout">
    <main class="empty" ref="main">
      <div class="empty__container">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'emptyLayout'
}
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"

.empty
  +Size(100%)
  max-width: $sMWContainer
  flex-grow: 1

.empty__container
  +PageLayoutContainer(false)
  height: 100vh
</style>
