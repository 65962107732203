<template>
  <div 
    v-if="content.data"
    class="accounts"
  >
    <base-button 
      type="button"
      style-class="text"
      class="accounts__add"
      @click="openAddAccountIdentModal"
    >
      <plus-icon />
      Подключить лицевой счёт
    </base-button>

    <div
      v-show="accountsCount > 1" 
      class="accounts__total"
    >
      <div v-loading="isLoadingTotalSum">
        <div
          v-if="!isLoadingTotalSum" 
          class="total"
        >
          <div :class="[
            'total__content',
            (!canPayForAllAccounts) ? `total__content--no-button` : false
          ]">
            <div class="total__amount">
              <span>К оплате</span>
              {{ accountsCount }} лицевых счета на
              <strong>{{ totalSum }} ₽</strong>
            </div>
            <div 
              v-if="canPayForAllAccounts && !hideComissionInfo" 
              class="total__tax"
            >
              В сумму оплаты включена комиссия {{ totalComission }} ₽
            </div>
          </div>
          <base-button 
            v-if="canPayForAllAccounts"
            type="button"
            class="total__button"
            @click="openPayModal(null)"
          >
            Оплатить все
          </base-button>
        </div>  
      </div>
    </div>

    <div 
      class="accounts__list"
      v-loading="content.isLoading"
    >
      <div 
        v-for="(account, i) in content.data"
        :key="`account-${i}`"
        class="accounts__item"
      >
        <div class="card">
          <div class="card__header">
            <div class="card__title">
              <h3 class="card__name">ЛС № {{ account.Ident }}</h3>
              <p class="card__address">{{ account.Address }}</p>
            </div>
            <trash-icon 
              class="card__delete-icon"
              :title="`Удалить счёт ${account.Ident}`"
              @click="deleteAccountInfo(account.Ident)"
            />
          </div>
          <div class="card__body">
            <p class="card__field card__field--date">
              <span>На</span> {{ account.DebtActualDate }}
            </p>
            <p class="card__field card__field--amount">
              <span>К оплате</span> {{ account.Sum }} ₽
            </p>
          </div>
          <div class="card__footer">
            <base-button 
              type="button" 
              style-class="secondary"
              @click="openPayModal(account.AccountID)"
            >
              Оплатить
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TrashIcon, PlusIcon } from "@vue-hero-icons/outline";
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'personalAccounts',

  components: {
    TrashIcon,
    PlusIcon,
    BaseButton
  },

  props: {
    content: {
      type: [Array, Object],
      required: true
    },
  },

  data() {
    return {
      totalSum: 0,
      totalComission: 0,
      hideComissionInfo: false,
      isLoadingTotalSum: true
    }
  },

  computed: {
    ...mapGetters({
      canPayForAllAccounts: 'account/canPayForAllAccounts',
    }),

    /**
     * Уникальные лицевые счета. Удалены дубликаты.
     * @returns {array} Список счетов.
     */
    uniqueAccounts() {
      // console.log('--- uniqueAccounts computed value ---')
      return this.content.data
        .map((x) => {
          return x;
        })
        .filter(
          (x, i, self) => self.findIndex((t) => t.Ident == x.Ident) === i
        );
    },

    /**
     * Количество лицевых счетов
     * @returns {number}
     */
    accountsCount() {
      return this.content.data.length;
    }
  },

  watch: {
    content: {
      deep: true,
      handler() {
        this.getTotalSum()
      }
    }
  },

  methods: {
    ...mapActions({
      deleteAccountByIdent: 'account/deleteAccountByIdent',
      getSumWithCommission: 'account/getSumWithCommission'
    }),

    /**
     * Удаление адреса по номеру лицевого счета.
     * @param {number} ident - Номер лицевого счета.
     */
    deleteAccountInfo(ident) {
      // console.log('--- deleteAccountInfo ---')
      this.openDialog({
        title: 'Удаление лицевого счета',
        text: `Действительно удалить адрес (Л/С ${ident})?`,
        buttons: [
          {
            title: 'Отмена',
            type: 'additional',
            handler: () => {
              this.cancelDialog()
            }
          },
          {
            title: 'Удалить',
            type: 'base',
            handler: () => {
              this.deleteAccountByIdent(ident);

              this.cancelDialog();
            }
          },
        ]
      });
    },

    getTotalSum() {
      // console.log('--- getTotalSum method ---')
      if (this.content.data) {
        if (this.canPayForAllAccounts) {
          this.isLoadingTotalSum = true;

          this.getSumWithCommission({
            sum: this.uniqueAccounts.reduce((accumulator, current) => {
              return parseFloat((accumulator + current.Sum).toFixed(2));
            }, 0),
            accountID: null
          })
            .then((result) => {
              this.totalSum = result.TotalSum;
              this.totalComission = result.Commission;
              this.hideComissionInfo = result.HideComissionInfo;

              this.isLoadingTotalSum = false;
            })
            .catch(error => {
              throw error;
            });
        } else {
          this.totalSum = this.uniqueAccounts.reduce((accumulator, current) => {
            return parseFloat((accumulator + current.Sum).toFixed(2));
          }, 0);
          this.isLoadingTotalSum = false;
        }
      } else {
        this.isLoadingTotalSum = false;
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../sass/variables"
@import "../../../sass/mixins"

.accounts
.accounts__add
  width: 100%
  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)

.accounts__total
  padding: 24px 0

.accounts__item
  &:not(:last-child)
    margin-bottom: 16px

.accounts
  .total
    display: flex
    justify-content: flex-end
    align-items: center
    flex-wrap: wrap

    @media screen and (min-width: 768px)
      flex-wrap: nowrap

  .total__content
    width: 100%
    margin-bottom: 16px

    &--no-button
      margin-left: auto
      margin-right: auto

    @media screen and (min-width: 576px)
      width: auto
      margin-bottom: 0

  .total__amount
    +TextMain($cTextMain)
    text-align: center
    margin-bottom: 4px

    span
      color: $cLabel

    @media screen and (min-width: 768px)
      text-align: left

  .total__tax
    +TextMini()
    text-align: center

    @media screen and (min-width: 768px)
      text-align: left

  .total__button
    width: 100%

    @media screen and (min-width: 576px)
      width: 216px
      margin-left: 24px

  .card
    +Size(100%, auto)
    display: flex
    flex-direction: column
    justify-content: space-between
    background: $cBgMain
    box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
    border-radius: 16px
    padding: 16px

    @media screen and (min-width: 768px)
      padding: 16px 24px

  .card__header
    +Size(100%, auto)
    display: flex
    align-items: flex-start
    margin-bottom: 16px

    @media screen and (min-width: 768px)
      margin-bottom: 24px

  .card__title
    flex-grow: 1

  .card__name
    +TextHeader(2)
    margin-bottom: 8px

  .card__address
    +TextMini()
    display: inline-block
    width: 100%
    max-width: 210px
    margin-bottom: 0
  
  .card__delete-icon
    flex-shrink: 0
    color: $cIconNeutral
    +Transition((color))

    &:hover
      color: $cAccent
      cursor: pointer

  .card__body
    +Size(100%)
    flex-grow: 1

  .card__field
    margin-bottom: 0

    &:last-child
      margin-bottom: 0

    &--date
      +TextMain($cTextMain)

      span
        +TextMini()

    &--amount
      +TextSubMain($cTextMain)

      span
        +TextMain()

    @media screen and (min-width: 768px)
      margin-bottom: 4px

  .card__footer
    +Size(100%, auto)
    display: flex
    justify-content: flex-end
    align-items: center
    flex-wrap: wrap

    @media screen and (min-width: 768px)
      flex-wrap: nowrap

  .card__caption
    +TextMini()
    width: 100%
    margin-bottom: 16px

    @media screen and (min-width: 576px)
      width: auto
      margin-bottom: 0

  .card__button
    +Button('secondary')
    +Size(100%, 40px !important)
    border-radius: 12px

    @media screen and (min-width: 576px)
      +Size(112px, 40px)
</style>