<template>
  <div
    v-loading="!service"
    class="additional-service-page"
  >
    <template v-if="service">
      <page-header 
        :title="service.Name"
        :back="{
          title: 'Наш сервис',
          to: { name: 'Services' }
        }"
        class="additional-service-page__title"
      />

      <section class="additional-service">
        <a
          v-if="service.Address"
          :href="service.Address"
          target="_black" 
          class="additional-service__link"
        >
          {{ service.Address }}
        </a>

        <p
          v-if="service.Description"
          class="additional-service__description"
        >
          {{ service.Description }}
        </p>

        <figcaption
          v-if="img"
          class="additional-service__img"
        >
          <img :src="img" :alt="service.Name">
        </figcaption>
      </section>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import additionalServiceMixin from "@/mixins/additionalService";

import PageHeader from '@/components/pages/common/PageHeader';

export default {
  name: 'AdditionalServicePage',

  components: {
    PageHeader
  },

  mixins: [
    additionalServiceMixin
  ],

  data() {
    return {
      serviceId: null,
      img: null
    }
  },

  computed: {
    ...mapGetters({
      getService: 'additionalService/getServiceById'
    }),

    service() {
      if (this.serviceId) {
        return this.getService(this.serviceId);
      }

      return null;
    }
  },

  mounted() {
    this.serviceId = this.$attrs.id;
    this.getImg();
  },

  methods: {
    ...mapActions({
      getDescriptionImage: 'additionalService/getDescriptionImage',
    }),

    getImg() {
      if (this.serviceId) {
        this.getDescriptionImage(this.serviceId)
        .then((result) => {
          if (result) {
            this.img = result;
          }
        });
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.additional-service-page__title
  ::v-deep .page-header__title
    width: 100%

.additional-service__link
  +TextMain($cAccent)
  display: inline-block
  margin-bottom: 24px

.additional-service__description
  +TextMain($cTextMain)
  margin-bottom: 24px

  @media screen and (min-width: 992px)
    margin-bottom: 42px

.additional-service__img
  +ResponsiveImg(100%)
</style>