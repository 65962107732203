<template>
  <modal 
    title="Новая заявка" 
    class="new-request"
    @close="$emit('close')"
  >
    <form
      v-loading="isSending"
      v-on:submit.prevent 
      class="new-request__form" 
    >

      <!-- Account -->
      <form-box-select
        v-model="form.ident"
        label="Лицевой счет"
        :list="accountInfo"
        valuePropName="Ident"
        keyPropName="AccountID"
      >
        <!-- @input="setForm" -->
        <template #selectedOption="{ item }">
          (Л/с: {{ item.Ident }}) {{ item.Address }}
        </template>
        <template #option="{ item }">
          {{ item.Ident }} ({{ item.AccountType }})
        </template>
      </form-box-select>

      <!-- Type -->
      <form-box-select
        v-model="form.typeID"
        label="Тип заявки"
        :list="types"
        valuePropName="ID"
        keyPropName="Name"
      />

      <!-- Subtype -->
      <form-box-select
        v-if="subTypes"
        v-model="form.subTypeID"
        label="Неисправность"
        :list="subTypes"
        valuePropName="ID"
        keyPropName="Name"
      />

      <!-- Detail -->
      <form-box-select
        v-if="detailSubTypes"
        v-model="form.detailSubTypeID"
        label="Подробный вид"
        :list="detailSubTypes"
        valuePropName="ID"
        keyPropName="Name"
      />

      <alert
        v-if="creationDeniedMessage"
        :caption="creationDeniedMessage"
        type="warning"
      />

      <form-box-textarea
        v-model="form.text"
        label="Описание заявки"
        height="84px"
      />

      <form-box-file 
        v-model="files"
        accept="
          application/pdf, 
          image/jpeg, 	
          image/png, 
          application/excel,
          application/vnd.ms-excel,
          application/x-excel,
          application/x-msexcel, 
          application/msword"
        class="new-request__file"
        @input="selectFile"
      >
        <div class="file">
          <p class="file__title">
            Перетащите или загрузите файл
          </p>
          <p class="file__text">pdf, jpg, png, xlsx, doc не более 10 Мб</p>
          <div
            v-if="file" 
            class="file__list"
          >
            <p class="file__name">
              {{ file.name }}
            </p>
            <button 
              type="button"
              class="file__remove"
              @click.stop="resetFile"
            >
              <trash-icon />
            </button>
          </div>
        </div>
      </form-box-file>

      <base-button 
        type="submit"
        :disabled="!isValidate"
        class="new-request__submit"
        @click="newRequest"
      >
        Создать
      </base-button>
    </form>
      <!-- <input
        type="submit"
        @click="newRequest"
        class="application-form_submit-btn main-btn"
        :disabled="!isValidate"
        value="создать"
      /> -->
  </modal>
</template>

<script>
import { mapActions } from 'vuex';
import * as requestApi from '@/api/request';
import * as authService from '@/services/auth.service';
import Modal from "@/components/ui/Modal";
import FormBoxSelect from '@/components/form/FormBoxSelect';
import FormBoxTextarea from '@/components/form/FormBoxTextarea';
import FormBoxFile from '@/components/form/FormBoxFile';
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/ui/BaseButton';
import { TrashIcon } from '@vue-hero-icons/outline';

export default {
  name: "newRequestModal",

  components: {
    Modal,
    FormBoxSelect,
    FormBoxTextarea,
    FormBoxFile,
    Alert,
    BaseButton,
    TrashIcon,
  },
  
  data() {
    return {
      form: {
        ident: null,
        typeID: null,
        subTypeID: null,
        detailSubTypeID: null,
        text: "",
      },

      files: null,
      file: null,
      types: [],
      accountInfoIDs: [],
      isSending: false,
    };
  },
  
  computed: {
    isValidate() {
      return (
        this.form.text.length > 0 &&
        this.form.typeID !== null &&
        this.form.ident !== null &&
        !this.isCreationDenied
      );
    },

    accountInfo() {
      return this.$store.getters["account/getInfos"](this.accountInfoIDs);
    },

    selectedAccountInfo() {
      if (!this.form.ident) {
        return null;
      }
      var info = authService.getUserData();

      if (info && info.accounts && info.accounts.length) {
        var infos = info.accounts.filter((x) => x.ident == this.form.ident);
        if (infos.length) {
          return infos[0];
        }
      }

      return null;
    },

    isCreationDenied() {
      var info = this.selectedAccountInfo;
      return info && info.denyRequestCreation;
    },

    creationDeniedMessage() {
      var info = this.selectedAccountInfo;
      if (!info) {
        return null;
      }
      return info.denyRequestCreationMessage;
    },

    subTypes() {
      if (this.form.typeID) {
        const type = this.types.find((type) => {
          return type.ID === this.form.typeID && type.HasSubTypes
        });
        
        if (typeof type !== 'undefined' && type.SubTypes.length > 0) {
          return type.SubTypes;
        }
      }

      return null;
    },

    detailSubTypes() {
      if (this.form.subTypeID) {
        const type = this.subTypes.find((type) => {
          return type.ID === this.form.subTypeID && type.HasSubTypes
        });

        if (typeof type !== 'undefined' && type.SubTypes.length > 0) {
          return type.SubTypes;
        }
      }

      return null;
    }
  },

  mounted() {
    this.getRequestTypes();
    this.getAccountInfo();
  },

  methods: {
    ...mapActions({
      newRequestAction: 'request/newRequest',
      getInfoAction: 'account/getInfo',
      getRequestTypesAction: 'request/getRequestTypes'
    }),

    getRequestTypes() {
      this.getRequestTypesAction().then((result) => {
        this.types = [...result];

        this.types = this.types.map((el) => {
          return {
            ...el,
            Name: el.Name.charAt(0).toUpperCase() + el.Name.substr(1),
          };
        });
      });
    },

    selectFile() {
      if (this.files.length > 0) {
        this.file = this.files[0];
      } else {
        this.file = null;
      }
    },

    resetFile() {
      this.file = null;
    },

    newRequest() {
      if (!this.isValidate) {
        return;
      }

      this.isSending = true;

      this.newRequestAction(this.form)
        .then((result) => {
          if (result.id) {
            if (this.file) {
              let formData = new FormData();
              formData.append("requestId", result.id);
              formData.append("file", this.file);

              // TODO: refactor
              requestApi
                .addFile(formData)
                .then(() => {
                  this.isSending = false;
                  // this.$root.$emit("requests:new-request", result.id);
                })
                .catch(() => {
                  this.isSending = false;
                  // this.$root.$emit("requests:new-request", result.id);
                });
            }
            this.$notify.success('Заявка успешно создана');
            this.$router.push(`/requests/${result.id}`);
            this.$root.$emit("requests:new-request", result.id);
            this.$emit('close');
          } else {
            this.$notify.error('При создании заявки произошла ошибка');
          }

          this.isSending = false;
        })
        .catch((error) => {
          this.isSending = false;
          console.error(error);
        });
    },

    getAccountInfo() {
      this.getInfoAction()
        .then((result) => {
          this.accountInfoIDs = [...result.keys];
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.new-request
  +ContentContainer()

  .file
    +Size(100%, auto)
    display: flex
    justify-content: center
    flex-direction: column
    padding: 24px
    border: 1px dashed $cBorder
    border-radius: 16px
    cursor: pointer

  .file__title
    +TextMain()
    text-align: center
    margin-bottom: 8px

  .file__text
    +TextMini()
    text-align: center
    margin-bottom: 0

  .file__list
    display: inline-flex
    justify-content: center
    align-items: center
    margin-top: 8px

  .file__name
    +TextMain()
    margin-bottom: 0

  .file__remove
    +ButtonIcon()
    flex-shrink: 1
    margin-left: 16px

.new-request__form
  display: flex
  flex-direction: column

  @media screen and (min-width: 992px)
    display: block

.new-request__submit
  width: 200px
  align-self: center
</style>
