<template>
  <main class="main-content">
    <div class="container">
      <div class="row">
        <div class="main_left-column">
          <div class="container__header">
            <span
              class="router-link-active container__back-ico"
              @click="$router.push(previousRoute)"
            ></span>
            <span
              class="router-link-active container__back-btn"
              @click="$router.push(previousRoute)"
              >Назад</span
            >
          </div>
          <h1 class="main-inner_title">Опросы</h1>
          <div v-if="!!polls.length" class="show-closed_application">
            <switch-btn v-model="showPassedPolls" />
            Показать пройденные опросы
          </div>
          <div v-if="!nonPassedPolls.length">
            В данный момент нет опросов для прохождения
          </div>
          <div
            v-loading="isLoadingPolls"
            class="scroll-content scroll-content_application"
            ref="pollListScroll"
          >
            <div
              v-for="item in filteredPolls"
              class="polls-wrapper"
              :key="`poll-${item.ID}`"
            >
              <poll-item :poll="item" @startpoll="startPoll" />
            </div>
          </div>
        </div>

        <div v-if="!this.isMobile()" class="main_right-column">
          <poll-form
            v-if="selectedPoll"
            :poll="selectedPoll"
            @close="onClosePoll"
            @pass="onPassPoll"
          />
        </div>
        <modal name="PollFormModal" width="90%" height="auto">
          <poll-form
            v-if="selectedPoll"
            :poll="selectedPoll"
            @close="onClosePoll"
            @pass="onPassPoll"
          />
        </modal>
      </div>
    </div>

    <modal-wrapper
      :show="showPassedPollModal"
      @close="closePassedPollModal"
      class="testimony_modal"
    >
      <p>
        Спасибо за участие в опросе! Ответы успешно переданы!
      </p>
      <span class="poll__button main-btn" @click="closePassedPollModal">
        <span>OK</span>
      </span>
    </modal-wrapper>
  </main>
</template>

<script>
//vuex
import { mapActions, mapGetters, mapState } from "vuex";
//components
import PollForm from "@/components/pages/polls/PollForm";
import PollItem from "@/components/pages/polls/PollItem";
import SwitchBtn from "@/components/ui/SwitchBtn";
import modalWrapper from "@/components/modals/modalWrapper";

import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "Polls",
  components: {
    PollForm,
    PollItem,
    SwitchBtn,
    modalWrapper,
  },
  data() {
    return {
      selectedPoll: null,
      showPassedPolls: false,
      showPassedPollModal: false,
      perfectScrollConfig: { pollListScroll: null },
      isLoadingPolls: false,
    };
  },
  computed: {
    ...mapState({
      polls: (state) => state.polls.polls,
    }),
    ...mapGetters({
      nonPassedPolls: "polls/nonPassedPolls",
    }),
    previousRoute() {
      if (this.$route.params.breadcrumbs) {
        let index = this.$route.params.breadcrumbs.length - 2;
        return this.$route.params.breadcrumbs[index].route;
      }
      return {
        name: "Home",
      };
    },
    filteredPolls() {
      if (this.showPassedPolls) {
        return this.polls.filter((item) => {
          return (
            item.Questions.filter((question) => question.IsCompleteByUser)
              .length == item.Questions.length
          );
        });
      } else {
        return this.nonPassedPolls;
      }
    },
  },
  created() {
    this.toggleLoadingPolls();
    this.getEventBlockData().then(() => {
      this.toggleLoadingPolls();
    });
  },
  mounted() {
    this.initPerfectScroll();
  },

  watch: {
    showPassedPolls() {
      this.$refs.pollListScroll.scrollTo({
        top: 0,
        left: 0,
      });
    },
    nonPassedPolls() {
      this.showPassedPolls = !this.nonPassedPolls.length;
    },
  },

  methods: {
    ...mapActions({ getEventBlockData: "news/getEventBlockData" }),
    startPoll(poll) {
      this.selectedPoll = poll;
      if (this.isMobile()) {
        this.$modal.show("PollFormModal");
      }
    },
    onClosePoll() {
      this.selectedPoll = null;
      if (this.isMobile()) {
        this.$modal.hide("PollFormModal");
      }
    },
    onPassPoll() {
      this.selectedPoll = null;
      this.showPassedPollModal = true;
      if (this.isMobile()) {
        this.$modal.hide("PollFormModal");
      }
      this.toggleLoadingPolls();
      this.getEventBlockData().then(() => {
        this.toggleLoadingPolls();
      });
    },
    closePassedPollModal() {
      this.showPassedPollModal = false;
    },
    initPerfectScroll() {
      Object.keys(this.perfectScrollConfig).forEach((item) => {
        if (this.perfectScrollConfig[item])
          this.perfectScrollConfig[item].destroy();
        this.perfectScrollConfig[item] = new PerfectScrollbar(this.$refs[item]);
      });
    },
    toggleLoadingPolls() {
      this.isLoadingPolls = !this.isLoadingPolls;
    },
  },
};
</script>

<style>
.container__header {
  display: flex;
  align-items: center;
  padding: 10px;
}
.container__back-ico {
  cursor: pointer;
  position: relative;
  transform: rotate(225deg);
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-top: 2px solid var(--base-color);
  border-right: 2px solid var(--base-color);
}
.container__back-btn {
  cursor: pointer;
}
.polls-wrapper {
  padding: 5px;
}
</style>
