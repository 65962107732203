import * as commonApi from "@/api/common";
import download from "downloadjs";

const state = {
  messages: [],
  updateKey: null,
  mockups: [],
};

const getters = {
  getSortedMessages(state) {
    return state.messages.reduce((newEl, item) => {
      let date = item.Added.split(" ");

      if (!Array.isArray(newEl[date[0]])) {
        newEl[date[0]] = [];
      }

      newEl[date[0]].push({
        ...item,
        Added: date[1],
      });

      return newEl;
    }, {});
  },

  getMockupsLinksList: ({ mockups }) => mockups,
};

const mutations = {
  SET_MESSAGES: (state, data) => {
    state.messages = data.Messages;
    state.updateKey = data.UpdateKey;
  },

  SET_NEW_MESSAGES: (state, data) => {
    if (JSON.stringify(state.messages) !== JSON.stringify(data.Messages)) {
      state.messages = data.Messages;
    }
  },

  ADD_MOCKUPS_LINK: (state, url) => {
    // console.log('--- ADD_MOCKUPS_LINK mutation ---')
    state.mockups = [
      ...state.mockups,
      url
    ];
  },
};

const actions = {
  generateGUID() {
    return commonApi
      .registerNewDevice()
      .then((res) => localStorage.setItem("deviceID", res.data));
  },

  getMessages({ commit }, params) {
    return commonApi
      .getMessages(params)
      .then((res) => {
        commit("SET_MESSAGES", res.data);
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },

  updateMessages({ commit }, params) {
    return commonApi
      .getMessages(params)
      .then((res) => {
        commit("SET_NEW_MESSAGES", res.data);
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },

  addMessage(context, data) {
    return commonApi
      .addMessage(data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },

  addFile(context, data) {
    return commonApi
      .addFile(data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },

  getFile(context, params) {
    return commonApi
      .getFile(params)
      .then((res) => {
        let filename = "";
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(res.headers["content-disposition"]);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
        download(res.data, filename, res.headers["content-type"]);
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },

  /**
   * Получение ссылки на мокап. 
   * Сам commonApi запрос на сервер не делает - только формирует ссылку 
   * на изображение. 
   * @param {Object} state
   * @param {Function} commit Функция вызова мутации. 
   * @param {Integer} number Число для формирование ссылки. 
   */
  getMockupsLink({ commit }, number) {
    // console.log('--- getMockupsLink action ---')
    const url = commonApi.getMockupDownloadLink(number);
    if (url) {
      commit('ADD_MOCKUPS_LINK', url);
    }
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
