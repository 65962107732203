<template>
  <div class="section-header">
    <slot name="icon" />
    <slot name="title">
      <h2 class="section-header__title">
        {{ title }}
      </h2>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',

  props: {
    title: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables'
@import '../../../sass/mixins'

.section-header
  display: inline-flex
  margin-bottom: 16px

  @media screen and (min-width: 992px)
    margin-bottom: 24px

  ::v-deep .icon
    color: $cIconNeutral
    margin-right: 8px
    flex-shrink: 0

.section-header__title
  +TextHeader(1)
  flex-shring: 1

</style>