<template>
  <div class="layout--services" ref="layout">
    <header-block ref="header" />
    <main 
      ref="main"
      class="page" 
    >
      <div class="page__container">
        <slot />
      </div>
    </main>
    <footer-block ref="footer" />
  </div>
</template>

<script>
import HeaderBlock from "@/layouts/HeaderBlock";
import FooterBlock from "@/layouts/FooterBlock";

export default {
  name: "ServicesLayout",

  components: { 
    HeaderBlock, 
    FooterBlock, 
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$root.$emit("page-loading", newValue);
      }
    },
  },

  created() {
    this.resizeThrottlerFunctionList.push(
      this.setMainOffset
    );
  },

  mounted() {
    this.setMainOffset()
  },

  methods: {
    setMainOffset() {
      const $main = this.$refs.main;
      const $header = this.$refs.header;
      const height = $header.$el.offsetHeight;
      const $cart = document.querySelector('#cart');

      const $layout = this.$refs.layout;
      const cartHeight = $cart.offsetHeight;

      $layout.style.marginBottom = `${cartHeight}px`;

      if (this.isMobileView()) {
        $layout.style.minHeight = `calc(100vh - ${cartHeight}px)`;
      }

      $main.style.marginTop = `${height}px`;
    }
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"

.page
  +Size(100%)
  max-width: $sMWContainer
  flex-grow: 1

  &--requests
    .page__container
      padding-top: 0
      padding-bottom: 0

.page__container
  +PageLayoutContainer(false)
  padding: 
    top: 0
    bottom: 0
</style>
