import Vue from "vue";
import "./plugins/axios";
import "./plugins/mask";
import "./plugins/element";
import "./plugins/perfect-scrollbar";
import "./plugins/vue-js-modal";
import 'clickout-event';
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./mixins";
// import "./plugins/element.js";
import "./sass/main.sass";

import VueSlickCarousel from "vue-slick-carousel";

import AuthLayout from "@/layouts/LoginRegisterLayout";
import PageLayout from "@/layouts/Page";
import EmptyLayout from "@/layouts/EmptyLayout";
import RequestsLayout from "@/layouts/RequestsLayout";
import RequestsFrameLayout from "@/layouts/RequestsFrameLayout";
import ServicesLayout from "@/layouts/ServicesLayout";

Vue.component("vue-slick-carousel", VueSlickCarousel);

Vue.component("auth-layout", AuthLayout);
Vue.component("page-layout", PageLayout);
Vue.component("empty-layout", EmptyLayout);
Vue.component("requests-layout", RequestsLayout);
Vue.component("requests-frame-layout", RequestsFrameLayout);
Vue.component("services-layout", ServicesLayout);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
