export default {
  data() {
    return {
      resizeTimeout: null,
      resizeThrottlerFunctionList: []
    }
  },

  mounted() {
    window.addEventListener("resize", () => this.resizeThrottler(
      this.resizeThrottlerFunctionList
    ));
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeThrottler);
  },

  methods: {
    resizeThrottler () {
      // ignore resize events as long as an actualResizeHandler execution is in the queue
      if (!this.resizeTimeout) {
        this.resizeTimeout = setTimeout(() => {
          this.resizeTimeout = null

          this.resizeThrottlerFunctionList.map((item) => {
            this.$nextTick(() => {
              item()
            })
          })

         // The actualResizeHandler will execute at a rate of 15fps
        }, 66);
      }
    },
  },
}
