<template>
  <div class="form-box__field">
    <div 
      :class="[
        'field', 
        'field--textarea', 
        { 'field--error': hasError || error },
        { 'field--active': value.toString().length > 0 || fieldIsFocus },
        { 'field--popover': showPopover },
      ]"
    >
      <div 
        class="field__group"
        :style="`
          ${(width) ? `width: ${width};` : ''}
          ${(height) ? `height: ${height};` : ''}
        `"
      >
        <div class="field__control" v-on="toggleFieldFocus()">
          <label class="field__label">
            {{ label }}
          </label>
          <textarea 
            :value="value"
            :name="name" 
            :ref="ref"
            :cols="(cols) ? cols : false" 
            :rows="(rows) ? rows : false"
            :placeholder="placeholder"
            class="field__textarea"
            v-on="toggleInputFocus()"
            @input="inputHandler"
          />
        </div>
        <slot name="icon"></slot>
      </div>
      <p
        v-show="(hasError || error) && message" 
        class="field__message"
      >
        {{ message }}
      </p>
      <div 
        v-show="showPopover"
        class="field__popover"
      >
        <slot name="popover"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormBoxTextarea',

  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [ String, Number ],
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    refName: {
      type: String,
      required: false,
      default: ''
    },
    focused: {
      type: Boolean,
      required: false,
      default: false
    },
    showPopover: {
      type: Boolean,
      required: false,
      default: false
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    cols: {
      type: Number,
      required: false,
      default: 0
    },
    rows: {
      type: Number,
      required: false,
      default: 0
    },
    width: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      text: '',
      fieldIsFocus: false,
      showPassword: false,
      error: false,
    }
  },

  computed: {
    ref() {
      // console.log('--- components -> ui -> form -> textarea -> ref computed ---');
      if (this.refName) {
        return this.refName;
      }

      if (!this.refName && this.name) {
        return `textarea${this.name}Control`;
      }

      return `textarea${this._uid}Control`;
    }
  },

  watch: {
    focused(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.$nextTick(() => this.$refs[this.refName].focus());
      }
    }
  },

  methods: {
    inputHandler(e) {
      // console.log('--- components -> ui -> form -> textarea -> inputHandler method ---');
      this.$emit('input', e.target.value);
    },

    toggleFieldFocus() {
      const setParams = (value) => {
        
        if (this.fieldIsFocus !== value) {
          this.fieldIsFocus = value;

          // Установка/снятие фокуса на поле ввода
          this.$nextTick(() => {
            if (value) {
              this.$refs[this.ref].focus();
            } else {
              this.$refs[this.ref].blur();
            }
          });
        }
      };

      return {
        click: () => setParams(true),
        clickout: () => setParams(false),
      }
    },

    toggleInputFocus() {
      const setParams = (value) => {
        if (this.fieldIsFocus !== value) {
          this.fieldIsFocus = value;
        }
      };

      return {
        focus: () => setParams(true),
        blur: () => setParams(false),
      }
    }
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/input'
</style>