<template>
  <modal
    title="Оплата" 
    class="modal--request pay-modal"
    @close="$emit('close')"
  >
    <p class="pay-modal__text">
      {{ info.PaidServiceText }}
    </p>

    <div class="pay-modal__field">
      Итого к оплате:
      <strong>{{ info.PaidSumm }} ₽</strong>
    </div>
  
    <form-box-radioset
      v-model="paidType"
      label="Способ оплаты"
      :list="payTypes"
    />

    <div
      class="pay-modal__field pay-modal__field--toggler"
      v-if="config && 
        config.useBonusSystem && 
        info.BonusAmount"
    >
      Зачесть бонусами <strong>{{ info.BonusAmount }}</strong> балл(ов)
      <toggler 
        v-model="paidWithBonuses" 
        class="pay-modal__toggler"
      />
    </div>

    <base-button 
      class="pay-modal__submit"
      @click="paidRequest"
    >
      Оплатить
    </base-button>

    <alert type="info">
      После успешной оплаты Вам придет код подтверждения в смс
    </alert>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '@/components/ui/Modal';
import Toggler from '@/components/ui/Toggler';
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/ui/BaseButton';
import FormBoxRadioset from '@/components/form/FormBoxRadioset';

export default {
  name: 'PayRequestModal',

  components: {
    Modal,
    Toggler,
    Alert,
    BaseButton,
    FormBoxRadioset
  },

  props: {
    info: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      payTypes: [
        {
          title: 'Картой',
          value: 'online',
        },
        {
          title: 'При получении',
          value: 'cash',
        },
      ],
      paidType: "online",
      paidWithBonuses: false
    }
  },

  methods: {
    ...mapActions({
      sendPaidRequestCompleteCodeCash: "request/sendPaidRequestCompleteCodeCash",
      getPayLink: "account/getPayLink",
    }),

    closePaidModal() {
      this.showPaidModal = false;
      this.paidType = "online";
      this.paidWithBonuses = false;
    },

    paidRequest() {
      if (this.paidType === "online") {
        this.getPayLink({
          sum: this.info.PaidSumm,
          paidRequestId: this.info.ID,
          paymentSystem: null,
          allowCardSaving: false,
          payAll: false,
          bonusAmount:
            this.paidWithBonuses && this.info.BonusAmount
              ? this.info.BonusAmount
              : 0,
        }).then((result) => {
          if (result.payLink && result.payLink.length > 0) {
            location.href = result.payLink;
            this.$emit('close');
          }
        });
        return;
      }

      if (this.paidType === "cash") {
        this.sendPaidRequestCompleteCodeCash({
          requestId: this.info.ID,
          phone: this.info.Phone,
        }).then((result) => {
          if (result.isSucceed) {
            this.$root.$emit('paid-request');
            this.$emit('close');
          }
        });
        return;
      }
    },
  }
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.pay-modal__text
  +TextMain($cTextMain)
  margin-bottom: 24px

.pay-modal__field
  +TextMain($cAccentBlue)
  display: inline-flex
  align-items: center
  margin-bottom: 24px

  strong
    margin: 0 4px

.pay-modal__toggler
  margin-left: 16px

.pay-modal__submit
  margin-bottom: 24px
</style>