<template>
  <ul class="breadcrumbs">
    <li
      v-for="(item, index) in items"
      :key="'breadcrumbs-item-' + index"
      class="breadcrumbs__item"
    >
      <router-link
        :to="item.route"
        :class="[
          'breadcrumbs__link',
          { 'breadcrumbs__link--disabled': !item.route }
        ]"
      >
        {{ item.text }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Breadcrumbs",

  props: {
    items: {
      type: Array,
      required: true
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.breadcrumbs
  margin-bottom: 24px
  +TextMain()

.breadcrumbs__item
  display: inline

.breadcrumbs__item:not(:last-child):after
  content: "/"

.breadcrumbs__link
  &--disabled
    color: $cTextMain
    cursor: default

  &:not(&--disabled):hover
    color: $cAccentBlueHover
</style>
