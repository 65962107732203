<template>
  <footer class="footer">
    <div class="footer__container">
      <p class="footer__company-name">
        {{ config.main_name }}
      </p>

      <div class="footer__app-links">
        <a 
          v-if="config.appLinkIOS"
          :href="config.appLinkIOS"
          class="footer__app-link"
        >
          <img 
            :src="resolveUrl('/img/static/application/app-store-gray.svg')" 
            alt="AppStore"
          >
        </a>
        <a 
          v-if="config.appLinkAndroid"
          :href="config.appLinkAndroid"
          class="footer__app-link"
        >
          <img 
            :src="resolveUrl('/img/static/application/google-play-gray.svg')" 
            alt="GooglePlay"
          >
        </a>
      </div>

      <div class="footer__empty"></div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "FooterBlock"
  }
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"

.footer
  +Size(100%, auto)
  display: flex
  justify-content: center
  background-color: $cBgSec
  border-top: 1px solid $cBorder

.footer__container
  +PageLayoutContainer()

.footer__company-name,
.footer__app-links,
.footer__empty
  display: block
  flex: 0 1 auto
  width: auto

  @media screen and (min-width: 768px)
    flex: 1 0 calc(100% / 3)
    width: calc(100% / 3)

.footer__company-name
  +TextMini($cTextMain)
  margin-bottom: 0

.footer__app-links
  +ListReset()
  display: flex

  // @media screen and (min-width: 768px)
  //   display: bo

.footer__app-link
  +ResponsiveImg(80px, auto)
  display: inline-block
  margin: 0 calc(16px / 2)
  flex-shring: 1

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0

  @media screen and (min-width: 768px)
    width: 126px
    margin: 0 calc(44px / 2)

.footer__empty
  display: none

  @media screen and (min-width: 768px)
    display: block
</style>
