<template>
  <div class="services-page">
    <page-header title="Специальные предложения" />

    <!-- TODO: В дизайне есть поиск. А что он ищет - ХЗ. -->

    <div v-loading="!servicesGroups">
      <template v-if="servicesGroups">
        <template v-if="servicesGroups.length">
          <service-group
            v-for="(service, i) in servicesGroups"
            :key="`service-group-${i}`"
            :group="service"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import additionalServiceMixin from "@/mixins/additionalService";
import PageHeader from '@/components/pages/common/PageHeader';
import ServiceGroup from "@/components/pages/services/ServiceGroup";

export default {
  name: "Services",

  components: { 
    PageHeader,
    ServiceGroup,
  },

  mixins: [
    additionalServiceMixin
  ],

  props: {
    serviceId: null,
  },

  computed: {
    ...mapGetters({
      getServicesByGroups: 'additionalService/getServicesByGroups'
    }),

    servicesGroups() {
      // TODO: Оставить только геттер
      return this.getServicesByGroups;
    }
  },

  mounted() {
    if (this.servicesGroups && this.servicesGroups.length <= 0) {
      this.getEventBlockData();
    }
  },

  methods: {
    ...mapActions({
      getGoods: "additionalService/getGoods",
    }),
  },
};
</script>
