<template>
  <div
    ref="modal" 
    :class="[
      'modal',
      { 'modal--base': setBaseStyle },
      { 'modal--chat': chatMode }
    ]"
  >
    <div
      ref="modalHeader" 
      :class="[
        'modal__header',
        { 'modal__header--left': !title || (title && closeButton) }
      ]"
    >
      <h4 
        v-if="title"
        class="modal__title" 
      >
        {{ title }}
      </h4>
      <x-icon 
        v-if="closeButton && !dialog"
        role="button"
        class="modal__close-icon"
        @click="$emit('close')"
      />
    </div>
    <div class="modal__body">
      <slot />
    </div>
  </div>
</template>

<script>
  import { XIcon } from "@vue-hero-icons/outline"

  export default {
    name: "Modal",

    components: {
      XIcon
    },

    props: {
      title: {
        type: String,
        required: false,
        default: ''
      },
      setBaseStyle: {
        type: Boolean,
        required: false,
        default: true
      },
      chatMode: {
        type: Boolean,
        required: false,
        default: false
      },
      autoClose: {
        type: Number,
        required: false,
        default: 0
      },
      dialog: {
        type: Boolean,
        required: false,
        default: false
      },
      closeButton: {
        type: Boolean,
        required: false,
        default: true
      }
    },

    mounted() {
      this.closeTimer()
    },

    methods: {
      closeTimer() {
        if (this.autoClose > 0) {
          setTimeout(() => {
            this.$parent.close();
          }, this.autoClose)
        }
      }
    }
  }
</script>

<style lang="sass" rel="stylesheets/scss" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.modal
  &--base
    padding: 16px
    background: $cBgMain
    box-shadow: 0px 32px 40px rgba(101, 107, 119, 0.24), 0px 8px 16px rgba(101, 107, 119, 0.16)
    border-radius: 16px

    @media screen and (min-width: 992px)
      padding: 24px

  &--chat
    display: flex
    flex-direction: column
    justify-content: space-between
    height: calc(100vh - 2px)
    padding: 0

    .modal__header
      margin-bottom: 0
      padding: 16px
      background-color: $cBgMain
      box-shadow: 0px 1px 2px rgba(60, 80, 119, 0.24)

      @media screen and (min-width: 992px)
        padding: 24px

    .modal__title
      +TextHeader(1)
      text-align: left

    @media screen and (min-height: 637px)
      max-height: 637px

    .modal__body
      height: calc(100% - 56px)

  &--ident
    .modal__title
      text-align: left

    .modal__close-icon
      +Size(24px)

.modal__header
  display: flex
  align-items: center
  margin-bottom: 16px

  &--left
    justify-content: flex-end

.modal__title
  +TextHeader(2)
  text-align: center
  width: 100%
  flex-shrink: 1
  margin-bottom: 0

.modal__close-icon
  flex-shrink: 0
  color: $cIconNeutral
  cursor: pointer
  +Transition((color))

  &:hover
    color: $cTextMain
  
.modal__body
  height: 100%
</style>

<style lang="sass">
  .vm--modal
    width: 100% !important
    left: 50% !important
    transform: translateX(-50%) !important
    background-color: transparent  !important
    border-radius: 16px !important
    overflow: initial !important

    @media screen and (min-width: 576px)
      width: 540px !important
</style>
