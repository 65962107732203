<template>
  <section 
    v-if="isLoadingMessages"
    class="request-chat"
  >
    <!-- Messages -->
    <perfect-scrollbar 
      ref="chatContainer"
      class="request-chat__chat-wrapper"
    >
      <div 
        v-for="(messagesArray, date) in messages"
        :key="`list-date-${date}`"
        class="request-chat__chat"
      >
        <time class="request-chat__date">- {{ date }} -</time>

        <div
          v-for="message in messagesArray"
          :key="`message-${message.ID}`"
          :class="[
            'request-chat__message',
            `${message.IsSelf ? 'request-chat__message--user' : 'operator'}`
          ]"
        >
          <article class="message">
            <div class="message__header">
              <p class="message__name">{{ message.AuthorName }}</p>
              <time class="message__date">{{ message.Added }}</time>
            </div>

            <div class="message__body">
              <a
                v-if="message.FileID > 0"
                class="message__text--file"
                @click="getFile(message.FileID)"
              >
                <download-icon class="message__download-icon" />
                {{ message.Text }}
              </a>
              <pre 
                v-else
                class="message__text"
              >
                {{ message.Text }}
              </pre>
            </div>
          </article>
        </div>
      </div>
    </perfect-scrollbar>

    <!-- TODO: Pane -->
    <div
      v-if="!info.IsClosed"
      class="request-chat__pane"
    >
      <!-- TODO: Remove -->
      <!-- <base-button
        v-if="canCancelingRequest"
        style-class="additional"
        class="request-chat__button"
        @click="cancelRequest"
      >
        Отменить заявку
      </base-button> -->

      <base-button
        v-if="showPayButton && canPay"
        class="request-chat__button"
        @click="checkSumIsVerified"
      >
        Оплатить
      </base-button>

      <base-button
        v-if="info.StatusID === 5"
        style-class="additional"
        class="request-chat__button"
        @click="openReopenRequestModal(info.ID, info.RequestNumber)"
      >
        Переоткрыть
      </base-button>

      <base-button
        v-if="info.CanClose && (info.StatusID === 5)"
        class="request-chat__button"
        @click="closeRequest"
      >
        Подтвердить
      </base-button>
    </div>

    <!-- Form -->
    <form
      v-if="!config.disableCommentingRequests && !info.IsClosed"
      v-show="requestId"
      v-on:submit.prevent
      class="request-chat__form"
    >
      <!-- Attach -->
      <form-box-file 
        class="request-chat__attach"
        @input="addFile"
      >
        <paper-clip-icon class="request-chat__attach-icon" />
      </form-box-file>

      <input
        v-model="form.message"
        type="text"
        placeholder="Сообщение"
        :disabled="isSendingMessage"
        class="request-chat__input"
        @keyup.enter="addMessage"
      />

      <button
        v-loading="isSendingMessage"
        type="submit" 
        title="Отправить"
        :disabled="form.message.length === 0"
        class="request-chat__send" 
        @click="addMessage"
      >
        <paper-airplane-icon class="request-chat__send-icon"/>
      </button>
    </form>
  </section>
</template>

<script>
import * as requestApi from "@/api/request";
import { mapGetters, mapActions } from 'vuex';
import { PaperClipIcon, PaperAirplaneIcon, DownloadIcon } from "@vue-hero-icons/outline"
import download from "downloadjs";
import FormBoxFile from '@/components/form/FormBoxFile';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'RequestChat',

  components: {
    FormBoxFile,
    PaperClipIcon,
    PaperAirplaneIcon,
    DownloadIcon,
    BaseButton,
  },

  props: {
    requestId: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      info: null,
      files: null,
      messageIDs: null,
      isLoadingMessages: false,
      isSendingMessage: false,
      form: {
        message: "",
        file: null,
      },
      showPaidModal: false,
      chatContainer: null,
    }
  },

  computed: {
    ...mapGetters({
      getMessages: 'request/getMessages',
      getLastMessage: 'request/getLastMessage'
    }),

    messages() {
      if (this.isLoadingMessages) {
        let messages = this.getMessages(this.messageIDs);

        return messages.reduce((newEl, item) => {
          let date = item.Added.split(" ");

          if (!Array.isArray(newEl[date[0]])) {
            newEl[date[0]] = [];
          }

          newEl[date[0]].push({
            ...item,
            Added: date[1],
          });

          return newEl;
        }, {});
      }

      return null;
    },

    showPayButton() {
      let lastMessage = this.getLastMessage();

      return (
        !!lastMessage &&
        !lastMessage.IsSelf &&
        !!this.info &&
        !this.info.IsPaidByUser &&
        this.info.IsPaid && //IsPaid - заявка платная, а не оплаченная!
        this.info.CanPayOnMobileApp
      );
    },

    canPay() {
      return (
        this.info &&
        // 5 – Выполнена(выполнена)
        this.info.StatusID != 5 &&
        // 6 - Житель подтвердил(закрыл) (закрыта)
        this.info.StatusID != 6 &&
        // 7 - Отменена(житель отказался) (закрыта)
        this.info.StatusID != 7 &&
        // 8 - Закрыта с низкой оценкой(закрыта)
        this.info.StatusID != 8 &&
        // 10 - Закрыта, низкая оценка обработана(закрыта)
        this.info.StatusID != 10 &&
        // 11 - Закрыта сотрудником(закрыта)
        this.info.StatusID != 11 &&
        // 12 - Закрыта автоматически(закрыта)
        this.info.StatusID != 12
      );
    },

    canCancelingRequest() {
      return (
        this.info.CanCancelingRequest &&
        this.info.StatusID === 1 || this.info.StatusID === 9
      )
    }
  },

  watch: {
    requestId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getRequest();
      }
    },
  },

  created() {
    this.$root.$on('requests:update', this.getRequest);
    this.$root.$on('requests:sent-message', this.getRequest);
    this.$root.$on('requests:closed-request', this.getRequest);
  },

  mounted() {
    if (this.$route.params.requestID) {
      this.requestID = +this.$route.params.requestID;
      this.getRequest();
    }
  },

  updated() {
    this.scrollToBottom();
  },

  destroyed() {
    this.$root.$off('requests:update');
    this.$root.$off('requests:sent-message');
    this.$root.$off('requests:closed-request');
  },

  methods: {
    ...mapActions({
      getRequestDetails: "request/getRequestDetails",
      cancelRequestAction: 'request/cancelRequest',
      getPaidRequestAllowedBonuses: "request/getPaidRequestAllowedBonuses",
      checkRequestSumIsVerified: "request/checkRequestSumIsVerified",
      sendMessage: "request/addMessage",
    }),

    getRequest() {
      this.isLoadingMessages = false;

      this.getRequestDetails(this.requestId)
        .then((result) => {
          this.messageIDs = result.Messages;
          this.templateMessageFiles = [...result.Files];
          this.info = {
            ...result,
            CanClose: !result.IsClosed,
          };
          this.isLoadingMessages = true;
          this.scrollToBottom();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // TODO: Вынести в геттер vuex!
    getFile(fileID) {
      requestApi.getFile(fileID)
        .then((response) => {
          const file = this.templateMessageFiles.find((el) => el.ID === fileID);

          if (file.Name) {
            download(response.data, file.Name, response.headers["content-type"]);
          } else {
            this.$notify.error("Ошибка названия файла");
          }
        });
    },

    addMessage() {
      if (this.form.message.length > 0) {
        this.isSendingMessage = true;

        this.sendMessage({
          RequestId: this.requestId,
          Text: this.form.message,
        })
          .then(() => {
            this.form.message = "";
            this.getRequest();
            this.$root.$emit('requests:sent-message')
          })
          .finally(() => {
            this.isSendingMessage = false;
          });
      }
    },

    // TODO: Вынести в экшен vuex!
    addFile(files) {
      let formData = new FormData();
      formData.append("requestId", this.requestId);
      formData.append("file", files[0]);

      requestApi
        .addFile(formData)
        .then(this.getRequest)
        .catch(() => {
          this.$notify.error("Ошибка загрузки файла");
        });
    },

    /**
     * Закрытие выполненной (или нет) заявки
     */
    closeRequest() {
      this.closeRequestModal(
        this.requestId,
        this.info.RequestNumber
      );
    },

    /**
     * Отмена (невыполненной) заявки
     */
    cancelRequest() {
      this.openDialog({
        title: 'Отмена заявки',
        text: `Вы уверены, что ходите отменить заявку №${this.info.RequestNumber}`,
        buttons: [
          {
            type: 'additional',
            title: 'Отмена',
            handler: () => {
              this.cancelDialog();
            }
          },
          {
            type: 'base',
            title: 'Подтвердить',
            handler: () => {
              this.$modal.hide('dialog');
              this.cancelRequestAction({
                RequestID: this.requestId
              })
                .then((response) => {
                  if (response.Error) {
                    this.$notify.error({
                      position: 'top-right',
                      title: 'Ошибка',
                      message: response.Error
                    })
                  } else {
                    this.$notify.success({
                      position: 'top-left',
                      title: 'Успех',
                      message: `Заявка №${this.info.RequestNumber} отменена.`
                    });

                    this.getRequest();
                  }
                })
                .catch((error) => {
                  throw error
                });
            }
          }
        ]
      });
    },

    checkSumIsVerified() {
      if (this.config && this.config.useBonusSystem) {
        this.getPaidRequestAllowedBonuses(this.requestId)
          .then((result) => {
            this.info.BonusAmount = result.BonusAmount;
          }
        );
      }

      this.checkRequestSumIsVerified(this.requestId)
        .then((result) => {
          if (result) {
            this.openPayRequestModal(this.info);
            this.$emit('request-chat:open-pay-modal');
          }
        }
      );
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.chatContainer) {
          const $container = this.$refs.chatContainer;
          $container.$el.scrollTop = $container.$el.scrollHeight;
        }
      });
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables'
@import '../../../sass/mixins'

.request-chat
  +Size(100%)
  display: flex
  flex:
    direction: column
    basis: 100%

  .message
    +Size(90%, auto)
    max-width: 400px
    background-color: $cElmLight
    border-radius: 16px 16px 16px 4px
    padding: 12px

    @media screen and (min-width: 992px)
      padding: 16px

  .message__header
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 0 10px

    @media screen and (min-width: 992px)
      margin-bottom: 14px

  .message__name
    +TextSubMain()
    margin: 0

  .message__date
    +TextMini()
    flex-shrink: 0

  .message__text
    +TextMain()
    white-space: pre-line
    word-wrap: break-word
    overflow: visible
    margin-bottom: 0

    &--file
      +TextMain($cAccentBlue)
      display: inline-flex
      align-items: center

      &:hover
        text-decoration: underline
        cursor: pointer
    
  .message__download-icon
    margin-right: 8px

.request-chat__chat-wrapper
  flex:
    shrink: 1
    basis: 100%
  padding: 0 24px

  @media screen and (min-width: 1200px)
    padding: 0 60px

.request-chat__date
  +Size(100%, auto)
  +TextMini()
  text-align: center
  display: inline-block
  margin-bottom: 16px

.request-chat__message
  display: flex
  margin: 0 0 16px

  @media screen and (min-width: 992px)
    margin-bottom: 24px

  &--user
    justify-content: flex-end

    .message
      background-color: $cBgSuccess

.request-chat__pane
  flex-shrink: 0
  display: flex
  justify-content: space-between
  align-items: center
  padding: 12px 24px

  @media screen and (min-width: 1200px)
    padding: 16px 60px

.request-chat__button
  width: 100%
  margin: 0 calc(16px / 2)

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0

.request-chat__form
  flex-shrink: 0
  display: flex
  justify-content: space-between
  align-items: center
  background: $cBgSec
  border-top: 1px solid $cBorder
  padding: 16px 24px

  @media screen and (min-width: 1200px)
    padding: 16px 60px

.request-chat__attach:last-of-type
  margin-bottom: 0

.request-chat__attach-icon
  +Size(24px)
  +Transition((color))
  flex-shrink: 0
  color: $cIconNeutral
  cursor: pointer
  margin-right: 12px

  &:hover, &:focus
    color: $cTextMain

  @media screen and (min-width: 992px)
    margin-right: 17px

.request-chat__input
  +Size(100%, $sHug)
  flex-shrink: 1
  background-color: $cBgMain
  border: 1px solid $cBorder
  border-radius: 16px
  padding: 18px 16px

.request-chat__send
  +ButtonIcon()
  flex-shrink: 0
  margin-left: 12px

  @media screen and (min-width: 992px)
    margin-left: 17px

.request-chat__send-icon
  transform: rotate(45deg)
  color: $cIconNeutral
</style>