<template>
  <div 
    v-if="accounts"
    :class="[
      'requests-page',
      (!accounts.length) ? 'requests-page--no-accounts' : false
    ]"
  >
    <template v-if="accounts.length">
      <div class="requests-page__column--left" ref="list">
        <page-header 
          title="Заявки" 
          ref="title"
          class="requests-page__header"
        >
          <template #right-content>
            <div
              v-show="requests.length" 
              class="requests-page__toggler"
            >
              Закрытые 
              <toggler 
                v-if="filteredRequests.length"
                v-model="showClosed" 
              />
            </div>
          </template>
        </page-header>

        <section 
          ref="buttonSection"
          class="requests-page__new" 
        >
          <base-button
            styleClass="text"
            @click="newRequest"
          >
            + Создать новую заявку
          </base-button>
        </section>

        <requests-list 
          v-loading="isLoadingRequests"
          :items="filteredRequests" 
          ref="requestsList"
          @request-list:request-canceled="getRequestsList"
          @request-list:open-request="openRequest"
        />
      </div>
      <div 
        ref="chat"
        :class="[
          'requests-page__column--right',
          { 'requests-page__column--placeholder': !this.requestID }
        ]" 
      >
        <!-- Chat. Desktop -->
        <request-chat 
          v-if="!isTabletView() && requestID"
          :requestId="+requestID"
        />
      </div>
    </template>

    <template v-else>
      <page-header title="Заявки" />

      <section class="requests-page__add-account">
        <add-account-ident text="создать заявку" />
      </section>
    </template>

    <!-- Modals -->
    <!-- Chat. Mobile -->
    <modal
      name="requestChatModal"
      :addaptive="true"
      height="100%"
      :scrollable="true"
      class="request-page__modal"
    >
      <modal-wrapper
        :title="`Заявка №${requestNumber}`"
        :chatMode="true"
        class="request-chat"
        @close="closeRequest"
        @request-chat:open-pay-modal="closeRequest"
      >
        <!-- Chat -->
        <request-chat 
          v-if="requestID"
          :requestId="+requestID"
        />
      </modal-wrapper>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddAccountIdent from '@/components/pages/common/AddAccountIdent';
import PageHeader from '@/components/pages/common/PageHeader';
import Toggler from '@/components/ui/Toggler';
import BaseButton from '@/components/ui/BaseButton';
import RequestsList from '@/components/pages/requests/RequestsList';
import RequestChat from '@/components/pages/requests/RequestChat';
import ModalWrapper from '@/components/ui/Modal';

export default {
  name: "Requests",

  components: { 
    PageHeader,
    Toggler,
    BaseButton,
    RequestsList,
    RequestChat,
    ModalWrapper,
    AddAccountIdent
  },

  props: {
    requestID: null,
  },

  data() {
    return {
      windowWidth: 0,
      messageIDs: [],
      selectedRequestID: this.requestID,
      selectedRequestInfo: null,
      templateMessageFiles: [],
      timer: null,
      showDialogModal: false,
      showClosed: false,
      isLoadingRequests: false,
    };
  },

  computed: {
    ...mapGetters({
      getRequestsGetter: 'request/getRequests',
      getRequestById: 'request/getRequestById',
      getMenuVisibilitySettings: 'config/getMenuVisibilitySettings',
      accounts: 'account/getAccounts',
    }),

    requests() {
      return this.getRequestsGetter();
    },

    filteredRequests() {
      return this.requests.filter((request) => {
        if (this.showClosed) {
          return request.IsClosed;
        }

        return !request.IsClosed;
      });
    },

    selectedRequestHeader() {
      return `Заявка № ${this.selectedRequestInfo.number} ${
        this.selectedRequestInfo.paidRequestStatus
          ? this.selectedRequestInfo.paidRequestStatus
          : ""
      }`;
    },

    requestNumber() {
      return this.getRequestById(this.requestID).RequestNumber;
    },
  },

  created() {
    // console.log('--- Requests page ---');
    this.$root.$on("requests:new-request", this.getRequestsList);
    this.$root.$on("paid-request", this.getRequestsList);
    this.$root.$on("requests:sent-message", this.getRequestsList);
    this.$root.$on("updated-account-info", this.onAccountInfoChanged);
    this.$root.$on("deleted-account-info", this.onAccountInfoChanged);
    this.$root.$on('requests:closed-request', this.getRequestsList);

    this.resizeThrottlerFunctionList.push(
      this.setContainerHeight,
      this.calcWindowWidth
    );
  },

  mounted() {
    // let requestId = this.requestID;

    this.getAccountInfo();
    this.calcWindowWidth();
    this.setContainerHeight();

    // this.getRequests(requestId);

    let target = this;
    this.timer = setInterval(() => {
      target.update();
    }, 30000);

    // this.initPerfectScroll();
    // setTimeout(this.showPaymentResult, 1000);
  },

  destroyed() {
    window.removeEventListener("resize", this.calcWindowWidth);

    this.$root.$off("requests:new-request");
    this.$root.$off("updated-account-info");
    this.$root.$off("deleted-account-info");
    this.$root.$off('requests:closed-request');

    clearInterval(this.timer);
  },

  methods: {
    ...mapActions({
      getUserInfo: "account/getUserInfo",
      refreshRequest: "request/update",
      getRequestsList: "request/getRequestsList",
      cancelRequest: "request/CancelRequest"
    }),

    calcWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    onAccountInfoChanged() {
      this.getAccountInfo();
      this.getRequests();
    },

    getAccountInfo() {
      this.isLoadingAccounts = true;

      this.getUserInfo()
        .finally(() => {
          this.isLoadingAccounts = false;
        });
    },

    update() {
      this.refreshRequest(this.requestID)
        .then((result) => {
          if (result.current) {
            // Add new messages
            this.$root.$emit('requests:update');
          }
        });
    },

    getRequests(selectId = null) {
      this.isLoadingRequests = true;

      this.messageIDs = [];
      this.selectedRequestID = null;

      this.getRequestsList()
        .then(() => {
          if (selectId) {
            this.selectedRequestID = selectId;
            if (this.isTabletView()) {
              this.showDialogModal = true;
            }
            return;
          }
          if (this.filteredRequests.length) {
            this.$router.push({
              name: "Requests",
              params: { requestID: this.filteredRequests[0].ID },
            });
          }
        })
        .finally(() => {
          this.isLoadingRequests = false;
        });
    },

    scrollToBottom() {
      try {
        this.$refs.dialogListScroll.scrollTop = this.$refs.dialogListScroll.scrollHeight;
      } catch (e) {
        console.log(e);
      }
    },

    showPaymentResult() {
      if (location.hash.match(/paymentSuccess$/)) {
        this.$notify.success({
          title: "Оплата",
          message: "Оплата прошла успешно",
          duration: 6000,
          offset: 100,
        });
      }

      if (location.hash.match(/paymentFail$/)) {
        this.$notify.error({
          title: "Оплата",
          message: "Оплата не прошла",
          duration: 6000,
          offset: 100,
        });
      }
    },

    openRequest() {
      if (this.isTabletView()) {
        this.$modal.show('requestChatModal');
      }
    },

    closeRequest() {
      this.selectedRequestID = null;
      this.$router.push({
        name: "Requests",
      });
      this.$modal.hide('requestChatModal');
    },

    setContainerHeight() {
      // console.log('--- Reqests page -> setContainerHeight method ---')
      this.$nextTick(() => {
        const $height = this.$parent.$refs.header;
        const $footer = this.$parent.$refs.footer;
        const $chat = this.$refs.chat;
        const $list = this.$refs.list;

        if (typeof $height != 'undefined' && typeof $footer != 'undefined') {
          let interval = setInterval(() => {
            if ($chat && $list) {
              // const $main = this.$parent.$refs.main;
              const headerHeight = $height.$el.offsetHeight;
              const footerHeight = $footer.$el.offsetHeight;
              const viewportHeight = Math.floor(window.visualViewport.height);

              const mainHeight = viewportHeight - (headerHeight + footerHeight);

              // $main.style.maxHeight = `${mainHeight}px`;
              // $main.style.overflowX = `hidden`;

              $chat.style.height = `${mainHeight}px`;
              $list.style.maxHeight = `${mainHeight}px`;

              const $requestsList = this.$refs.requestsList;
              const titleHeight = this.$refs.title.$el.offsetHeight;
              const buttonSectionHeight = this.$refs.buttonSection.offsetHeight;
              const requestsListHeight = mainHeight - (titleHeight + buttonSectionHeight);

              $requestsList.$refs.scrollSection.$el.style.maxHeight = `${requestsListHeight}px`;
              clearInterval(interval)
            }
          }, 300);
        }
      });
    },

    newRequest() {
      if (this.authToken) {
        this.openNewRequestModal();
        return;
      }

      this.$router.push({ name: 'NewRequest' });
    }
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.requests-page
  display: flex
  height: 100%
  align-items: stretch

  &--no-accounts
    display: block

  .request-chat
    height: 100%
    max-height: 100%

.requests-page__header
  padding:
    left: 16px
    right: 16px

  @media screen and (min-width: 768px)
    padding:
      left: 24px
      right: 24px

  @media screen and (min-width: 1200px)
    padding:
      left: 80px
      right: 24px

  ::v-deep .page-header__title
    width: auto

.requests-page__column
  &--left
    display: flex
    flex:
      direction: column
      grow: 0
      shrink: 0
      basis: 100%

  &--right
    display: none
    flex:
      grow: 0
      shrink: 1
      basis: 100%
    border-left: 1px solid $cBorder

  &--placeholder
    background: 
      image: url('../../public/img/static/request-chat-placeholder.svg')
      repeat: no-repeat
      position: center

  @media screen and (min-width: 1200px)
    &--left
      max-width: 50%
      // padding-right: 24px

    &--right
      display: flex

  @media screen and (min-width: 1400px)
    &--left
      max-width: 592px

.requests-page__toggler
  +TextMain()
  display: flex
  align-items: center
  flex-shrink: 0

  .toggler
    margin-left: 16px

.requests-page__new
  padding: 16px
  border-width: 1px 0 1px 0
  border-style: solid
  border-color: $cBorder

  @media screen and (min-width: 768px)
    padding: 16px 24px

.requests-page__new-request
  +Button('text')
  display: inline-flex
  padding: 0 24px
</style>