<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M1 4H6C6.55228 4 7 4.44772 7 5V9C7 9.55228 6.55228 10 6 10H1" 
      :stroke="fill" 
      stroke-width="2" 
      stroke-linecap="round"
    />
    <path 
      d="M8.31297 8C13.3661 8 15.8263 11.3518 16.0229 15.822C16.0659 16.8004 14.7706 17.0382 14.1508 16.28C10.0188 11.2251 5.79215 17.2995 9.31297 19.5" 
      :stroke="fill" 
      stroke-width="2" 
      stroke-linecap="round"
    />
    <path 
      d="M8 5C13.5 5 21 5.5 22.5 12" 
      :stroke="fill" 
      stroke-width="2" 
      stroke-linecap="round"
    />
    <path 
      d="M8 5C13.5 5 21 5.5 22.5 12" 
      :stroke="fill" 
      stroke-width="2" 
      stroke-linecap="round"
    />
    <path 
      d="M20.7814 22.6916C18.8168 23.3141 18.5874 21.2673 18.5994 18.9437C18.604 18.0529 19.7152 17.6064 20.2965 18.2816C21.7339 19.9514 22.9646 21.9997 20.7814 22.6916Z" 
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'WastewaterIcon',

  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>
