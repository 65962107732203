export default {
  created() {
    this.resizeThrottlerFunctionList.push(
      this.isTabletView
    );
  },

  methods: {
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },

    isMobileView() {
      return window.matchMedia('screen and (max-width: 991px)').matches
    },

    isTabletView() {
      return window.matchMedia('screen and (max-width: 1199px)').matches
    }
  }
}