import * as authService from "../services/auth.service";
// import router from "../router";
import store from "../store/index";

const promiseGetMobileAppSettings = store.dispatch(
  "config/getMobileAppSettings"
);

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to, from, next) {
  // console.log('--- checkAccessMiddleware ---')
  const url = new URL(window.location);
  const token = url.searchParams.get('token');

  if (!token) {
    const isAuthRoute = to.matched.some((item) => item.meta.isAuth === true);

    if (isAuthRoute && !authService.isAuthUser()) {
      return next({ name: "Login" });
    }

    return next();
  } else {
    console.log('to:', to)
    if (to.meta.accessToHeadless) {
      const authToken = store.getters['account/getAuthToken'];

      if (to.name !== 'Auth' && !authToken) {
        return next({ name: 'Auth' });
      }

      return next();
    } else {
      if (to.name === 'Home') {
        return next({ name: 'Requests' });
      }
    }
  }
}

// export function redirectToHomeMiddleware(to, from, next) {
//   if (authService.isAuthUser() && to.path === "/") {
//     let availableRoutes = router.options.routes.filter((route) => {
//       return route.meta.isAuth === true;
//     });

//     if (availableRoutes.length) {
//       return next({ name: "Payment" });
//     }
//   }

//   return next();
// }

export async function checkMobileSettingsMiddleware(to, from, next) {
  await promiseGetMobileAppSettings;
  const menuVisibilitySettings =
    store.getters["config/getMenuVisibilitySettings"];

  if (
    typeof menuVisibilitySettings[to.name] === "boolean" &&
    !menuVisibilitySettings[to.name]
  ) {
    if (from.name == "Home") {
      return next(false);
    } else {
      return next({ name: "Home" });
    }
  }

  return next();
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title);

  if (pageTitle) window.document.title = pageTitle.meta.title;
  next();
}

export function preloaderStart(_, __, next) {
  next();
}

export function preloaderStop() {
  const $preloader = document.querySelector('.app-preloader');

  setTimeout(() => {
    $preloader.style = 'opacity: 0';
    setTimeout(() => {
      $preloader.style = 'display: none';
    }, 300)
  }, 1000);
}
