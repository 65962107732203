import { render, staticRenderFns } from "./FormBoxInput.vue?vue&type=template&id=31df99d6&scoped=true&"
import script from "./FormBoxInput.vue?vue&type=script&lang=js&"
export * from "./FormBoxInput.vue?vue&type=script&lang=js&"
import style0 from "./FormBoxInput.vue?vue&type=style&index=0&id=31df99d6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31df99d6",
  null
  
)

export default component.exports