<template>
  <div class="form-box__field">
    <div 
      :class="[
        'field',
        'field--radio',
        { 'field--error': hasError },
        { 'field--selected': value === radioValue },
        { 'field--active': isActiveState },
        { 'field--focus': fieldIsFocus },
        { 'field--disabled': disabled },
        ...modificators
      ]"
      tabindex="0"
      @focus="fieldIsFocus = true"
      @blur="fieldIsFocus = false"
    >
      <div 
        class="field__group"
        @mousedown="isActiveState = (!disabled) ? true : false"
        @mouseup="isActiveState = false"
      >
        <span 
          class="field__radio"
          :class="{'field__radio--selected': value === radioValue}"
          @click="[
            $emit('input', radioValue),
            fieldIsFocus = true,
            fieldIsFocus = false
          ]"
        >
        </span>
        <label 
          class="field__label" 
          @click="[
            $emit('input', radioValue),
            fieldIsFocus = true,
            fieldIsFocus = false
          ]"
        >
          <slot>
            {{ radioLable }}  
          </slot>
        </label>
      </div>
    </div>

    <p 
      v-show="hasError"
      class="field__message field__message--error"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FormBoxRadio',

  props: {
    value: {
      type: [ String, Number ],
      required: true
    },
    radioValue: {
      type: [ String, Number ],
      required: true
    },
    radioLable: {
      type: String,
      required: true
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    classModificators: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      requred: false,
      default: false
    }
  },

  data() {
    return {
      fieldIsFocus: false,
      isActiveState: false
    }
  },

  computed: {
    modificators() {
      return this.classModificators.map((item) => `field--${item}`);
    }
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/input'
</style>