<template>
  <div 
    :class="[
      'form-box__radioset',
      { 'form-box__radioset--error': hasError },
      ...modificators
    ]"
  >
    <label 
      v-if="label"
      class="form-box__radioset-label"
    >
      {{ label }}:
    </label>
    <div class="form-box__radioset-group">
      <template v-for="(item, i) in list">
        <form-box-radio
          :key="`radio-${i}`"
          v-model="v"
          :radioLable="item.title"
          :radioValue="item.value"
          :classModificators="['from-radioset']"
          @input="$emit('input', v)"
        >
          <slot name="label" :item="item">
            {{ item.title }}
          </slot>
        </form-box-radio>
      </template>
    </div>

    <p 
      v-show="hasError"
      class="field__message field__message--error"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import FormBoxRadio from '@/components/form/FormBoxRadio'

export default {
  name: 'FormBoxRadioset',

  components: {
    FormBoxRadio
  },

  props: {
    value: {
      type: [ String, Number ],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    list: {
      type: Array,
      required: true
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    classModificators: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      v: this.value
    }
  },

  computed: {
    modificators() {
      return this.classModificators.map((item) => `form-box__radioset--${item}`);
    }
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/input'
</style>