<template>
  <div 
    v-if="content.data"
    class="history"
  >
    <!-- Accounts Selector -->
    <div 
      v-if="content.data.accounts"
      class="history__ident-selector form-box"
    >
      <form-box-select
        v-model="selectedIdent"
        label="Лицевой счёт"
        :list="content.data.accounts"
        valuePropName="Ident"
        keyPropName="Ident"
        class="ident-selector"
        @input="selectIdent"
      >
        <template #selectedOption="{ item }">
          (Л/с: {{ item.Ident }}) {{ item.Address }}
        </template>
        <template 
          v-if="content.data.accounts.length > 1"
          #customOption
        >
          <li
            class="ident-selector__select-all"
            @click="selectedIdent = null"
          >
            Выбрать все ({{ content.data.accounts.length }})
          </li>
        </template>
      </form-box-select>
    </div>

    <!-- Togglers -->
    <div class="history__togglers">
      <div class="history__toggler">
        <label class="history__toggler-label">ЖКУ</label>
        <toggler
          v-model="showAccountTable"
        />
      </div>
      <div class="history__toggler">
        <label class="history__toggler-label">Страхование</label>
        <toggler
          v-model="showInsuranceTable"
        />
      </div>
    </div>

    <!-- History Tables -->
    <div class="history__tables">
      <div
        v-show="showAccountTable" 
        class="history__table"
      >
        <p class="history__table-title">ЖКУ</p>
        <base-table
          v-if="accountTable"
          :header="accountTable.header"
          :body="accountTable.body"
        />
      </div>
      <div 
        v-show="showInsuranceTable" 
        class="history__table"
      >
        <p class="history__table-title">Страхование</p>
        <base-table
          v-if="insuranceTable"
          :header="insuranceTable.header"
          :body="insuranceTable.body"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as commonApi from "@/api/common";
import download from "downloadjs";
import FormBoxSelect from '@/components/form/FormBoxSelect';
import BaseTable from '@/components/ui/BaseTable';
import Toggler from '@/components/ui/Toggler';

export default {
  name: 'History',

  components: {
    FormBoxSelect,
    BaseTable,
    Toggler
  },

  props: {
    content: {
      type: [ Array, Object ],
      required: true
    }
  },

  data() {
    return {
      selectedIdent: null,
      showAccountTable: true,
      showInsuranceTable: true
    }
  },

  computed: {
    accountTable() {
      const pendingPayments = this.content.data.pendingPayments;
      const payments = this.content.data.payments;

      if (this.content.data.pendingPayments || this.content.data.payments) {
        return {
          header: [
            {
              title: 'Дата',
              width: `50%`
            },
            {
              title: 'Сумма',
              align: 'center',
              width: `50%`
            },
            {
              title: '',
            }
          ],
          body: [
            ...(() => {
              let result = [];

              Object.keys(pendingPayments).forEach((i) => {
                if (pendingPayments[i].length > 0) {
                  if (!this.selectedIdent || (this.selectedIdent && i === this.selectedIdent)) {
                    pendingPayments[i].map((row) => {
                      result.push([
                        {
                          value: `${row.Date} (В обработке)`,
                        },
                        {
                          value: `${row.Sum} ₽`,
                          align: 'center'
                        },
                        (row.HasCheck ? 
                          {
                            value: '',
                            align: 'center',
                            action: {
                              type: 'download',
                              events: { click: () => this.downloadFile(this.getCheckLink(row)) }
                            }
                          } : 
                          { value: '' }
                        )
                      ])
                    });
                  }
                }
              });

              return result
            })(),
            ...(() => {
              let result = [];

              Object.keys(payments).forEach((i) => {
                if (payments[i].length > 0) {
                  if (!this.selectedIdent || (this.selectedIdent && i === this.selectedIdent)) {
                    payments[i].map((row) => {
                      result.push([
                        {
                          value: row.Date,
                        },
                        {
                          value: `${row.Sum} ₽`,
                          align: 'center'
                        },
                        (row.HasCheck ? 
                          {
                            value: '',
                            align: 'center',
                            action: {
                              type: 'download',
                              events: { click: () => this.downloadFile(row.ID) }
                            }
                          } : 
                          { value: '' }
                        )
                      ]);
                    });
                  }
                }
              })

              return result
            })(),
          ]
        }
      }

      return null;
    },

    insuranceTable() {
      if (this.content.data.insurances) {
        let checkCounter = 0;

        const body = (() => {
          const insurances = this.content.data.insurances;
          const keys = Object.keys(insurances);
          let result = [];

          keys.forEach((i) => {
            if (insurances[i].length > 0) {
              if (!this.selectedIdent || (this.selectedIdent && i === this.selectedIdent)) {
                insurances[i].map((row) => {
                  if (row.HasCheck) {
                    checkCounter++;
                  }

                  result.push([
                    {
                      value: row.Date,
                    },
                    {
                      value: `${row.Sum} ₽`,
                      align: 'center'
                    },
                    (row.HasCheck ? 
                      {
                        value: '',
                        align: 'center',
                        action: {
                          type: 'download',
                          events: { click: () => this.downloadFile(row.ID) }
                        }
                      } : 
                      null
                    )
                  ])
                });
              }
            }
          })

          return result
        })();

        const header = [
          {
            title: 'Дата',
            width: '50%',
          },
          {
            title: 'Сумма',
            align: 'center',
            width: '50%',
          },
          (checkCounter > 0 ? { title: '' } : null),
        ];

        return {
          header: header,
          body: body
        }
      }

      return null;
    },
  },

  watch: {
    showAccountTable(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!newVal && !this.showInsuranceTable) {
          this.showInsuranceTable = true;
        }
      }
    },

    showInsuranceTable(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!newVal && !this.showAccountTable) {
          this.showAccountTable = true;
        }
      }
    }
  },

  methods: {
    selectIdent(e) {
      this.selectedIdent = e;
    },

    downloadFile(id) {
      // console.log('--- downloadFile method ---')
      // console.log('id:', id)
      const src = commonApi.getCheckDownloadLink(id);
      // console.log('src:', src)
      download(src);
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../sass/variables"
@import "../../../sass/mixins"

.history
  .ident-selector__select-all
    +TextMini($cAccentBlue)
    +Transition((color))
    text-align: center
    border-top: 1px solid $cBorder
    padding: 12px 16px

    &:hover
      color: $cAccent
      cursor: pointer

.history__ident-selector,
.history__togglers
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    margin-bottom: 24px

.history__togglers
  display: flex

.history__toggler
  display: inline-flex
  align-items: center
  padding: 0 16px
  margin: 0 calc(16px / 2)

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0

  @media screen and (min-width: 768px)
    padding: 0 24px
    margin: 0 calc(24px / 2)

.history__toggler-label
  +TextMain($cTextMain)
  margin-right: 16px
  margin-bottom: 0
  
.history__table
  background-color: $cBgMain
  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
  border-radius: 16px
  padding: 16px

  &:not(:last-child)
    margin-bottom: 16px

  @media screen and (min-width: 768px)
    padding: 16px 24px

    &:not(:last-child)
      margin-bottom: 24px

.history__table-title
  +TextMain($cTextMain)
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    margin-bottom: 24px

.history__title
  +TextMain($cTextMain)
  margin-bottom: 24px
</style>