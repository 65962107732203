var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('accordion',{staticClass:"meters-accordion",attrs:{"items":_vm.items},on:{"sm-accordion:toggle-item":_vm.toggleItem},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"header"},[_c('div',{staticClass:"header__column header__column--left"},[_c('div',{staticClass:"header__icon-box"},[_c('ui-icons',{staticClass:"header__icon",attrs:{"iconName":_vm.getIconName(item.Resource),"iconColor":_vm.getIconColor(item.Resource),"width":"24","height":"24"}})],1),_c('div',{staticClass:"header__info"},[_c('h3',{staticClass:"header__title"},[_vm._v(" "+_vm._s(item.Resource)+" ")]),_c('p',{staticClass:"header__subtitle"},[_vm._v(" "+_vm._s(item.CustomName || item.Name)+" ")])])]),_c('div',{staticClass:"header__column header__column--right"},[(item.AutoValueGettingOnly)?[_c('badge',{attrs:{"caption":"Автоматическое снятие","type":"success"}})]:(item.IsDisabled)?[_c('badge',{attrs:{"caption":"Счетчик выбыл","type":"danger"}})]:[(item.Values.length && 
              item.Values[0].IsCurrentPeriod)?[_c('badge',{attrs:{"caption":"Показания переданы","type":"success"}})]:_vm._e(),(item.Values.length && 
              !item.Values[0].IsCurrentPeriod && 
              !item.Values[0].IsAccepted)?[_c('badge',{attrs:{"caption":"Показания не переданы","type":"info"}})]:_vm._e()]],2)])]}},{key:"content",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('div',{staticClass:"meter-statistic"},[_c('div',{staticClass:"meter-statistic__header"},[_c('div',{staticClass:"meter-statistic__value"},[_c('span',{staticClass:"meter-statistic__value-title"},[_vm._v("Заводской №:")]),_c('span',{staticClass:"meter-statistic__value-data"},[_vm._v(" "+_vm._s(item.FactoryNumber)+" ")])]),_c('div',{staticClass:"meter-statistic__value"},[_c('span',{staticClass:"meter-statistic__value-title"},[_vm._v("Следующая поверка:")]),_c('span',{staticClass:"meter-statistic__value-data"},[_vm._v(" "+_vm._s(item.NextCheckupDate || '-')+" ")])]),_c('div',{staticClass:"meter-statistic__value"},[_c('span',{staticClass:"meter-statistic__value-title"},[_vm._v(" Межповерочный интервал: ")]),_c('span',{staticClass:"meter-statistic__value-data"},[_vm._v(" "+_vm._s(item.RecheckInterval)+" лет ")])])]),_c('div',{staticClass:"meter-statistic__body"},[(!item.AutoValueGettingOnly)?_c('form',{staticClass:"meter-statistic__form"},[_c('p',{staticClass:"meter-statistic__form-title"},[_vm._v("Показания прибора учёта")]),_c('p',{staticClass:"meter-statistic__form-previous-value"},[_vm._v(" Предыдущие показания: "+_vm._s(_vm.getPrevValues(item.ID))+" ")]),(item.ValuesCanAdd)?_c('div',{staticClass:"meter-statistic__form-fields"},[_vm._l((item.TariffNumberInt),function(i){return [_c('div',{key:i,staticClass:"meter-statistic__form-field"},[_c('form-box-input',{attrs:{"refName":("MeterControl" + (item.ID) + index + i),"label":(item.TariffNumberInt < 2) ? 'Показания' : ("Показания " + i)},model:{value:(_vm.values[i]),callback:function ($$v) {_vm.$set(_vm.values, i, $$v)},expression:"values[i]"}}),(item.TariffNumberInt > 1)?_c('span',{staticClass:"meter-statistic__form-caption"},[_vm._v(" За "+_vm._s(item.Values[0].Period)+" ")]):_vm._e()],1)]}),((!item.AutoValueGettingOnly &&
                !item.IsDisabled &&
                _vm.getDiffCheckupDate(item.NextCheckupDate) > 0) && 
                (item.Values.length &&
                item.Values[0].IsCurrentPeriod))?_c('trash-icon',{staticClass:"meter-statistic__remove",attrs:{"style-class":"secondary","disabled":(item.AutoValueGettingOnly),"title":"Удалить показания"},on:{"click":function($event){return _vm.removeValue(item.ID)}}}):_vm._e()],2):_vm._e()]):_c('div',{staticClass:"meter-statictic__auto-data"},[_c('div',{staticClass:"auto-data"},[_c('alert',{attrs:{"caption":"Показания передаются автоматически","type":"info"}}),(item.Values)?_c('div',{staticClass:"auto-data__table"},[_c('div',{staticClass:"table"},[_vm._l((_vm.convertValues(item)),function(col,i){return [(!!col)?_c('div',{key:("table-column-" + i),class:[
                      'table__column' ].concat( ((col.modificators) ? 
                        col.modificators.map(function (mod) { return (mod) ? ("table__column--" + mod) : null; }) : 
                        null
                      )
                    )},[_vm._l((col.values),function(row,j){return [_c('span',{key:("table-row-" + j),class:[
                          'table__row' ].concat( ((row.modificators) ? 
                            row.modificators.map(function (mod) { return (mod) ? ("table__row--" + mod) : null; }) : 
                            null
                          ),
                          [{ 'table__row--empty': !row.value }]
                        )},[(row.button)?[_c('button',_vm._g({staticClass:"table__button"},row.button.events),[_vm._v(" "+_vm._s(row.button.title)+" ")])]:[_vm._v(" "+_vm._s((row.value) ? row.value : '...')+" ")]],2)]})],2):_vm._e()]})],2)]):_vm._e()],1)]),_c('div',{staticClass:"meter-statistic__history"},[_c('div',{staticClass:"history"},[_c('base-button',{staticClass:"history__button",attrs:{"style-class":"secondary-text"},on:{"click":function($event){[_vm.showHistory = !_vm.showHistory, _vm.getHistory(item.ID, item.UniqueNum)]}}},[_c('clock-icon',{staticClass:"history__icon"}),_vm._v(" История показаний "),_c('chevron-down-icon',{class:[
                  'history__chevron',
                  { 'history__chevron--up': _vm.showHistory }
                ]})],1),(_vm.showHistory && _vm.uniqueNumHistory === item.UniqueNum)?_c('base-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingHistory),expression:"loadingHistory"}],staticClass:"history__table",attrs:{"header":_vm.history.header,"body":_vm.history.body,"type":"white"}}):_vm._e()],1)])]),_c('div',{staticClass:"meter-statistic__footer"},[(!item.AutoValueGettingOnly &&
            !item.IsDisabled &&
            _vm.getDiffCheckupDate(item.NextCheckupDate) > 0)?[(item.ValuesCanAdd)?_c('base-button',{staticClass:"meter-statistic__button",attrs:{"style-class":"secondary","disabled":(item.AutoValueGettingOnly)},on:{"click":function($event){return _vm.sendValues(item.ID)}}},[(item.Values.length &&
                item.Values[0].IsCurrentPeriod)?[_vm._v(" Изменить показания ")]:[_vm._v(" Передать показания ")]],2):_c('alert',{attrs:{"caption":item.PeriodMessage,"type":"info"}})]:_vm._e(),(item.AllowMeterVerificationRequest &&
            _vm.getDiffCheckupDate(item.NextCheckupDate) <= 30 &&
            _vm.verificationService)?[_c('base-button',{staticClass:"meter-statistic__button",attrs:{"style-class":"secondary","disabled":_vm.isNotAllowedVerification()},on:{"click":function($event){return _vm.openVerificationConfirmationModal(item.ID)}}},[_vm._v(" Заказать поверку ")])]:_vm._e()],2)])]}}],null,false,2705598979)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }