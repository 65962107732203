import { render, staticRenderFns } from "./RequestChat.vue?vue&type=template&id=6b9b0c64&scoped=true&"
import script from "./RequestChat.vue?vue&type=script&lang=js&"
export * from "./RequestChat.vue?vue&type=script&lang=js&"
import style0 from "./RequestChat.vue?vue&type=style&index=0&id=6b9b0c64&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b9b0c64",
  null
  
)

export default component.exports