<template>
  <div class="form-box__field">
    <div 
      :class="[
        'field',
        'field--select',
        { 'field--error': hasError },
        { 'field--active': showList || fieldIsFocus },
        { 'field--popover': showPopover },
        { 'field--selected-option': value },
      ]"
    >
      <div 
        class="field__group"
        @click="fieldClickHandler"
        @clickout="fieldClickoutHandler"
      >
        <div class="field__control">
          <label class="field__label">
            {{ label }}
          </label>
          <span class="field__selected-option">
            <slot name="selectedOption" :item="selectedOption">
              {{ selectedOption[keyPropName] }}
            </slot>
            <!-- Use example -->
            <!-- <template #selectedOption="{ item }">
              (Л/с: {{ item.Ident }}) {{ item.Address }}
            </template> -->
          </span>
        </div>
        <component 
          :is="chevron" 
          class="field__chevron"
          @click.stop="showList = !showList, fieldIsFocus = !fieldIsFocus"
        />
      </div>

      <div
        v-show="showList"  
        class="field__list-wrapper"
      >
        <perfect-scrollbar 
          tag="ul"
          class="field__list"
          :style="(maxShowOptions > 0) ? `max-height: calc(40px * ${maxShowOptions})` : false"
        >
          <li
            v-for="(item, i) in list"
            :key="`select-option-${i}`" 
            :data-value="item[valuePropName]"
            class="field__option"
            @click="onSelectHandler"
          >
            <slot name="option" :item="item">
              {{ item[keyPropName] }}
            </slot>
            <!-- Use example -->
            <!-- <template #option="{ item }">
              {{ item.Ident }}
            </template> -->
          </li>
          <li
            v-if="allowSelectAll && list.length > 1"
            class="field__option field__option--select-all"
            @click="onSelectAllHandler"
          >
            Выбрать все ({{ list.length }})
          </li>
          <slot name="customOption" />
          <!-- Use example -->
          <!-- <template 
            v-if="accountsCount > 1"
            #customOption
          >
            Выбрать все ({{ accountsCount }})
          </template> -->
        </perfect-scrollbar>
      </div>

      <p
        v-show="hasError && message" 
        class="field__message"
      >
        {{ message }}
      </p>
      <div 
        v-show="showPopover"
        class="field__popover"
      >
        <slot name="popover"></slot>
      </div>
    </div>
    <!-- <select v-model="value">
      <option 
        v-for="(item, i) in list" 
        :key="i" 
        :value="item[valuePropName]"
        :selected="(item[valuePropName] === value) ? 'selected' : ''"
      >
        {{ typeof item[valuePropName] }}
        {{ typeof value }}
        {{item[valuePropName]}}
      </option>
    </select> -->
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@vue-hero-icons/outline";

export default {
  name: 'FormBoxSelect',

  components: {
    ChevronDownIcon, 
    ChevronUpIcon
  },

  props: {
    value: {
      type: [ String, Number ]
    },
    label: {
      type: String,
      required: true
    },
    list: {
      type: [ Array, Object ],
      required: false,
      default() {
        return []
      }
    },
    valuePropName: {
      type: String,
      required: false,
      default: 'key'
    },
    keyPropName: {
      type: String,
      required: false,
      default: 'value'
    },
    // optionsParams: {
    //   type: Object,
    //   required: false,
    //   default() {
    //     return {}
    //   }
    // },
    message: {
      type: String,
      required: false,
      default: ''
    },
    showPopover: {
      type: Boolean,
      required: false,
      default: false
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    allowSelectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    maxShowOptions: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      fieldIsFocus: false,
      showList: false,
    }
  },

  computed: {
    chevron() {
      return `chevron-${this.fieldIsFocus ? 'up' : 'down'}-icon`;
    },

    selectedOption() {
      if (this.value) {
        return this.list.find(item => {
          // TODO: Не строгое сравнение необходимо на случай того, что в массиве
          // число, а value есть строка
          return item[this.valuePropName] == this.value
        });
      }

      return { [this.valuePropName]: null }
    }
  },

  methods: {
    fieldClickHandler() {
      this.openList();
    },

    fieldClickoutHandler() {
      this.closeList();
    },

    onSelectHandler(e) {
    // onSelectHandler() {
      // console.log('this.selectedOption:', this.selectedOption)
      this.$emit('input', e.target.dataset.value);
      // this.$emit('input', this.value);
      this.closeList();
    },

    onSelectAllHandler() {
      // console.log('--- onSelectAllHandler ---')
      this.$emit('input', null);
      this.closeList();
    },

    openList() {
      this.fieldIsFocus = true;
      this.showList = true;
    },

    closeList() {
      this.fieldIsFocus = false;
      this.showList = false;
    }
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/input'
</style>