<template>
  <div class="news-page">
    <page-header 
      title="Новости" 
      :back="{
        title: 'Назад',
        to: { name: 'Home' }
      }"
    />

    <article 
      v-loading="!article"
      class="article"
    >
      <div class="article__header">
        <h3 class="article__title">{{ article.Header }}</h3>
        <time class="article__date">{{ article.Created }}</time>
      </div>

      <div class="article__body">
        <figure
          v-if="article.HasImage"
          class="article__image"
        >
          <img
            :src="article.ImageLink"
            :alt="article.Header"
          />
        </figure>

        <div
          v-html="article.Text"
          class="article__text"
        ></div>
      </div>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/pages/common/PageHeader';

export default {
  name: "News",

  components: {
    PageHeader
  },

  data() {
    return {
      isLoaded: false
    }
  },

  computed: {
    ...mapGetters({
      getNewsById: 'news/getNewsById'
    }),

    newsId() {
      return this.$route.params.id;
    },

    article() {
      if (this.isLoaded) {
        return this.getNewsById(this.newsId);
      }

      return null;
    },
  },

  mounted() {
    this.getNews(this.newsId)
      .then((result) => {
        if (result) {
          this.isLoaded = true;
        }
      });
  },

  methods: {
    ...mapActions({
      getNews: 'news/getNews',
      getImage: 'news/getImage',
    }),
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.article__header
  margin-bottom: 24px

  @media screen and (min-width: 768px)
    margin-bottom: 40px

.article__title
  +TextHeader(0)
  margin-bottom: 8px

  @media screen and (min-width: 768px)
    margin-bottom: 24px

.article__date
  +TextMini()

.article__image
  +ResponsiveImg(100%, 200px)
  float: right
  margin-bottom: 16px
  border: 1px solid $cBorder
  border-radius: 16px

  @media screen and (min-width: 576px)
    +Size(50%, 258px)
    float: right
    margin-bottom: 24px

  @media screen and (min-width: 768px)
    +Size(398px, 258px)
    margin-bottom: 40px

.article__text
  +TextMain()
  margin-bottom: 0
  
  &:after
    clear: both
</style>
