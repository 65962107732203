function clearPhone(source) {
  if (!source) {
    return "";
  }

  let result = "";

  for (let i = 0; i < source.length; i++) {
    let char = source[i];
    if (char >= "0" && char <= "9") {
      result = result + char;
    }
  }

  if (result.length > 0 && result[0] === "8") {
    result = "7" + result.substr(1);
  }

  if (result.length > 0) {
    result = "+" + result;
  }

  return result;
}

export default {
  computed: {
    config() {
      let defaultBaseConfig = {
        color: "00A0E3",
        main_name: "",
      };

      let config = this.$store.getters["config/getMobileAppSettings"];

      return {
        ...defaultBaseConfig,
        ...config,
        clearPhone: clearPhone(config.phone),
        logoLink() {
          var result = window.appSettings.logoLink;
          if (result && result.length > 0) {
            return result;
          }
          return undefined;
        },
      };
    },
  },
  
  methods: {
    resolveUrl(url) {
      return window.appSettings.virtualPath + url;
    },
  },
};
