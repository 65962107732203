import axios from "axios";

export function login({ phone, password }) {
  return axios.post("/auth/login", { phone, password });
}

export function updateProfile({ fio, email, onlyElectronicBills }) {
  return axios.post("/User/UpdateProfile", { fio, email, onlyElectronicBills });
}

export function getRequestAccessCode({ phone }) {
  return axios.post("/Auth/RequestAccessCode", { phone });
}

export function sendCheckCodeSMS({ phone }) {
  return axios.post("/Auth/SendCheckCode", { phone });
}

export function sendCheckCodeFlashCall({ phone, dontCheck }) {
  return axios.post("/Auth/SendCheckCodeFlashCall", { phone, dontCheck });
}

export function getUserInfo() {
  return axios.get("/User/Info");
}

export function getInfo() {
  return axios.get("/Accounting/Info");
}

export function checkAccessCode({ phone, code }) {
  return axios.post("/Auth/CheckAccessCode", { phone, code });
}

export function registerByPhone({ fio, phone, password, code, birthday }) {
  return axios.post("/Auth/RegisterByPhone", {
    fio,
    phone,
    password,
    code,
    birthday,
  });
}

export function deleteAccountByIdent({ ident }) {
  return axios.post("/User/DeleteAccountByIdent", { ident });
}

export function addAccountByIdent({ ident, pinCode, clientBaseID }) {
  return axios.post("/User/AddAccountByIdent", { ident, pinCode, clientBaseID });
}

export function getPayLink({ accountID, sum, payInsurance, paymentSystem, payAll, allowCardSaving, paidRequestId, bonusAmount }) {
  return axios.post("/PayOnline/GetPayLink", {
    accountID,
    payAll,
    sum,
    payInsurance,
    paymentSystem,
    allowCardSaving,
    SuccessUrl: location.href + "?paymentSuccess",
    FailUrl: location.href + "?paymentFail",
    paidRequestId,
    bonusAmount
  });
}

export function getPaymentSystems() {
  return axios.get("/PayOnline/GetPaymentSystemsList");
}

export function getSumWithCommission({ sum, accountID }) {
  return axios.get("/Accounting/SumWithComission", {
    params: { sum, accountID },
  });
}

export function disableAccountInsurance({ accountID }) {
  return axios.post("/Accounting/DisableAccountInsurance", { accountID });
}

export function getAccountPrefixes() {
  return axios.get("/User/AccountPrefixes");
}

export function deleteMobileUser() {
  return axios.post('/User/DeleteMobileUser');
}

export function getClientBases() {
  return axios.get('/User/GetClientBases');
}

export function authByToken(params) {
  return axios.post('/Auth/LoginByToken/', params);
}
