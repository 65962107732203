<template>
  <section 
    v-if="group"
    class="service-category"
  >
    <section-header
      :title="group.name"
    />

    <div
      v-if="group.services.length" 
      class="service-category__list"
    >
      <div
        v-for="(service, i) in group.services" 
        :key="`service-${i}`"
        class="service-category__item"
      >
        <service-card
          :data="service"
          class="service-category__card"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from '@/components/pages/common/SectionHeader';
import ServiceCard from '@/components/pages/services/ServiceCard';

export default {
  name: 'ServiceGroup',

  components: {
    SectionHeader,
    ServiceCard,
  },

  props: {
    group: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
.service-category__list
  display: flex
  flex-wrap: wrap

.service-category__item
  flex-grow: 1
  flex-shrink: 0
  flex-basis: 100%
  max-width: 100%
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    flex-basis: calc(100% / 2)
    max-width: calc(100% / 2)
    padding-left: calc(16px / 2)
    padding-right: calc(16px / 2)
    margin-bottom: 20px

    &:nth-child(1), &:nth-child(2n) + .service-category__item
      padding-left: 0

    &:nth-child(2n)
      padding-right: 0

  @media screen and (min-width: 992px)
    padding-left: calc(20px / 2)
    padding-right: calc(20px / 2)

  @media screen and (min-width: 1200px)
    flex-basis: calc(100% / 4)
    max-width: calc(100% / 4)
    padding-left: calc(16px / 2)
    padding-right: calc(16px / 2)

    &:nth-child(2n) + .service-category__item
      padding-left: calc(16px / 2)

    &:nth-child(2n)
      padding-right: calc(16px / 2)

    &:nth-child(1), &:nth-child(4n) + .service-category__item
      padding-left: 0

    &:nth-child(4n)
      padding-right: 0

  @media screen and (min-width: 1400px)
    padding-left: calc(20px / 2)
    padding-right: calc(20px / 2)

    &:nth-child(2n) + .service-category__item
      padding-left: calc(20px / 2)

    &:nth-child(2n)
      padding-right: calc(20px / 2)
    
    &:nth-child(1), &:nth-child(4n) + .service-category__item
      padding-left: 0

.service-category__card
  height: 100%
</style>