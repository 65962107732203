<template>
  <div class="error-page">
    <span class="error-page__code">404</span>

    <div class="error-page__content">
      <h1 class="error-page__title">
        Страница по данному адресу не существует.
      </h1>

      <router-link
        :to="{ name: 'Home' }"
        class="error-page__link"
      >
        Вернуться
        <template v-if="authToken">к заявкам</template>
        <template v-else>в личный кабинет</template>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notFound'
}
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.error-page
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  height: 100%

.error-page__code
  +Typography(120px, 700, 200px, $cError)

  @media screen and (max-width: 767px)
    font-size: 72px
    line-hegiht: 120px

.error-page__title
  +TextHeader(0)
  text-align: center
  margin-bottom: 24px

  @media screen and (min-width: 768px)
    margin-bottom: 72px

.error-page__link
  +Button('text')
</style>