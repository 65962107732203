<template>
  <div class="announcement">
    <page-header 
      title="Уведомление"
      :back="{
        title: 'Назад',
        to: { name: 'Home' }
      }"
    />

    <article 
      v-loading="!announce"
      class="article"
    >
      <div class="article__header">
        <h3 class="article__title">{{ announce.Header }}</h3>
        <time class="article__date">{{ announce.Created }}</time>
      </div>

      <div class="article__body">
        <figure 
          v-if="announce.HasImage"
          class="article__image"
        >
          <img 
            :src="announce.ImageLink" 
            :alt="announce.Header"
          />
        </figure>

        <div 
          v-html="announce.Text"
          class="article__text"
        ></div>
      </div>

      <div class="article__footer">
        <ul class="article__files-list">
          <li
            v-for="(file, index) in announce.Files"
            :key="file.Name + index"
            class="article__item"
          >
            <a 
              :href="file.Link" 
              class="article__file-link"
            >
              <download-icon/>
              {{ file.Description }}
            </a>
          </li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/pages/common/PageHeader';
import { DownloadIcon } from '@vue-hero-icons/outline'

export default {
  name: "Announcement",

  components: {
    PageHeader,
    DownloadIcon,
  },

  computed: {
    ...mapGetters({
      getAnnouncementById: 'announcement/getAnnouncementById'
    }),

    idAnnounce() {
      return this.$route.params.id;
    },
    announce() {
      return this.getAnnouncementById(this.idAnnounce);
    },
  },

  // watch: {
  //   $route() {
  //     this.getNews();
  //   },
  // },

  mounted() {
    this.getEventBlockData();
  },

  methods: {
    ...mapActions({
      getEventBlockData: 'news/getEventBlockData'
    }),
  },
}
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.article__header
  margin-bottom: 24px

  @media screen and (min-width: 768px)
    margin-bottom: 40px

.article__title
  +TextHeader(0)
  margin-bottom: 8px

  @media screen and (min-width: 768px)
    margin-bottom: 24px

.article__date
  +TextMini()

.article__image
  +ResponsiveImg(100%, 200px)
  float: right
  margin-bottom: 16px
  border: 1px solid $cBorder
  border-radius: 16px

  @media screen and (min-width: 576px)
    +Size(50%, 258px)
    float: right
    margin-bottom: 24px

  @media screen and (min-width: 768px)
    +Size(398px, 258px)
    margin-bottom: 40px

.article__text
  +TextMain()
  margin-bottom: 0
  
  &:after
    clear: both
</style>
