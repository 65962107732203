import * as pollsApi from "@/api/polls";

const state = {
  polls: [],
};

const getters = {
  getNonPassedPolls: ({ polls }) => polls.filter((item) => {
    return (
      item.Questions.filter((question) => question.IsCompleteByUser)
        .length != item.Questions.length
    );
  }),

  getPolls: ({ polls }) => polls,
};

const mutations = {
  setPolls(state, data) {
    data.forEach((poll) => {
      poll.Questions.forEach((item) => {
        const urlRegExp = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*))/;
        item.Text = item.Text.replace(
          urlRegExp,
          "<a target='_blank' class='poll__anchor' href='$1'>$1</a>"
        );
      });
    });
    state.polls = [...data];
  },
  setReadedFlag(state, id) {
    state.polls.find((item) => item.ID == id).IsReaded = true;
  },
};

const actions = {
  saveResult(context, data) {
    return pollsApi.saveResult(data).catch((error) => {
      throw error;
    });
  },
  setReadedFlag({ commit }, id) {
    return pollsApi
      .setReadedFlag(id)
      .then(() => {
        commit("setReadedFlag", id);
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
