<template>
  <div class="log-register">
    <div class="log-register__header">
      <h2 class="log-register__title">{{ content.title }}</h2>
    </div>

    <div class="log-register__form">
      <div class="form-box">
        <form 
          @submit.prevent="login" 
          class="form-box__form"
        >
          <!-- Login -->
          <form-box-input 
            refName="loginControl"
            label="Телефон"
            type="tel"
            placeholder="+7 (999) 999 99 99"
            :message="(errors.login) ? errors.login : ''"
            :focused="loginIsFocus"
            :hasError="errors.form || errors.login"
            v-mask="`+7 (###) ### ## ##`"
            v-model="form.login"
          />

          <!-- Password -->
          <form-box-input 
            refName="passwordControl"
            label="Пароль"
            type="password"
            :message="(errors.password) ? errors.password : ''"
            :focused="passwordIsFocus"
            :hasError="errors.form || errors.password"
            v-model="form.password"
          >
          </form-box-input>

          <base-button
            role="link"
            tabindex="0"
            style-class="text"
            class="form-box__button form-box__button--forgot-password"
            @click="showPopover = !showPopover"
            @focus="showPopover = true"
            @blur="showPopover = false"
          >
            <span class="underline">Забыли пароль?</span>
            <div 
              v-show="showPopover"
              class="form-box__popover"
            >
              <popover
                :closeIcon="false"
                :allowCloseByClickout="true"
                bodyText="Для восстановления пароля пройдите повторную регистрацию"
              />
            </div>
          </base-button>

          <base-button
            type="submit"
            tabindex="0"
            class="form-box__button"
            :disabled="isDisabled"
          >
            Войти
          </base-button>

          <base-button 
            tabindex="0"
            style-class="text"
            class="form-box__button"
            @click="$router.push({ name: 'Register' })" 
          >
            Зарегистрироваться
          </base-button>
        </form>
      </div>
    </div>

    <p class="log-register__note">
      {{ content.note }}
    </p>
  </div>
</template>

<script>
import FormBoxInput from '@/components/form/FormBoxInput';
import Popover from '@/components/ui/Popover';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: "Login",

  components: {
    FormBoxInput,
    Popover,
    BaseButton
  },

  data() {
    return {
      form: {
        login: '',
        password: '',
      },
      content: {
        title: 'Вход в личный кабинет',
        note: 'Если Вы ранее входили по номеру лицевого счета или логину, зарегистрируйтесь по номеру телефона и подключите свой лицевой счет.',
      },
      errors: {
        form: false
      },
      loginIsFocus: false,
      passwordIsFocus: false,
      error: '',
      showPopover: false,
    }
  },

  computed: {
    isDisabled() {
      return (
        this.form.login.length <= 17 || 
        this.form.password.length === 0 ||
        this.errors.length > 0
      )
    }
  },

  methods: {
    login() {
      // console.log('--- login methods ---');
      if (this.isValidate()) {
        this.error = "";
        this.errors['form'] = false;

        this.$store
          .dispatch("account/login", {
            phone: this.form.login.match(/(\d+)/g).join(""),
            password: this.form.password,
          })
          .then(() => {
            this.$router.push({ name: "Home" });
          })
          .catch((error) => {
            this.errors['form'] = true;

            if (error.response && error.response.status === 401) {
              this.error = "Не верная пара логин/пароль";

              this.$notify.error({
                position: "top-left",
                title: "Ошибка",
                message: "Не верная пара логин/пароль",
              });
            } else {
              this.error = "Ошибка.";
              this.$notify.error({
                position: "top-left",
                title: "Ошибка",
                message: "",
              });
            }
          });
      }
    },

    isValidate() {
      if (this.form.login.length === 0) {
        this.errors['login'] = "Телефон обязательно";   
        return false;     
      }

      if (this.form.password.length === 0) {
        this.errors['password'] = "Пароль обязательно";
        return false;
      }

      return true;
    },
  },

  mounted() {
    if (this.$route.query.phone && this.$route.query.phone.length === 10) {
      this.form.login = this.$route.query.phone;
      this.passwordIsFocus = true;
    } else {
      this.loginIsFocus = true;
    }
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"
@import "../sass/pages/login-register"
@import "../sass/components/form/form"

.form-box__button--forgot-password
  position: relative

  .underline
    position: relative

    &::after
      +Size(100%, 0)
      display: block
      border-bottom: 1px dashed $cAccent

      position: absolute
      left: 0
      bottom: 0

      content: ''
</style>
